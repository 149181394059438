/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Video = ({ color = '#191919' }: IconProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.33334 6C2.97971 6 2.64057 6.14048 2.39053 6.39053C2.14048 6.64057 2 6.97971 2 7.33334V15.3334C2 16.2174 2.35119 17.0653 2.97631 17.6904C3.60144 18.3155 4.44928 18.6667 5.33334 18.6667H16C16.3537 18.6667 16.6928 18.5262 16.9428 18.2762C17.1929 18.0261 17.3334 17.687 17.3334 17.3334V14.8155L21.0026 16.9122C21.2089 17.0301 21.4624 17.0292 21.668 16.91C21.8735 16.7907 22 16.571 22 16.3334V8.33334C22 8.09569 21.8735 7.87601 21.668 7.75673C21.4624 7.63745 21.2089 7.6366 21.0026 7.75451L17.3334 9.8512V9.33334C17.3334 8.44928 16.9822 7.60144 16.3571 6.97631C15.7319 6.35119 14.8841 6 14 6H3.33334ZM16 10.9859C15.9998 10.9957 15.9998 11.0055 16 11.0154V13.6513C15.9998 13.6612 15.9998 13.671 16 13.6808V17.3334H5.33334C4.80291 17.3334 4.2942 17.1226 3.91912 16.7476C3.54405 16.3725 3.33334 15.8638 3.33334 15.3334V7.33334L14 7.33334C14.5305 7.33334 15.0392 7.54405 15.4142 7.91912C15.7893 8.2942 16 8.80291 16 9.33334V10.9859ZM17.3334 13.2798V11.3869L20.6667 9.48213V15.1846L17.3334 13.2798Z"
    fill={color}
  />
);
