/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Home = ({ color = '#191919' }: IconProps) => (
  <path
    fill={color}
    fillRule="evenodd"
    d="M10.876 2.424A1.692 1.692 0 0 1 12 2c.416 0 .817.151 1.124.424l.001.001 8.331 7.372.012.01c.165.152.297.334.388.536.091.202.14.42.144.64v9.394c0 .43-.176.843-.488 1.148a1.69 1.69 0 0 1-1.179.475h-5a1.69 1.69 0 0 1-1.178-.475 1.602 1.602 0 0 1-.488-1.148V15.51h-3.334v4.868c0 .43-.175.843-.488 1.148A1.69 1.69 0 0 1 8.667 22h-5a1.69 1.69 0 0 1-1.179-.475A1.601 1.601 0 0 1 2 20.377v-9.394c.004-.22.053-.438.144-.64s.223-.384.388-.535l.012-.011 8.331-7.372h.001ZM3.675 10.99l8.323-7.364.002-.002.002.002 8.323 7.364a.03.03 0 0 1 .008.018v9.37h-5V15.51c0-.43-.175-.843-.488-1.147a1.69 1.69 0 0 0-1.178-.476h-3.334a1.69 1.69 0 0 0-1.178.476 1.601 1.601 0 0 0-.488 1.147v4.868h-5v-9.37a.03.03 0 0 1 .008-.018Z"
    clipRule="evenodd"
  />
);
