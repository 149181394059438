import { useState } from "react";
import InputWithLabel from "./InputWithLabel";
import { Grid } from "./Grid";
import { Button } from "./Button";

interface SearchFormProps {
  initialDocumento?: string;
  initialMatricula?: string;
  initialNome?: string;
  onSearch: (documento: string, matricula: string, nome: string) => void;
}

const SearchSmartShareForm: React.FC<SearchFormProps> = ({
  initialDocumento = '',
  initialMatricula = '',
  initialNome = '',
  onSearch
}) => {
  const [documento, setDocumento] = useState<string>(initialDocumento);
  const [matricula, setMatricula] = useState<string>(initialMatricula);
  const [nome, setNome] = useState<string>(initialNome);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSearch(documento, matricula, nome);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid.Container
        cols={12}
        rowSpacing='20px'
        gridSpacing='20px'> 
        <Grid.Item colSpan={2}>
          <InputWithLabel
            id="nome"
            label="Nome"
            value={nome}
            setValue={setNome}
            placeholder="Inserir nome"
          />
        </Grid.Item>

        <Grid.Item colSpan={2}>
          <InputWithLabel
            id="matricula"
            label="Matricula"
            value={matricula}
            setValue={setMatricula}
            placeholder="Inserir matricula"
          />
        </Grid.Item>

        <Grid.Item colSpan={2}>
          <InputWithLabel
            id="documento"
            label="Documento"
            value={documento}
            setValue={setDocumento}
            placeholder="Inserir documento"
          />
        </Grid.Item>

        <Grid.Item colSpan={2} mt='24px'>
          <Button>Buscar</Button>
        </Grid.Item>
      </Grid.Container>
    </form>
  );
};

export default SearchSmartShareForm;
