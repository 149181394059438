/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ArrowLineRight = ({ color = '#191919' }: IconProps) => (
  <path
    fill={color}
    d="M2.25 12C2.25 12.1989 2.32902 12.3897 2.46967 12.5303C2.61032 12.671 2.80109 12.75 3 12.75H15.4397L9.96937 18.2194C9.89969 18.2891 9.84442 18.3718 9.80671 18.4628C9.76899 18.5539 9.74958 18.6515 9.74958 18.75C9.74958 18.8485 9.76899 18.9461 9.80671 19.0372C9.84442 19.1282 9.89969 19.2109 9.96937 19.2806C10.0391 19.3503 10.1218 19.4056 10.2128 19.4433C10.3039 19.481 10.4015 19.5004 10.5 19.5004C10.5985 19.5004 10.6961 19.481 10.7872 19.4433C10.8782 19.4056 10.9609 19.3503 11.0306 19.2806L17.7806 12.5306C17.8504 12.461 17.9057 12.3783 17.9434 12.2872C17.9812 12.1962 18.0006 12.0986 18.0006 12C18.0006 11.9014 17.9812 11.8038 17.9434 11.7128C17.9057 11.6217 17.8504 11.539 17.7806 11.4694L11.0306 4.71938C10.9609 4.64969 10.8782 4.59442 10.7872 4.55671C10.6961 4.51899 10.5985 4.49958 10.5 4.49958C10.4015 4.49958 10.3039 4.51899 10.2128 4.55671C10.1218 4.59442 10.0391 4.64969 9.96937 4.71938C9.89969 4.78906 9.84442 4.87178 9.80671 4.96283C9.76899 5.05387 9.74958 5.15145 9.74958 5.25C9.74958 5.34855 9.76899 5.44613 9.80671 5.53717C9.84442 5.62822 9.89969 5.71094 9.96937 5.78062L15.4397 11.25H3C2.80109 11.25 2.61032 11.329 2.46967 11.4697C2.32902 11.6103 2.25 11.8011 2.25 12ZM20.25 3C20.4489 3 20.6397 3.07902 20.7803 3.21967C20.921 3.36032 21 3.55109 21 3.75V20.25C21 20.4489 20.921 20.6397 20.7803 20.7803C20.6397 20.921 20.4489 21 20.25 21C20.0511 21 19.8603 20.921 19.7197 20.7803C19.579 20.6397 19.5 20.4489 19.5 20.25V3.75C19.5 3.55109 19.579 3.36032 19.7197 3.21967C19.8603 3.07902 20.0511 3 20.25 3Z"
  />
);
