/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const SmallGrid = ({ color = '#191919' }: IconProps) => (
  <path
    d="M2 3.53846V20.4615C2 21.3077 2.69231 22 3.53846 22H20.4615C21.3077 22 22 21.3077 22 20.4615V3.53846C22 2.69231 21.3077 2 20.4615 2H3.53846C2.69231 2 2 2.69231 2 3.53846ZM20.4615 8.15385H15.8462V3.53846H20.4615V8.15385ZM9.69231 14.3077V9.69231H14.3077V14.3077H9.69231ZM14.3077 15.8462V20.4615H9.69231V15.8462H14.3077ZM8.15385 14.3077H3.53846V9.69231H8.15385V14.3077ZM9.69231 8.15385V3.53846H14.3077V8.15385H9.69231ZM15.8462 9.69231H20.4615V14.3077H15.8462V9.69231ZM8.15385 3.53846V8.15385H3.53846V3.53846H8.15385ZM3.53846 15.8462H8.15385V20.4615H3.53846V15.8462ZM15.8462 20.4615V15.8462H20.4615V20.4615H15.8462Z"
    fill={color}
  />
);
