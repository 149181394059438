import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'; 

interface ImageViewerProps {
  base64: string; 
}

const ImageViewer: React.FC<ImageViewerProps> = ({ base64 }) => {
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  function base64ToBlob(mimeType: string): Blob {
    try {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mimeType });
    } catch (e) {
      setError('Falha ao carregar PDF!');
      setIsLoading(false);
      return new Blob(); 
    }
  }

  useEffect(() => {
    const handleDisplayImage = async () => {
      try {
        setIsLoading(true);     
        const blob = base64ToBlob('application/pdf');
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      } catch (error) {
        setError('Erro ao processar imagem!');
        toast.error('Erro ao processar imagem!');
      } finally {
        setIsLoading(false);
      }
    };

    handleDisplayImage();

    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
        setPdfUrl(''); 
      }
    };
  }, [base64]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <iframe style={{marginTop: 20}} src={pdfUrl} width="100%" height="300px" title="PDF Viewer" />
  );
};

export default ImageViewer;