/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Eraser = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.09161 8.86037L2.6868 14.2652L2.68524 14.2667C2.46838 14.4818 2.29619 14.7376 2.17858 15.0195C2.0607 15.3021 2 15.6052 2 15.9114C2 16.2176 2.0607 16.5207 2.17858 16.8033C2.29617 17.0852 2.46833 17.341 2.68515 17.556L2.6868 17.5577L6.27975 21.1506C6.42516 21.296 6.62238 21.3777 6.82802 21.3777H20.7749C21.2031 21.3777 21.5502 21.0306 21.5502 20.6024C21.5502 20.1741 21.2031 19.827 20.7749 19.827H12.4714L21.3229 10.9839L21.3281 10.9787C21.7587 10.5401 22 9.95001 22 9.33537C22 8.72074 21.7587 8.13066 21.3281 7.69209L16.9354 3.30906L16.9335 3.30711C16.7185 3.09043 16.4628 2.91837 16.1811 2.80084C15.8985 2.68295 15.5953 2.62225 15.2892 2.62225C14.983 2.62225 14.6799 2.68295 14.3973 2.80084C14.1154 2.91844 13.8595 3.09064 13.6445 3.3075L13.6429 3.30906L8.23811 8.71387C8.21095 8.73463 8.18485 8.75743 8.16001 8.78227C8.13517 8.80711 8.11237 8.83321 8.09161 8.86037ZM14.9944 4.23201C15.0878 4.19304 15.188 4.17298 15.2892 4.17298C15.3904 4.17298 15.4906 4.19304 15.584 4.23201C15.6774 4.27098 15.7621 4.32808 15.8333 4.40001L20.2239 8.78093C20.3684 8.92929 20.4493 9.12822 20.4493 9.33537C20.4493 9.5426 20.3683 9.74159 20.2238 9.88996L15.2917 14.8174L9.80965 9.33538L14.7422 4.4028L14.745 4.4C14.8162 4.32807 14.901 4.27098 14.9944 4.23201ZM8.71312 10.4319L14.1946 15.9134L10.2773 19.827H7.14919L3.78055 16.4583L3.77774 16.4556C3.70582 16.3844 3.64872 16.2996 3.60976 16.2062C3.57079 16.1128 3.55073 16.0126 3.55073 15.9114C3.55073 15.8102 3.57079 15.71 3.60976 15.6166C3.64872 15.5232 3.70583 15.4385 3.77775 15.3673L8.71312 10.4319Z"
      fill={color}
    />
  </>
);
