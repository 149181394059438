/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const PMais = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7648 2C13.9801 2 15.1381 2.47744 15.9906 3.33164L15.9888 3.3299C16.843 4.18409 17.3205 5.34037 17.3205 6.5418C17.3205 9.43598 15.0583 11.0992 11.9679 11.0992H5.74723V18.5092C5.74723 18.7192 5.67952 19.3616 4.90866 19.3616C4.46073 19.3616 4.08398 18.9849 4.08398 18.5369V2.82468C4.08398 2.37675 4.46073 2 4.90866 2H12.7648ZM12.7648 9.43598C13.5304 9.43598 14.2683 9.13215 14.8187 8.59741C15.3534 8.04879 15.6572 7.31092 15.6572 6.54353C15.6572 5.77615 15.3673 5.05391 14.8187 4.50354C14.27 3.95318 13.5322 3.64935 12.7648 3.64935H5.74723V9.43598H12.7648Z"
      fill={color}
    />
    <path
      d="M16.3144 13.8379C15.9592 13.8379 15.6713 14.1258 15.6713 14.4809V17.276H12.6614C12.3063 17.276 12.0184 17.5638 12.0184 17.919C12.0184 18.2741 12.3063 18.562 12.6614 18.562H15.6713V21.357C15.6713 21.7121 15.9592 22 16.3144 22C16.6695 22 16.9574 21.7121 16.9574 21.357V18.562H19.9673C20.3224 18.562 20.6103 18.2741 20.6103 17.919C20.6103 17.5638 20.3224 17.276 19.9673 17.276H16.9574V14.4809C16.9574 14.1258 16.6695 13.8379 16.3144 13.8379Z"
      fill={color}
    />
  </>
);
