/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Config = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fill={color}
      fillRule="evenodd"
      d="M12.084 6.502a5.497 5.497 0 1 0 0 10.995 5.497 5.497 0 0 0 0-10.995ZM8.157 12a3.927 3.927 0 1 1 7.854 0 3.927 3.927 0 0 1-7.854 0Z"
      clipRule="evenodd"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M9.366 1.598a.785.785 0 0 0-.645-.074A10.995 10.995 0 0 0 4.693 3.86a.785.785 0 0 0-.256.594l.045 2.669a8.012 8.012 0 0 0-.426.736L1.72 9.153a.785.785 0 0 0-.387.52 10.798 10.798 0 0 0 .01 4.658.785.785 0 0 0 .385.515l2.328 1.294c.13.255.272.503.426.743l-.045 2.663a.785.785 0 0 0 .256.594 10.856 10.856 0 0 0 4.042 2.328c.215.067.448.04.64-.075l2.286-1.368c.282.012.564.012.845 0l2.296 1.377c.194.117.43.144.645.074a10.994 10.994 0 0 0 4.028-2.336.785.785 0 0 0 .256-.594l-.045-2.669a8.02 8.02 0 0 0 .426-.736l2.336-1.294a.785.785 0 0 0 .387-.52 10.799 10.799 0 0 0-.01-4.658.785.785 0 0 0-.385-.515L20.112 7.86a8.023 8.023 0 0 0-.426-.737l.045-2.669a.785.785 0 0 0-.256-.594 10.858 10.858 0 0 0-4.042-2.328.785.785 0 0 0-.64.075l-2.286 1.368a9.456 9.456 0 0 0-.845 0L9.366 1.598Zm-3.31 5.745-.043-2.552a9.424 9.424 0 0 1 2.858-1.658l2.19 1.314a.785.785 0 0 0 .46.11c.375-.027.75-.027 1.125 0a.785.785 0 0 0 .46-.11l2.183-1.306a9.287 9.287 0 0 1 2.866 1.65l-.043 2.552a.785.785 0 0 0 .141.463c.21.302.395.62.551.954.07.15.186.273.33.353l2.227 1.238c.2 1.09.203 2.207.008 3.298l-2.234 1.237a.786.786 0 0 0-.33.354 6.43 6.43 0 0 1-.552.954.785.785 0 0 0-.141.463l.043 2.552a9.424 9.424 0 0 1-2.859 1.658l-2.19-1.314a.785.785 0 0 0-.46-.11 7.88 7.88 0 0 1-1.125 0 .786.786 0 0 0-.459.11L8.88 20.859a9.285 9.285 0 0 1-2.866-1.65l.043-2.543a.785.785 0 0 0-.136-.456 7.077 7.077 0 0 1-.557-.972.785.785 0 0 0-.329-.351l-2.227-1.238A9.228 9.228 0 0 1 2.8 10.35l2.234-1.237a.785.785 0 0 0 .33-.354 6.44 6.44 0 0 1 .552-.954.785.785 0 0 0 .141-.463Z"
      clipRule="evenodd"
    />
  </>
);
