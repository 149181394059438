/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const CheckBoxUndefined = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.41732 4H18.7507C19.1043 4 19.4434 4.14048 19.6935 4.39052C19.9435 4.64057 20.084 4.97971 20.084 5.33333V18.6667C20.084 19.0203 19.9435 19.3594 19.6935 19.6095C19.4434 19.8595 19.1043 20 18.7507 20H5.41732C5.0637 20 4.72456 19.8595 4.47451 19.6095C4.22446 19.3594 4.08398 19.0203 4.08398 18.6667V5.33333C4.08398 4.97971 4.22446 4.64057 4.47451 4.39052C4.72456 4.14048 5.0637 4 5.41732 4ZM8.88398 11.2C8.44216 11.2 8.08398 11.5582 8.08398 12C8.08398 12.4418 8.44216 12.8 8.88398 12.8H15.284C15.7258 12.8 16.084 12.4418 16.084 12C16.084 11.5582 15.7258 11.2 15.284 11.2H8.88398Z"
      fill={color}
    />
  </>
);
