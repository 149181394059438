/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ImageToRight = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M20.8889 6.22229C21.5025 6.22229 22 5.72485 22 5.11104C22 4.49744 21.5025 4 20.8889 4H3.11112C2.49746 4 2 4.49744 2 5.11104C2 5.72485 2.49746 6.22229 3.11112 6.22229H20.8889Z"
      fill={color}
    />
    <path
      d="M20.8889 17.3333C21.5025 17.3333 22 17.8308 22 18.4444C22 19.0582 21.5025 19.5556 20.8889 19.5556H3.11112C2.49746 19.5556 2 19.0582 2 18.4444C2 17.8308 2.49746 17.3333 3.11112 17.3333H20.8889Z"
      fill={color}
    />
    <path
      d="M9.77778 12.8889C10.3914 12.8889 10.8889 13.3864 10.8889 14C10.8889 14.6136 10.3914 15.111 9.77778 15.111H3.11112C2.49746 15.111 2 14.6136 2 14C2 13.3864 2.49746 12.8889 3.11112 12.8889H9.77778Z"
      fill={color}
    />
    <path
      d="M9.77778 8.44437C10.3914 8.44437 10.8889 8.94181 10.8889 9.55562C10.8889 10.1692 10.3914 10.6667 9.77778 10.6667H3.11112C2.49746 10.6667 2 10.1692 2 9.55562C2 8.94181 2.49746 8.44437 3.11112 8.44437H9.77778Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1111 13.4444C13.1111 14.3648 13.8573 15.111 14.7778 15.111H20.3333C21.2538 15.111 22 14.3648 22 13.4444V10.111C22 9.19063 21.2538 8.44437 20.3333 8.44437H14.7778C13.8573 8.44437 13.1111 9.19063 13.1111 10.111V13.4444ZM20.3333 10.111H14.7778V13.4444L20.3333 13.4385V10.111Z"
      fill={color}
    />
  </>
);
