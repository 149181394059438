/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ZoomOut = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M15.7593 10.923C15.7593 10.5272 15.4384 10.2064 15.0426 10.2064H6.90072C6.50491 10.2064 6.18405 10.5272 6.18405 10.923C6.18405 11.3188 6.50491 11.6397 6.90072 11.6397H15.0426C15.4384 11.6397 15.7593 11.3188 15.7593 10.923Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9301 2C6.04453 2 2.08398 5.96055 2.08398 10.8461C2.08398 15.7317 6.04453 19.6923 10.9301 19.6923C13.096 19.6923 15.08 18.9139 16.6178 17.6217L20.7708 21.7747C21.0712 22.0751 21.5583 22.0751 21.8587 21.7747C22.1591 21.4743 22.1591 20.9872 21.8587 20.6868L17.7057 16.5338C18.9979 14.996 19.7763 13.012 19.7763 10.8461C19.7763 5.96055 15.8157 2 10.9301 2ZM3.62244 10.8461C3.62244 6.81022 6.8942 3.53846 10.9301 3.53846C14.966 3.53846 18.2378 6.81022 18.2378 10.8461C18.2378 14.8821 14.966 18.1538 10.9301 18.1538C6.8942 18.1538 3.62244 14.8821 3.62244 10.8461Z"
      fill={color}
    />
  </>
);
