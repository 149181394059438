/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Connection = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M12.084 6.44274C8.80108 6.44274 5.64999 7.73447 3.31169 10.0388C3.02792 10.3184 2.57119 10.3151 2.29155 10.0313C2.01191 9.74753 2.01525 9.2908 2.29902 9.01116C4.90725 6.44084 8.4221 5 12.084 5C15.7459 5 19.2607 6.44084 21.8689 9.01116C22.1527 9.2908 22.1561 9.74753 21.8764 10.0313C21.5968 10.3151 21.14 10.3184 20.8563 10.0388C18.518 7.73447 15.3669 6.44274 12.084 6.44274Z"
      fill={color}
    />
    <path
      d="M6.36599 13.0979C7.89528 11.6057 9.94732 10.7704 12.084 10.7704C14.2207 10.7704 16.2728 11.6057 17.802 13.0979C18.0872 13.3762 18.5439 13.3706 18.8221 13.0854C19.1004 12.8003 19.0948 12.3436 18.8096 12.0653C17.0109 10.3102 14.5972 9.3277 12.084 9.3277C9.57081 9.3277 7.15718 10.3102 5.35841 12.0653C5.07326 12.3436 5.06766 12.8003 5.34589 13.0854C5.62413 13.3706 6.08084 13.3762 6.36599 13.0979Z"
      fill={color}
    />
    <path
      d="M9.41845 16.1679C10.1354 15.4801 11.0905 15.096 12.0841 15.096C13.0776 15.096 14.0327 15.4801 14.7497 16.1679C15.0371 16.4438 15.4938 16.4343 15.7696 16.1468C16.0454 15.8593 16.036 15.4027 15.7485 15.1269C14.7629 14.1813 13.4499 13.6533 12.0841 13.6533C10.7182 13.6533 9.40523 14.1813 8.41963 15.1269C8.13214 15.4027 8.12268 15.8593 8.3985 16.1468C8.67432 16.4343 9.13097 16.4438 9.41845 16.1679Z"
      fill={color}
    />
    <path
      d="M13.1661 18.7043C13.1661 19.3019 12.6816 19.7863 12.084 19.7863C11.4864 19.7863 11.002 19.3019 11.002 18.7043C11.002 18.1067 11.4864 17.6222 12.084 17.6222C12.6816 17.6222 13.1661 18.1067 13.1661 18.7043Z"
      fill={color}
    />
  </>
);
