import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import PrivateRoutes from './privateRoutes';

import EDTList from '../pages/EDTList';
import SmartShareList from '../pages/SmartShareList';
import App from '../App';
import NotFound from '../components/NotFound';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/" element={<EDTList />} />
      <Route path="/smart-share"  element={<SmartShareList />} />      
  
      <Route element={<PrivateRoutes routeRoles={['admin', 'user']} />}>
        <Route path="profile" element={<>Profile</>} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>,
  ),
);
