/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Medal = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.484 8.8C6.484 5.70721 8.9912 3.2 12.084 3.2C15.1768 3.2 17.684 5.70721 17.684 8.8C17.684 11.8928 15.1768 14.4 12.084 14.4C8.9912 14.4 6.484 11.8928 6.484 8.8ZM12.084 4.8C9.87486 4.8 8.084 6.59086 8.084 8.8C8.084 11.0091 9.87486 12.8 12.084 12.8C14.2931 12.8 16.084 11.0091 16.084 8.8C16.084 6.59086 14.2931 4.8 12.084 4.8Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.684 15.5885C19.6384 13.9745 20.884 11.5327 20.884 8.8C20.884 3.93989 16.9441 0 12.084 0C7.22389 0 3.284 3.93989 3.284 8.8C3.284 11.5327 4.52959 13.9745 6.484 15.5885V23.2C6.484 23.4773 6.62756 23.7348 6.86341 23.8805C7.09926 24.0263 7.39378 24.0395 7.64177 23.9155L12.084 21.6944L16.5262 23.9155C16.7742 24.0395 17.0687 24.0263 17.3046 23.8805C17.5404 23.7348 17.684 23.4773 17.684 23.2V15.5885ZM12.084 1.6C8.10755 1.6 4.884 4.82355 4.884 8.8C4.884 11.1461 6.00614 13.2302 7.74299 14.5447C7.7572 14.5547 7.77106 14.5651 7.78458 14.5759C8.98438 15.4705 10.4724 16 12.084 16C13.6956 16 15.1836 15.4705 16.3834 14.5759C16.3969 14.5651 16.4108 14.5547 16.425 14.5447C18.1619 13.2302 19.284 11.1461 19.284 8.8C19.284 4.82355 16.0604 1.6 12.084 1.6ZM16.084 16.6405C14.8839 17.254 13.5244 17.6 12.084 17.6C10.6436 17.6 9.2841 17.254 8.084 16.6405V21.9056L11.7262 20.0845C11.9514 19.9718 12.2165 19.9718 12.4418 20.0845L16.084 21.9056V16.6405Z"
      fill={color}
    />
  </>
);
