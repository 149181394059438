/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ActivateCycle = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M19.5081 2.82179C19.9003 2.85891 20.1926 3.20738 20.1614 3.60049L19.8495 7.51908C19.8147 7.95691 19.4159 8.26775 18.9828 8.19466L15.2324 7.56176C14.8387 7.49533 14.5688 7.12223 14.6292 6.728C14.6899 6.33226 15.0603 6.06565 15.4553 6.13339L17.4885 6.48204C15.4095 3.81836 11.8269 2.73991 8.5972 3.90078C5.79193 4.90909 3.93291 7.36737 3.53964 10.145C3.499 10.4321 3.3102 10.6817 3.03435 10.7808C2.54248 10.9576 2.02524 10.5938 2.0894 10.0808C2.50824 6.73163 4.73169 3.75372 8.09717 2.54404C11.8786 1.18487 16.0756 2.44193 18.5734 5.53895L18.7396 3.46729C18.7712 3.07369 19.1153 2.78462 19.5081 2.82179Z"
      fill={color}
    />
    <path
      d="M5.54282 16.1226C7.20179 18.2482 9.8182 19.3643 12.4487 19.1387C12.6554 19.1209 12.8633 19.1746 13.0285 19.3C13.5499 19.6956 13.3848 20.4936 12.7336 20.5588C9.60943 20.8717 6.47793 19.5704 4.45787 17.0657L4.29165 19.1374C4.26008 19.531 3.91596 19.8201 3.52322 19.7829C3.131 19.7458 2.83866 19.3973 2.86993 19.0042L3.18178 15.0856C3.21661 14.6478 3.61541 14.3369 4.0485 14.41L7.79893 15.0429C8.1926 15.1093 8.4625 15.4824 8.40208 15.8767C8.34141 16.2724 7.97101 16.539 7.57599 16.4713L5.54282 16.1226Z"
      fill={color}
    />
    <path
      d="M19.6837 13.1808C19.861 13.3667 19.8541 13.6611 19.6682 13.8384L17.1039 16.2848C16.9241 16.4564 16.6412 16.4562 16.4615 16.2845L15.1815 15.0613C14.9958 14.8839 14.9891 14.5894 15.1666 14.4037C15.344 14.218 15.6385 14.2113 15.8242 14.3888L16.7831 15.3052L19.0261 13.1653C19.212 12.988 19.5064 12.9949 19.6837 13.1808Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7661 14.5503C12.7661 11.9773 14.8519 9.89137 17.425 9.89137C19.9981 9.89137 22.084 11.9773 22.084 14.5503C22.084 17.1234 19.9981 19.2093 17.425 19.2093C14.8519 19.2093 12.7661 17.1234 12.7661 14.5503ZM17.425 10.8216C15.3657 10.8216 13.6963 12.491 13.6963 14.5503C13.6963 16.6097 15.3657 18.2791 17.425 18.2791C19.4843 18.2791 21.1537 16.6097 21.1537 14.5503C21.1537 12.491 19.4843 10.8216 17.425 10.8216Z"
      fill={color}
    />
  </>
);
