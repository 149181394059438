/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const NoConnection = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M21.7819 5.27099C22.0727 4.98024 22.0727 4.50883 21.7819 4.21807C21.4912 3.92731 21.0198 3.92731 20.729 4.21807L19.0219 5.9252L17.3147 4.21807C17.024 3.92731 16.5526 3.92731 16.2618 4.21807C15.9711 4.50883 15.9711 4.98024 16.2618 5.27099L17.969 6.97812L16.2618 8.68525C15.9711 8.97601 15.9711 9.44742 16.2618 9.73818C16.5526 10.0289 17.024 10.0289 17.3147 9.73818L19.0219 8.03105L20.729 9.73818C21.0198 10.0289 21.4912 10.0289 21.7819 9.73818C22.0727 9.44742 22.0727 8.97601 21.7819 8.68525L20.0748 6.97812L21.7819 5.27099Z"
      fill={color}
    />
    <path
      d="M9.56995 15.5283C10.3099 14.8184 11.2957 14.422 12.3211 14.422C13.3466 14.422 14.3323 14.8184 15.0723 15.5283C15.369 15.813 15.8403 15.8032 16.125 15.5065C16.4097 15.2098 16.3999 14.7385 16.1032 14.4538C15.086 13.4779 13.7308 12.9329 12.3211 12.9329C10.9114 12.9329 9.5563 13.4779 8.53906 14.4538C8.24234 14.7385 8.23258 15.2098 8.51725 15.5065C8.80192 15.8032 9.27323 15.813 9.56995 15.5283Z"
      fill={color}
    />
    <path
      d="M3.26871 9.20073C5.99739 6.49557 9.91259 5.13626 13.7263 5.56815C14.1349 5.61443 14.5037 5.32072 14.5499 4.91213C14.5962 4.50355 14.3025 4.13482 13.8939 4.08855C9.63154 3.60585 5.26925 5.12064 2.22035 8.14326C1.92834 8.43275 1.9263 8.90416 2.21579 9.19617C2.50529 9.48819 2.9767 9.49023 3.26871 9.20073Z"
      fill={color}
    />
    <path
      d="M13.6863 10.067C13.2365 9.99109 12.781 9.95408 12.3248 9.95635L12.3181 9.95638C10.1128 9.94751 7.99345 10.8106 6.4218 12.3576C6.12876 12.646 5.65736 12.6423 5.36891 12.3493C5.08046 12.0562 5.08418 11.5848 5.37723 11.2964C7.2281 9.47453 9.72376 8.45772 12.3208 8.46729C12.8613 8.46479 13.401 8.50874 13.934 8.59865C14.3395 8.66705 14.6127 9.05119 14.5443 9.45665C14.4759 9.86212 14.0918 10.1354 13.6863 10.067Z"
      fill={color}
    />
    <path
      d="M13.4379 18.1461C13.4379 18.7629 12.9379 19.2629 12.3211 19.2629C11.7043 19.2629 11.2043 18.7629 11.2043 18.1461C11.2043 17.5293 11.7043 17.0293 12.3211 17.0293C12.9379 17.0293 13.4379 17.5293 13.4379 18.1461Z"
      fill={color}
    />
  </>
);
