/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const LinkUp = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9059 8.08339C16.2146 8.39168 16.215 8.8919 15.9067 9.20066L9.21122 15.906C8.90292 16.2148 8.4027 16.2151 8.09395 15.9068C7.7852 15.5985 7.78483 15.0983 8.09313 14.7896L14.7886 8.08421C15.0969 7.77546 15.5971 7.77509 15.9059 8.08339Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.53212 9.76261C7.84064 10.0711 7.84064 10.5714 7.53212 10.8799L4.73739 13.6746C3.99636 14.4156 3.58006 15.4207 3.58006 16.4687C3.58006 17.5166 3.99636 18.5217 4.73739 19.2627C5.47842 20.0037 6.48347 20.42 7.53144 20.42C8.05034 20.42 8.56417 20.3178 9.04357 20.1193C9.52297 19.9207 9.95857 19.6296 10.3255 19.2627L13.1202 16.468C13.4287 16.1595 13.929 16.1595 14.2375 16.468C14.546 16.7765 14.546 17.2767 14.2375 17.5852L11.4428 20.38C10.9291 20.8936 10.3193 21.3011 9.64823 21.579C8.97713 21.857 8.25784 22.0001 7.53144 22.0001C6.06441 22.0001 4.65747 21.4173 3.62012 20.38C2.58277 19.3426 2 17.9357 2 16.4687C2 15.0016 2.58277 13.5947 3.62012 12.5573L6.41485 9.76261C6.72337 9.45408 7.22359 9.45408 7.53212 9.76261Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5572 3.62012C13.5946 2.58277 15.0015 2 16.4686 2C17.9356 2 19.3425 2.58277 20.3799 3.62012C21.4172 4.65747 22 6.06441 22 7.53144C22 8.99847 21.4172 10.4054 20.3799 11.4428L17.5852 14.2375C17.2766 14.546 16.7764 14.546 16.4679 14.2375C16.1594 13.929 16.1594 13.4287 16.4679 13.1202L19.2626 10.3255C20.0036 9.58446 20.4199 8.57941 20.4199 7.53144C20.4199 6.48347 20.0036 5.47842 19.2626 4.73739C18.5216 3.99636 17.5165 3.58006 16.4686 3.58006C15.4206 3.58006 14.4155 3.99636 13.6745 4.73739L10.8798 7.53212C10.5713 7.84064 10.071 7.84064 9.76251 7.53212C9.45399 7.22359 9.45399 6.72337 9.76251 6.41485L12.5572 3.62012Z"
      fill={color}
    />
  </>
);
