import { AuthTokens } from '../auth/AuthService';
import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';
import { ApiResponse } from '../types/api-response';

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

api.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem('auth') || '{}') as AuthTokens;

  config = {
    ...config,
    // @ts-ignore
    headers: {
      ...(config.headers as RawAxiosRequestHeaders),
      Authorization: 'Bearer ' + token.access_token,
    },
  };
  return config;
});

api.interceptors.response.use(
  (res) => {
    if (!res.data?.success) {
    }
    return res;
  },
  (err: AxiosError) => {
    if (err.response) {
      const errosFormmated = err.response.data as ApiResponse;
      const error = [errosFormmated.erro ?? err.response.statusText];
      const mensangeError = errosFormmated.errors ?? error;

      if (err.response.status === 401) {
        logout();
        return Promise.reject(mensangeError);
      } else {
        return Promise.reject(mensangeError);
      }
    } else {
      const error = "Ocorreu um erro inesperado, contate o administrador do sistema."
      return Promise.reject(error);
    }
  },
);

function logout() {
  window.localStorage.removeItem('pkce');
  window.localStorage.removeItem('auth');
  window.location.reload();
}

export default api;
