/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const FavoriteComments = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M2.61472 3.44666C2.20875 3.44666 1.81941 3.60793 1.53234 3.89499C1.24528 4.18206 1.084 4.5714 1.084 4.97738L1.08398 19.0291L1.08403 19.0347C1.08632 19.3241 1.17064 19.607 1.3272 19.8505C1.48376 20.0939 1.70614 20.288 1.96854 20.4102C2.23094 20.5324 2.52262 20.5777 2.80972 20.5408C3.09682 20.5039 3.36778 20.3862 3.59079 20.2017L3.59373 20.1992L6.41965 17.837L10.9562 17.8357C11.4111 17.8356 11.7798 17.4667 11.7798 17.0118C11.7798 16.5568 11.4109 16.1879 10.9559 16.1879H6.37496C6.01438 16.188 5.66522 16.3174 5.39134 16.5516L2.72975 18.7765V5.0924L18.0724 5.0924V8.47087C18.0724 8.92563 18.4412 9.29416 18.896 9.29375C19.3502 9.29333 19.7181 8.92504 19.7181 8.47087V4.97738C19.7181 4.57141 19.5568 4.18206 19.2698 3.89499C18.9827 3.60793 18.5934 3.44666 18.1874 3.44666H2.61472Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9021 12.1889C18.7521 12.1889 18.6034 12.2401 18.4865 12.3264C18.3909 12.397 18.2646 12.5693 18.2234 12.682L17.4465 14.6763L15.4011 14.8101C15.0608 14.8277 14.8315 15.0627 14.7516 15.34C14.6725 15.6147 14.7363 15.9429 14.9843 16.1571L16.5523 17.5501L16.0874 19.4452C15.9116 20.126 16.6344 20.7602 17.2614 20.3509L18.8888 19.2829L20.6513 20.4349C20.9404 20.6227 21.2552 20.5705 21.4739 20.3935C21.6898 20.2188 21.8186 19.9163 21.7441 19.5997L21.2387 17.5236L22.8194 16.1577C23.3098 15.735 23.0881 14.8619 22.4049 14.8102L20.3451 14.6629L19.581 12.6684C19.5292 12.5265 19.429 12.4085 19.3177 12.3264C19.2009 12.2401 19.0522 12.1889 18.9021 12.1889ZM17.3993 17.6527L17.3803 17.7337L17.3257 17.9676C17.2807 18.1613 17.2209 18.4199 17.1619 18.6792C17.1052 18.9287 17.049 19.1784 16.9963 19.4289C17.2079 19.2976 17.419 19.1656 17.6301 19.0335C17.8501 18.8958 18.0697 18.7582 18.2342 18.6549L18.5115 18.4809C18.617 18.4186 18.7485 18.365 18.8887 18.365C19.018 18.365 19.1532 18.3983 19.2737 18.4859L19.5571 18.6689C19.7293 18.78 19.959 18.9281 20.1891 19.0762C20.4044 19.2148 20.6199 19.3534 20.8357 19.4912L20.3899 17.6601C20.3576 17.5264 20.3575 17.3775 20.3899 17.2438L20.3936 17.2283C20.438 17.106 20.504 16.9814 20.626 16.8892L22.0025 15.6663L20.206 15.5488C20.0725 15.5477 19.924 15.4978 19.8179 15.4083C19.7017 15.3213 19.6205 15.2017 19.5723 15.0696L18.8927 13.3253L18.2185 15.0557C18.1697 15.1893 18.0872 15.31 17.9689 15.3974C17.8579 15.4793 17.725 15.5336 17.5853 15.5348L15.7769 15.6642L17.1676 16.8777C17.2669 16.9634 17.348 17.0814 17.3963 17.2147C17.4472 17.355 17.4482 17.5118 17.3993 17.6527Z"
      fill={color}
    />
  </>
);
