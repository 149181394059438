/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Like = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M21.9848 9.57592C21.8983 9.28029 21.7468 9.01351 21.5377 8.77556C21.3359 8.54483 21.0907 8.35736 20.8095 8.23478C20.5283 8.10499 20.2327 8.04731 19.9226 8.04731H15.0628V6.60522C15.0628 5.64623 14.6878 4.73772 14.01 4.05272C13.3323 3.37494 12.4238 3 11.4576 3C11.1836 3 10.9312 3.15142 10.8158 3.39657L7.4125 10.2104H3.52607C3.14392 10.2104 2.77619 10.3619 2.5094 10.6359C2.24261 10.9099 2.08398 11.2704 2.08398 11.6525V19.584C2.08398 19.9662 2.2354 20.3339 2.5094 20.6007C2.7834 20.8675 3.14392 21.0261 3.52607 21.0261H18.841C19.3674 21.0261 19.8721 20.8314 20.2687 20.4853C20.6581 20.1392 20.9104 19.6561 20.9825 19.137L22.0641 10.4916C22.1074 10.1888 22.0785 9.87876 21.992 9.58313L21.9848 9.57592ZM3.52607 11.6525H7.13129V19.584H3.52607V11.6525ZM19.5477 18.9495C19.526 19.1225 19.4395 19.2812 19.3097 19.4037C19.1799 19.5191 19.0141 19.584 18.841 19.584H8.57338V11.1045L11.883 4.48535C12.3012 4.56466 12.6833 4.77377 12.9862 5.07661C13.3972 5.4876 13.6207 6.02838 13.6207 6.60522V8.76835C13.6207 9.16492 13.9452 9.48939 14.3417 9.48939H19.9226C20.0236 9.48939 20.1245 9.51103 20.2182 9.55429C20.312 9.59755 20.3913 9.65523 20.4562 9.73455C20.5211 9.81386 20.5715 9.90039 20.6004 10.0013C20.6292 10.1023 20.6364 10.2032 20.622 10.3114L19.5405 18.9639L19.5477 18.9495Z"
      fill={color}
    />
  </>
);
