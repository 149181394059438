/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const SavedBookmarks = ({ color = '#191919' }: IconProps) => (
  <path
    d="M15.0769 5.07692H5.84614C5.43811 5.07692 5.0468 5.23901 4.75828 5.52752C4.46977 5.81604 4.30768 6.20736 4.30768 6.61538V21.2308C4.30767 21.3721 4.34661 21.5107 4.42022 21.6314C4.49382 21.752 4.59925 21.8501 4.72492 21.9147C4.85059 21.9794 4.99166 22.0082 5.13262 21.998C5.27359 21.9877 5.40901 21.9389 5.52402 21.8567L10.4615 18.3298L15.4 21.8567C15.515 21.9386 15.6503 21.9872 15.7911 21.9973C15.9319 22.0074 16.0728 21.9786 16.1983 21.9139C16.3238 21.8493 16.4291 21.7514 16.5027 21.6309C16.5763 21.5104 16.6152 21.3719 16.6154 21.2308V6.61538C16.6154 6.20736 16.4533 5.81604 16.1648 5.52752C15.8762 5.23901 15.4849 5.07692 15.0769 5.07692ZM15.0769 19.7365L10.9077 16.7558C10.7772 16.6626 10.6209 16.6125 10.4606 16.6125C10.3002 16.6125 10.1439 16.6626 10.0134 16.7558L5.84614 19.7356V6.61538H15.0769V19.7365ZM19.6923 3.53846V18.1538C19.6923 18.3578 19.6112 18.5535 19.467 18.6978C19.3227 18.842 19.1271 18.9231 18.923 18.9231C18.719 18.9231 18.5234 18.842 18.3791 18.6978C18.2349 18.5535 18.1538 18.3578 18.1538 18.1538V3.53846H8.15383C7.94982 3.53846 7.75416 3.45742 7.6099 3.31316C7.46564 3.1689 7.3846 2.97324 7.3846 2.76923C7.3846 2.56522 7.46564 2.36956 7.6099 2.2253C7.75416 2.08104 7.94982 2 8.15383 2H18.1538C18.5618 2 18.9532 2.16209 19.2417 2.4506C19.5302 2.73912 19.6923 3.13043 19.6923 3.53846Z"
    fill={color}
  />
);
