/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const AlignRight = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      d="M2.91602 5.83333C2.91602 5.3731 3.28911 5 3.74935 5H22.0827C22.5429 5 22.916 5.3731 22.916 5.83333C22.916 6.29357 22.5429 6.66667 22.0827 6.66667H3.74935C3.28911 6.66667 2.91602 6.29357 2.91602 5.83333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      d="M7.91602 10C7.91602 9.53976 8.28911 9.16667 8.74935 9.16667H22.0827C22.5429 9.16667 22.916 9.53976 22.916 10C22.916 10.4602 22.5429 10.8333 22.0827 10.8333H8.74935C8.28911 10.8333 7.91602 10.4602 7.91602 10Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      d="M2.91602 14.1667C2.91602 13.7064 3.28911 13.3333 3.74935 13.3333H22.0827C22.5429 13.3333 22.916 13.7064 22.916 14.1667C22.916 14.6269 22.5429 15 22.0827 15H3.74935C3.28911 15 2.91602 14.6269 2.91602 14.1667Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      d="M7.91602 18.3333C7.91602 17.8731 8.28911 17.5 8.74935 17.5H22.0827C22.5429 17.5 22.916 17.8731 22.916 18.3333C22.916 18.7936 22.5429 19.1667 22.0827 19.1667H8.74935C8.28911 19.1667 7.91602 18.7936 7.91602 18.3333Z"
      fill={color}
    />
  </>
);
