/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Source = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M14.9419 13.6205C15.2674 13.946 15.795 13.946 16.1204 13.6205C16.4459 13.2951 16.4459 12.7675 16.1204 12.442L12.59 8.91155L12.58 8.90172C12.5093 8.83316 12.4295 8.7796 12.3445 8.74104C12.2395 8.69327 12.1228 8.66667 11.9999 8.66667C11.8135 8.66667 11.6413 8.72789 11.5025 8.83132C11.4673 8.85754 11.4343 8.88647 11.4037 8.91781L7.87943 12.442C7.554 12.7675 7.554 13.2951 7.87943 13.6205C8.20487 13.946 8.73251 13.946 9.05794 13.6205L11.1666 11.5119L11.1666 17.8333C11.1666 18.2936 11.5397 18.6667 11.9999 18.6667C12.4601 18.6667 12.8332 18.2936 12.8332 17.8333V11.5118L14.9419 13.6205Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 2C4.18436 2 3.8958 2.17834 3.75464 2.46066L2.08798 5.79399C2.03012 5.9097 2 6.0373 2 6.16667V20.3333C2 20.7754 2.17559 21.1993 2.48816 21.5118C2.80072 21.8244 3.22464 22 3.66667 22H20.3333C20.7754 22 21.1993 21.8244 21.5118 21.5118C21.8244 21.1993 22 20.7754 22 20.3333V6.16667C22 6.0373 21.9699 5.9097 21.912 5.79399L20.2454 2.46066C20.1042 2.17834 19.8156 2 19.5 2H4.5ZM4.18169 5.33333L5.01503 3.66667H18.985L19.8183 5.33333H4.18169ZM3.66667 7V20.3333H20.3333V7H3.66667Z"
      fill={color}
    />
  </>
);
