import { GridContainerStyle } from './styles';
import { GridContainerProps, TSpacing } from './types';

export const GridContainer = ({
  variant='grid',
  cols=12,
  rows=1,
  columnSpacing,
  rowSpacing,
  gridSpacing,
  children,
  justifyContent,
  alignContent,
  alignItems,
  justifyItems,
  wrap,
  flow,
  direction,
  p,px,py,pt,pb,pl,pr,
  m,mx,my,mt,mb,ml,mr,
  ...props
}:GridContainerProps) => {
  return (
    <GridContainerStyle
      $variant={variant}
      $columnSpacing={columnSpacing as TSpacing}
      $rowSpacing={rowSpacing as TSpacing}
      $gridSpacing={gridSpacing as TSpacing}
      $p={p} $px={px} $py={py} $pt={pt} $pb={pb} $pl={pl} $pr={pr}
      $m={m} $mx={mx} $my={my} $mt={mt} $mb={mb} $ml={ml} $mr={mr}
      $rows={rows}
      $cols={cols}
      $justifyContent={justifyContent}
      $alignContent={alignContent}
      $alignItems={alignItems}
      $justifyItems={justifyItems}
      $wrap={wrap}
      $flow={flow}
      $direction={direction}
      {...props}
    >{children}</GridContainerStyle>
  );
};