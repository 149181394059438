/* eslint-disable max-len */
import { TGridBreakpoint, TSpacing } from './../types';
import { ESpacing, gridSpacingOrder } from '.';

export const getSpacing = (spacing: TSpacing) => {
  return typeof spacing === 'number' ? spacing : ESpacing[spacing];
};

export const getMediaQuery = (
  element: TGridBreakpoint,
  breakpoints: { [x: string]: number }, // Add type annotation to the index signature
  callback: (value: string) => string,
) => {
  const sortedObject = Object.fromEntries(gridSpacingOrder.map(key => [key, element[key]]));
  const arrString = Object.entries(sortedObject).map(([breakpoint, spacing]) => {
    return spacing && `@media (min-width: ${breakpoints[breakpoint as string]}px) {
        ${callback(element[breakpoint as keyof TGridBreakpoint] as string)};
    }`;
  });
  return arrString ? arrString.join('') : '';
};