import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <h1 className="text-4xl font-bold text-red-600">404 - Página Não Encontrada</h1>
      <p className="text-lg text-gray-700 mt-4">A página que você está buscando não existe.</p>
      <Link to="/" className="mt-6 text-blue-500 hover:underline">
        Voltar para a página inicial
      </Link>
    </div>
  );
};

export default NotFound;