import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../services/api';
import { useEffect, useState } from "react";
import { Pagination } from "../components/Pagination";
import Table, { TableColumn } from '../components/Table';
import Modal from 'react-modal';
import SmartShareList from './SmartShareList';
import SearchForm from '../components/SearchForm';

interface Data {
  Matricula: string;
  Nome: string;
  IdFiliado: number;
  Cpf: string;
  Email: string;
  Franquia: string;
  Regional: string;
  DtFiliacao: Date;
}
interface QueryParamsEDT {
  dtIni?: string;
  dtFinal?: string;
  pageNumber?: number;
  pageSize?: number;
  documento?: string;
  matricula?: string;
  franquia?: number | null;
  regional?: number | null;
  nome?: string;
}

const EDTList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [items, setItems] = useState<Data[]>([]);

  const [documento, setDocumento] = useState<string>('');
  const [matricula, setMatricula] = useState<string>('');
  const [nome, setNome] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [franquia, setFranquia] = useState<number | null>(null);
  const [regional, setRegional] = useState<number | null>(null);

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Data | null>(null);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (searchTriggered) {
      fetchData();
      setSearchTriggered(false);
    }
  }, [searchTriggered]);

  useEffect(() => {
    if (!initialLoad) {
      fetchData();
    } else {
      setInitialLoad(false);
    }
  }, [currentPage, itemsPerPage]);

  const onPageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    setItemsPerPage(perPage);
  };

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);

    const params: QueryParamsEDT = {
      dtIni: startDate?.toString(),
      dtFinal: endDate?.toString(),
      pageNumber: currentPage,
      pageSize: itemsPerPage,
      documento: documento,
      matricula: matricula,
      franquia: franquia,
      regional: regional,
      nome: nome
    };

    if (!params.dtIni || !params.dtFinal) {
      toast.error('É necessário informar um periódo!');
      setIsLoading(false);
      return;
    }

    try {
      const { data, total } = await fetchDataFromServer(params);
      setItems(data);
      setTotalItems(total);
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar os documentos.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDataFromServer = async (params: QueryParamsEDT) => {
    const query = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        query.append(key, value.toString());
      }
    });

    const response = await api.get(`edt?${query.toString()}`);
    const data = response.data.Data.Items.map((item: Data) => ({
      Matricula: item.Matricula,
      Nome: item.Nome,
      IdFiliado: item.IdFiliado,
      Cpf: item.Cpf,
      Email: item.Email,
      Franquia: item.Franquia,
      Regional: item.Regional,
      DtFiliacao: item.DtFiliacao
    }));
    const total = response.data.Data.TotalItems;
    return { data, total };
  };

  const onSearch = (doc: string, mat: string, nom: string, dtIni: Date | null, dtFinal: Date | null, franquia: number | null, regional: number | null) => {
    setDocumento(doc);
    setMatricula(mat);
    setNome(nom);
    setStartDate(dtIni);
    setEndDate(dtFinal);
    setFranquia(franquia);
    setRegional(regional);
    setSearchTriggered(true);
  };

  const openModal = (item: Data) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setIsModalOpen(false);
  };

  const columns: TableColumn<Data>[] = [
    { header: 'Matricula', accessorKey: 'Matricula' },
    { header: 'Nome', accessorKey: 'Nome' },
    { header: 'Cpf', accessorKey: 'Cpf' },
    { header: 'Email', accessorKey: 'Email' },
    { header: 'Regional', accessorKey: 'Regional' },
    { header: 'Franquia', accessorKey: 'Franquia' },
    {
      header: 'Dt. Filiação', accessorKey: 'DtFiliacao', cell: (info) => {
        const date = new Date(info.getValue() as string);
        return date.toLocaleDateString();
      },
    },
    {
      header: 'Opções',
      cell: ({ row }) => (
        <button
          className="h-6 rounded bg-green-todos px-4 text-white hover:bg-green-dark-todos"
          onClick={() => openModal(row.original)}
        >
          Detalhes
        </button>
      ),
    }
  ];

  return (
    <div className="p-2 ml-3 mr-3  gap-6">
      <ToastContainer />

      <div className="mb-4 grid grid-cols-1 shadow rounded-md">
        <div className="bg-white p-4">

          <SearchForm
            initialDocumento={documento}
            initialMatricula={matricula}
            initialNome={nome}
            initialStartDate={startDate}
            initialEndDate={endDate}
            franquias={[]}
            regionais={[]}
            onSearch={onSearch}
          />
          {error && <p className="mt-2 text-red-500">{error}</p>}
        </div>
      </div>

        <Table
          columns={columns}
          data={items}
          isLoading={isLoading}
        />

      {
        items.length >= 1 &&
        <Pagination
          totalItems={totalItems}
          onPageChange={onPageChange}
          itemsPerPageOptions={[10, 20, 30, 50]}
        />
      }

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-10"
        overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-75"
      >
        <div className="bg-white p-6 rounded-md shadow-md w-2/4">
          <h2 className="text-xl font-bold mb-4">Detalhes do Documento</h2>
          {selectedItem && (
            <SmartShareList initialDocumento={selectedItem.Cpf} />
          )}
          <button
            onClick={closeModal}
            className="mt-4 rounded bg-green-todos px-4 py-2 text-white hover:bg-green-dark-todos"
          >
            Fechar
          </button>
        </div>
      </Modal>
    </div>
  )

}

export default EDTList;