/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const SpecialProjects = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M3.53852 4C3.15276 4 2.78279 4.15325 2.51001 4.42603C2.23723 4.6988 2.08398 5.06877 2.08398 5.45454V17.8181C2.08398 18.2039 2.23723 18.5739 2.51001 18.8467C2.78279 19.1194 3.15276 19.2727 3.53852 19.2727H10.8112C11.2129 19.2727 11.5385 18.9471 11.5385 18.5454C11.5385 18.1437 11.2129 17.8181 10.8112 17.8181H3.53852L3.53852 5.45454L8.38127 5.45454L8.38636 5.45633L10.9119 7.34595L10.9136 7.34723C11.1644 7.53374 11.4684 7.63507 11.781 7.63635H19.5385V10.5454C19.5385 10.9471 19.8641 11.2727 20.2657 11.2727C20.6674 11.2727 20.993 10.9471 20.993 10.5454V7.63635C20.993 7.25058 20.8398 6.88062 20.567 6.60784C20.2942 6.33506 19.9242 6.18181 19.5385 6.18181H11.7866L11.7816 6.18002L9.25602 4.2904L9.25429 4.28912C9.00347 4.10261 8.69952 4.00129 8.38695 4H3.53852Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6611 10.9852C17.5462 10.7183 17.2836 10.5454 16.9931 10.5454C16.7025 10.5454 16.4399 10.7183 16.325 10.9852L15.2896 13.3908L12.573 13.6022C12.2774 13.6252 12.0254 13.8253 11.936 14.1079C11.8466 14.3906 11.9378 14.6993 12.1664 14.888L14.2132 16.5782L13.5873 19.0973C13.5166 19.3816 13.6234 19.6805 13.8582 19.8556C14.093 20.0308 14.4099 20.048 14.6623 19.8993L16.9931 18.5259L19.3238 19.8993C19.5762 20.048 19.8931 20.0308 20.1279 19.8556C20.3627 19.6805 20.4695 19.3816 20.3989 19.0973L19.7729 16.5782L21.8198 14.888C22.0484 14.6993 22.1395 14.3906 22.0501 14.1079C21.9607 13.8253 21.7087 13.6252 21.4131 13.6022L18.6965 13.3908L17.6611 10.9852ZM16.452 14.3693L16.9931 13.1122L17.5341 14.3693C17.6409 14.6174 17.8764 14.7859 18.1457 14.8069L19.5055 14.9127L18.4936 15.7483C18.2778 15.9265 18.1834 16.2128 18.2509 16.4844L18.5694 17.7665L17.3623 17.0552C17.1344 16.9209 16.8517 16.9209 16.6239 17.0552L15.4167 17.7665L15.7352 16.4844C15.8027 16.2128 15.7083 15.9265 15.4925 15.7483L14.4806 14.9127L15.8404 14.8069C16.1097 14.7859 16.3452 14.6174 16.452 14.3693Z"
      fill={color}
    />
  </>
);
