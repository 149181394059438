/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const AddCourse = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      d="M18.4249 3.21417L19.5604 7.45061C19.7047 7.98894 19.222 8.49459 18.6674 8.4398C18.3471 8.40819 18.0632 8.19086 17.9798 7.87995L17.8932 7.55678L14.7286 8.4025L14.7269 8.40298L14.7931 8.65304C14.912 9.10189 14.6445 9.56216 14.1957 9.68103C13.7469 9.79995 13.2866 9.53246 13.1677 9.08361C13.0721 8.7226 12.9769 8.36153 12.8817 8.00052L12.8736 7.96975C12.5182 6.62109 12.1628 5.27301 11.7854 3.9296H8.52361V16.2753H9.30971C9.75484 16.2753 10.1157 16.6362 10.1157 17.0813C10.1157 17.5264 9.75484 17.8873 9.30971 17.8873H8.52361V20.2386H9.30137C9.59655 20.2386 9.86037 20.4218 9.98561 20.6892C10.2313 21.2137 9.87413 21.8694 9.29494 21.8694H8.52361C8.22652 21.8694 7.94803 21.7901 7.70817 21.6513C7.46826 21.7901 7.18977 21.8694 6.89271 21.8694H3.6309C2.73018 21.8694 2 21.1393 2 20.2386V3.9296C2 3.02888 2.73018 2.29867 3.6309 2.29867H6.89271C7.18977 2.29867 7.46826 2.37811 7.70817 2.51685C7.94803 2.37811 8.22652 2.29867 8.52361 2.29867H11.7854C12.3213 2.29867 12.7969 2.55719 13.0942 2.9563L16.4188 2.05626C17.2925 1.82204 18.1906 2.34049 18.4249 3.21417ZM17.4709 5.98148L14.3093 6.82641L14.3019 6.79846L14.2989 6.79926L13.6789 4.48626L16.8429 3.6382L17.4709 5.98148ZM6.89271 8.00684V20.2386H3.6309V8.00684H6.89271ZM6.89271 6.37592V3.9296H3.6309V6.37592H6.89271Z"
      fill={color}
    />
    <path
      d="M16.9475 14.6368C16.9475 14.3428 17.1859 14.1044 17.4799 14.1044C17.7739 14.1044 18.0123 14.3428 18.0123 14.6368V16.1135H19.489C19.783 16.1135 20.0214 16.3518 20.0214 16.6459C20.0214 16.9399 19.783 17.1783 19.489 17.1783H18.0123V18.6549C18.0123 18.9489 17.7739 19.1873 17.4799 19.1873C17.1859 19.1873 16.9475 18.9489 16.9475 18.6549V17.1783H15.4708C15.1768 17.1783 14.9384 16.9399 14.9384 16.6459C14.9384 16.3518 15.1768 16.1135 15.4708 16.1135H16.9475V14.6368Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      d="M12.1258 16.6459C12.1258 13.6889 14.5229 11.2917 17.4799 11.2917C20.4369 11.2917 22.834 13.6889 22.834 16.6459C22.834 19.6029 20.4369 22 17.4799 22C14.5229 22 12.1258 19.6029 12.1258 16.6459ZM17.4799 12.3565C15.111 12.3565 13.1906 14.2769 13.1906 16.6459C13.1906 19.0148 15.111 20.9352 17.4799 20.9352C19.8488 20.9352 21.7693 19.0148 21.7693 16.6459C21.7693 14.2769 19.8488 12.3565 17.4799 12.3565Z"
      fill={color}
    />
  </>
);
