/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const History = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M16.0794 2.7291C14.3269 2.00364 12.3987 1.81422 10.5386 2.18481C8.67877 2.55535 6.97058 3.46909 5.63007 4.81049L3.598 6.83659V4.76655C3.598 4.32528 3.24027 3.96755 2.799 3.96755C2.35772 3.96755 2 4.32528 2 4.76655L2 8.76142C2 8.87217 2.02253 8.97777 2.06326 9.07366C2.09755 9.15455 2.14578 9.23095 2.20795 9.29919C2.22463 9.31752 2.24216 9.33508 2.26047 9.35179C2.32612 9.41178 2.39937 9.45885 2.47694 9.49298C2.57546 9.53642 2.68441 9.56054 2.799 9.56054H6.79399C7.23526 9.56054 7.59299 9.20281 7.59299 8.76154C7.59299 8.32027 7.23526 7.96254 6.79399 7.96254H4.73196L6.75888 5.94158L6.75995 5.94052C7.87711 4.82245 9.30077 4.06083 10.8509 3.752C12.4009 3.44318 14.0078 3.60103 15.4681 4.20558C16.9285 4.81013 18.1768 5.83422 19.055 7.14831C19.9332 8.4624 20.402 10.0074 20.402 11.588C20.402 13.1685 19.9332 14.7136 19.055 16.0277C18.1768 17.3417 16.9285 18.3658 15.4681 18.9704C14.0078 19.5749 12.4009 19.7328 10.8509 19.424C9.30077 19.1151 7.87711 18.3535 6.75995 17.2354C6.44805 16.9233 5.94215 16.9231 5.62999 17.235C5.31784 17.5469 5.31763 18.0528 5.62953 18.3649C6.97012 19.7066 8.67852 20.6206 10.5386 20.9912C12.3987 21.3617 14.3269 21.1723 16.0794 20.4469C17.8318 19.7214 19.3297 18.4925 20.3836 16.9156C21.4375 15.3387 22 13.4846 22 11.588C22 9.69133 21.4375 7.83728 20.3836 6.26037C19.3297 4.68347 17.8318 3.45456 16.0794 2.7291Z"
      fill={color}
    />
    <path
      d="M13.2059 6.79399C13.2059 6.35271 12.8481 5.99499 12.4069 5.99499C11.9656 5.99499 11.6079 6.35271 11.6079 6.79399V11.5748C11.6075 11.6004 11.6083 11.626 11.6104 11.6515C11.6201 11.7756 11.6583 11.8919 11.7183 11.9936C11.774 12.0883 11.8498 12.1725 11.9439 12.2393C11.9684 12.2567 11.9938 12.2727 12.0202 12.2874L16.1624 14.6771C16.5446 14.8976 17.0333 14.7665 17.2538 14.3843C17.4743 14.0021 17.3432 13.5134 16.961 13.2929L13.2059 11.1265V6.79399Z"
      fill={color}
    />
  </>
);
