/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Confirm = ({ color = '#191919' }: IconProps) => (
  <path
    fill={color}
    d="M9.073 19.145a1.13 1.13 0 0 1-.825-.353l-5.894-5.894A1.13 1.13 0 0 1 2 12.073c0-.315.118-.59.354-.825.235-.236.51-.354.825-.354.314 0 .59.118.825.354l5.069 5.068L20.035 5.354A1.13 1.13 0 0 1 20.86 5c.236 0 .433.079.63.196l.156.158c.472.471.472 1.178 0 1.69L9.898 18.791a1.13 1.13 0 0 1-.825.353Z"
  />
);
