/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Loading = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M12 2C12.4248 2 12.7692 2.3444 12.7692 2.76923V5.84615C12.7692 6.27099 12.4248 6.61538 12 6.61538C11.5752 6.61538 11.2308 6.27099 11.2308 5.84615V2.76923C11.2308 2.3444 11.5752 2 12 2Z"
      fill={color}
    />
    <path
      d="M17.3846 12C17.3846 11.5752 17.729 11.2308 18.1538 11.2308H21.2308C21.6556 11.2308 22 11.5752 22 12C22 12.4248 21.6556 12.7692 21.2308 12.7692H18.1538C17.729 12.7692 17.3846 12.4248 17.3846 12Z"
      fill={color}
    />
    <path
      d="M16.8998 15.8119C16.5994 15.5115 16.1123 15.5115 15.8119 15.8119C15.5115 16.1123 15.5115 16.5994 15.8119 16.8998L17.985 19.0728C18.2854 19.3733 18.7724 19.3733 19.0728 19.0728C19.3733 18.7724 19.3733 18.2854 19.0728 17.985L16.8998 15.8119Z"
      fill={color}
    />
    <path
      d="M12 17.3846C12.4248 17.3846 12.7692 17.729 12.7692 18.1538V21.2308C12.7692 21.6556 12.4248 22 12 22C11.5752 22 11.2308 21.6556 11.2308 21.2308V18.1538C11.2308 17.729 11.5752 17.3846 12 17.3846Z"
      fill={color}
    />
    <path
      d="M8.18812 16.8998C8.48852 16.5994 8.48852 16.1123 8.18812 15.8119C7.88772 15.5115 7.40067 15.5115 7.10027 15.8119L4.92719 17.985C4.62678 18.2854 4.62678 18.7724 4.92719 19.0728C5.22759 19.3733 5.71464 19.3733 6.01504 19.0728L8.18812 16.8998Z"
      fill={color}
    />
    <path
      d="M2 12C2 11.5752 2.3444 11.2308 2.76923 11.2308H5.84615C6.27099 11.2308 6.61538 11.5752 6.61538 12C6.61538 12.4248 6.27099 12.7692 5.84615 12.7692H2.76923C2.3444 12.7692 2 12.4248 2 12Z"
      fill={color}
    />
    <path
      d="M6.01504 4.92719C5.71464 4.62678 5.22759 4.62678 4.92719 4.92719C4.62678 5.22759 4.62678 5.71464 4.92719 6.01504L7.10027 8.18812C7.40067 8.48852 7.88772 8.48852 8.18812 8.18812C8.48852 7.88772 8.48852 7.40067 8.18812 7.10027L6.01504 4.92719Z"
      fill={color}
    />
  </>
);
