/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Discipline = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M9.26915 10.8889C8.86005 10.8889 8.52841 11.2205 8.52841 11.6296C8.52841 12.0387 8.86005 12.3704 9.26915 12.3704H15.1951C15.6042 12.3704 15.9358 12.0387 15.9358 11.6296C15.9358 11.2205 15.6042 10.8889 15.1951 10.8889H9.26915Z"
      fill={color}
    />
    <path
      d="M9.26915 13.8519C8.86005 13.8519 8.52841 14.1835 8.52841 14.5926C8.52841 15.0017 8.86005 15.3333 9.26915 15.3333H15.1951C15.6042 15.3333 15.9358 15.0017 15.9358 14.5926C15.9358 14.1835 15.6042 13.8519 15.1951 13.8519H9.26915Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.91942 3.48148C8.97426 3.42017 9.03094 3.36023 9.08942 3.30175C9.92292 2.46825 11.0534 2 12.2321 2C13.4109 2 14.5413 2.46825 15.3748 3.30175C15.4333 3.36023 15.49 3.42017 15.5448 3.48148H18.8988C19.2917 3.48148 19.6685 3.63757 19.9464 3.9154C20.2242 4.19323 20.3803 4.57005 20.3803 4.96296V20.5185C20.3803 20.9114 20.2242 21.2882 19.9464 21.5661C19.6685 21.8439 19.2917 22 18.8988 22H5.56545C5.17254 22 4.79572 21.8439 4.51789 21.5661C4.24005 21.2883 4.08397 20.9114 4.08397 20.5185V4.96296C4.08397 4.57005 4.24005 4.19323 4.51789 3.9154C4.79572 3.63757 5.17254 3.48148 5.56545 3.48148H8.91942ZM10.137 4.34931C10.6926 3.79365 11.4463 3.48148 12.2321 3.48148C13.0179 3.48148 13.7716 3.79365 14.3272 4.34931C14.4249 4.44699 14.5151 4.55079 14.5973 4.6598C14.6005 4.66416 14.6038 4.66848 14.6071 4.67277C14.9866 5.1815 15.1951 5.80222 15.1951 6.44444H9.26915C9.26915 5.80222 9.47765 5.1815 9.85717 4.67277C9.86046 4.66848 9.8637 4.66416 9.8669 4.6598C9.94915 4.55079 10.0393 4.44699 10.137 4.34931ZM8.04185 4.96296H5.56545L5.56545 20.5185H18.8988V4.96296H16.4224C16.5891 5.43438 16.6766 5.9349 16.6766 6.44444V7.18518C16.6766 7.59428 16.3449 7.92593 15.9358 7.92593H8.52841C8.11931 7.92593 7.78767 7.59428 7.78767 7.18518V6.44444C7.78767 5.9349 7.87517 5.43438 8.04185 4.96296Z"
      fill={color}
    />
  </>
);
