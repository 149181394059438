/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const MyAvaliations = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fill={color}
      fillRule="evenodd"
      d="M7.97 2.803A.803.803 0 0 0 7.168 2H3.689a1.605 1.605 0 0 0-1.605 1.605V15.58c0 .212.085.417.235.567l5.619 5.619c.15.15.355.235.567.235h9.632a1.605 1.605 0 0 0 1.606-1.605v-5.418a.803.803 0 1 0-1.605 0v5.418h-8.83V16.38c0-.886-.719-1.605-1.605-1.605H3.689V3.606h3.479c.443 0 .802-.36.802-.803ZM4.824 16.38l2.879 2.878v-2.878H4.824Z"
      clipRule="evenodd"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M13.779 4.274a.736.736 0 0 0-1.316 0l-2.201 4.402a.79.79 0 0 0-.013.026l-.73 1.458a.736.736 0 1 0 1.317.659l.532-1.065h3.505l.533 1.065a.736.736 0 1 0 1.316-.659l-.728-1.456a.747.747 0 0 0-.015-.03l-2.2-4.4Zm.359 4.008L13.12 6.25l-1.017 2.033h2.034Z"
      clipRule="evenodd"
    />
    <path
      fill={color}
      d="M16.8 7.552c0-.407.33-.736.735-.736h1.104V5.712a.736.736 0 0 1 1.472 0v1.104h1.103a.736.736 0 1 1 0 1.472h-1.103V9.39a.736.736 0 1 1-1.472 0V8.288h-1.104a.736.736 0 0 1-.735-.736Z"
    />
  </>
);
