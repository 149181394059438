/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Send = ({ color = '#191919' }: IconProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M20.9888 2.04542C20.7372 1.98104 20.4729 1.98517 20.2234 2.05735L3.16274 6.86254L3.15957 6.86341C2.87025 6.94321 2.61239 7.10994 2.42089 7.34109C2.22869 7.57308 2.11268 7.85868 2.08865 8.15898C2.06463 8.45929 2.13376 8.7597 2.28664 9.0193C2.43839 9.277 2.66535 9.48204 2.93696 9.60695L10.7707 13.3133L14.477 21.147C14.6019 21.4186 14.807 21.6456 15.0647 21.7973C15.3243 21.9502 15.6247 22.0194 15.925 21.9953C16.2253 21.9713 16.5109 21.8553 16.7429 21.6631C16.9741 21.4716 17.1408 21.2137 17.2206 20.9244L17.2214 20.9212L22.0266 3.86066C22.0988 3.61115 22.1029 3.34685 22.0386 3.09517C21.9738 2.84212 21.8422 2.61114 21.6575 2.42645C21.4728 2.24176 21.2419 2.11015 20.9888 2.04542ZM20.6226 3.46336L20.6306 3.46478L20.6257 3.46486L20.6226 3.46336ZM3.54916 8.27584L20.6147 3.46929L15.8096 20.5294L15.8082 20.5349L15.8034 20.5246L12.2037 12.9164L15.9798 9.14033C16.2659 8.85424 16.2659 8.3904 15.9798 8.10431C15.6937 7.81823 15.2298 7.81823 14.9438 8.10431L11.1677 11.8803L3.55936 8.28056L3.54916 8.27584Z"
    fill={color}
  />
);
