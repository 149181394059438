/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const AddAvaliation = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fill={color}
      fillRule="evenodd"
      d="M8.08361 2C8.52692 2 8.88629 2.35937 8.88629 2.80268C8.88629 3.24598 8.52692 3.60535 8.08361 3.60535H4.60535V14.7759H8.61873C9.50534 14.7759 10.2241 15.4947 10.2241 16.3813V20.3946H19.0535V14.9766C19.0535 14.5333 19.4129 14.1739 19.8562 14.1739C20.2995 14.1739 20.6589 14.5333 20.6589 14.9766V20.3946C20.6589 20.8204 20.4897 21.2287 20.1887 21.5298C19.8876 21.8309 19.4793 22 19.0535 22H9.4214C9.20852 22 9.00436 21.9154 8.85383 21.7649L3.2351 16.1462C3.08457 15.9956 3 15.7915 3 15.5786V3.60535C3 3.17959 3.16913 2.77126 3.4702 2.4702C3.77126 2.16913 4.17959 2 4.60535 2H8.08361ZM8.61873 19.2595L5.74051 16.3813H8.61873V19.2595Z"
    />
    <path
      d="M15.6589 3.3326C15.2498 3.3326 14.9182 3.66423 14.9182 4.07332V7.2931H11.4509C11.0418 7.2931 10.7102 7.62474 10.7102 8.03383C10.7102 8.44291 11.0418 8.77454 11.4509 8.77454H14.9182V11.9943C14.9182 12.4034 15.2498 12.7351 15.6589 12.7351C16.068 12.7351 16.3996 12.4034 16.3996 11.9943V8.77454H19.867C20.276 8.77454 20.6077 8.44291 20.6077 8.03383C20.6077 7.62474 20.276 7.2931 19.867 7.2931H16.3996V4.07332C16.3996 3.66423 16.068 3.3326 15.6589 3.3326Z"
      fill="#191919"
      fillRule="evenodd"
    />
  </>
);
