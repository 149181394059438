/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const FilterClear = ({
  color = '#191919',
}: IconProps) => (
  <path
    d='M11.2748 15.3583L13.6498 13L11.2748 10.6417L12.4581 9.46667L14.8331 11.8083L17.1665 9.46667L18.3498 10.6417L16.0081 13L18.3498 15.3583L17.1665 16.5333L14.8331 14.1667L12.4581 16.5333L11.2748 15.3583ZM9.83312 14.5667C9.86645 14.8167 9.78312 15.0833 9.59145 15.2583C9.51436 15.3356 9.42278 15.3969 9.32197 15.4387C9.22116 15.4805 9.11309 15.502 9.00395 15.502C8.89481 15.502 8.78674 15.4805 8.68593 15.4387C8.58512 15.3969 8.49355 15.3356 8.41645 15.2583L5.07478 11.9167C4.9839 11.8278 4.91479 11.7192 4.87286 11.5992C4.83094 11.4792 4.81734 11.3511 4.83312 11.225V6.95833L0.841451 1.85C0.706124 1.67628 0.645062 1.45605 0.671607 1.23744C0.698152 1.01883 0.810146 0.819622 0.983117 0.683333C1.14145 0.566667 1.31645 0.5 1.49978 0.5H13.1665C13.3498 0.5 13.5248 0.566667 13.6831 0.683333C13.8561 0.819622 13.9681 1.01883 13.9946 1.23744C14.0212 1.45605 13.9601 1.67628 13.8248 1.85L9.83312 6.95833V14.5667ZM3.19978 2.16667L6.49978 6.38333V10.9833L8.16645 12.65V6.375L11.4665 2.16667H3.19978Z'
    fill={color}
  />
);
