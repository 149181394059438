/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Apps = ({ color = '#191919' }: IconProps) => (
  <path
    d="M9.27273 2.14279H3.81818C3.33597 2.14279 2.87351 2.33435 2.53253 2.67532C2.19156 3.0163 2 3.47876 2 3.96097V9.41552C2 9.89773 2.19156 10.3602 2.53253 10.7012C2.87351 11.0421 3.33597 11.2337 3.81818 11.2337H9.27273C9.75494 11.2337 10.2174 11.0421 10.5584 10.7012C10.8994 10.3602 11.0909 9.89773 11.0909 9.41552V3.96097C11.0909 3.47876 10.8994 3.0163 10.5584 2.67532C10.2174 2.33435 9.75494 2.14279 9.27273 2.14279ZM9.27273 9.41552H3.81818V3.96097H9.27273V9.41552ZM20.1818 2.14279H14.7273C14.2451 2.14279 13.7826 2.33435 13.4416 2.67532C13.1006 3.0163 12.9091 3.47876 12.9091 3.96097V9.41552C12.9091 9.89773 13.1006 10.3602 13.4416 10.7012C13.7826 11.0421 14.2451 11.2337 14.7273 11.2337H20.1818C20.664 11.2337 21.1265 11.0421 21.4675 10.7012C21.8084 10.3602 22 9.89773 22 9.41552V3.96097C22 3.47876 21.8084 3.0163 21.4675 2.67532C21.1265 2.33435 20.664 2.14279 20.1818 2.14279ZM20.1818 9.41552H14.7273V3.96097H20.1818V9.41552ZM9.27273 13.0519H3.81818C3.33597 13.0519 2.87351 13.2434 2.53253 13.5844C2.19156 13.9254 2 14.3879 2 14.8701V20.3246C2 20.8068 2.19156 21.2693 2.53253 21.6103C2.87351 21.9512 3.33597 22.1428 3.81818 22.1428H9.27273C9.75494 22.1428 10.2174 21.9512 10.5584 21.6103C10.8994 21.2693 11.0909 20.8068 11.0909 20.3246V14.8701C11.0909 14.3879 10.8994 13.9254 10.5584 13.5844C10.2174 13.2434 9.75494 13.0519 9.27273 13.0519ZM9.27273 20.3246H3.81818V14.8701H9.27273V20.3246ZM20.1818 13.0519H14.7273C14.2451 13.0519 13.7826 13.2434 13.4416 13.5844C13.1006 13.9254 12.9091 14.3879 12.9091 14.8701V20.3246C12.9091 20.8068 13.1006 21.2693 13.4416 21.6103C13.7826 21.9512 14.2451 22.1428 14.7273 22.1428H20.1818C20.664 22.1428 21.1265 21.9512 21.4675 21.6103C21.8084 21.2693 22 20.8068 22 20.3246V14.8701C22 14.3879 21.8084 13.9254 21.4675 13.5844C21.1265 13.2434 20.664 13.0519 20.1818 13.0519ZM20.1818 20.3246H14.7273V14.8701H20.1818V20.3246Z"
    fill={color}
    fillRule="evenodd"
  />
);
