/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Attach = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      d="M14.2142 3.00161C14.9499 2.33493 15.9139 1.97673 16.9065 2.00117C17.8991 2.02561 18.8443 2.43082 19.5463 3.1329C20.2484 3.83497 20.6536 4.78015 20.6781 5.77274C20.7025 6.76533 20.3443 7.7293 19.6776 8.46508C19.6703 8.47315 19.6628 8.48107 19.6552 8.48882L10.053 18.2264C10.0428 18.2367 10.0324 18.2467 10.0216 18.2565C9.58015 18.6565 9.00176 18.8714 8.40621 18.8567C7.81066 18.8421 7.24355 18.5989 6.82231 18.1777C6.40106 17.7564 6.15794 17.1893 6.14327 16.5938C6.12861 15.9982 6.34353 15.4199 6.74354 14.9784C6.75062 14.9706 6.75786 14.9629 6.76525 14.9554L14.8202 6.76501C15.1198 6.4604 15.6096 6.45632 15.9142 6.75589C16.2188 7.05547 16.2229 7.54526 15.9233 7.84987L7.88135 16.027C7.75362 16.1729 7.6852 16.3616 7.68998 16.5557C7.69487 16.7542 7.77591 16.9433 7.91633 17.0837C8.05674 17.2241 8.24578 17.3051 8.44429 17.31C8.6368 17.3148 8.82393 17.2475 8.96927 17.1219L18.5412 7.41495C18.9347 6.97493 19.1459 6.40132 19.1314 5.81082C19.1167 5.21527 18.8736 4.64816 18.4523 4.22691C18.0311 3.80567 17.464 3.56254 16.8684 3.54788C16.2796 3.53338 15.7076 3.74331 15.268 4.13465L5.67844 13.8594C4.95411 14.5838 4.54718 15.5662 4.54718 16.5905C4.54718 17.6149 4.9541 18.5972 5.67842 19.3216C6.40275 20.0459 7.38515 20.4528 8.4095 20.4528C9.43385 20.4528 10.4162 20.0459 11.1406 19.3216L19.0802 11.4013C19.3827 11.0996 19.8725 11.1002 20.1742 11.4026C20.4759 11.7051 20.4753 12.1949 20.1729 12.4966L12.2346 20.4156C11.2201 21.4301 9.84418 22 8.4095 22C6.97481 22 5.59888 21.4301 4.5844 20.4156C3.56993 19.4011 3 18.0252 3 16.5905C3 15.1568 3.56918 13.7817 4.58241 12.7674L14.1828 3.03171C14.1929 3.02138 14.2034 3.01135 14.2142 3.00161Z"
      fill={color}
    />
  </>
);
