/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Lock = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M12.9999 15.3334C12.9999 15.9471 12.5025 16.4445 11.8888 16.4445C11.2752 16.4445 10.7777 15.9471 10.7777 15.3334C10.7777 14.7198 11.2752 14.2223 11.8888 14.2223C12.5025 14.2223 12.9999 14.7198 12.9999 15.3334Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.81491 6.07407V8.66687H4.48148C3.66328 8.66687 3 9.33015 3 10.1484V20.5187C3 21.3369 3.66328 22.0002 4.48148 22.0002H19.2963C20.1145 22.0002 20.7778 21.3369 20.7778 20.5187V10.1484C20.7778 9.33015 20.1145 8.66687 19.2963 8.66687H15.9631V6.07407C15.9631 4.99356 15.5338 3.95731 14.7698 3.19327C14.0058 2.42923 12.9695 2 11.889 2C10.8085 2 9.77222 2.42923 9.00818 3.19327C8.24414 3.95731 7.81491 4.99356 7.81491 6.07407ZM11.889 3.48148C11.2014 3.48148 10.542 3.75463 10.0557 4.24083C9.56954 4.72704 9.29639 5.38648 9.29639 6.07407V8.66687H14.4816V6.07407C14.4816 5.38648 14.2084 4.72704 13.7222 4.24083C13.236 3.75463 12.5766 3.48148 11.889 3.48148ZM19.2963 10.1484H4.48148V20.5187H19.2963V10.1484Z"
      fill={color}
    />
  </>
);
