/* eslint-disable */
import jwt_decode from 'jwt-decode';
import { createPKCECodes, PKCECodePair } from './pkce';
import { goToHref, toUrlEncoded } from './util';

export interface AuthServiceProps {
  clientId: string;
  clientSecret?: string;
  contentType?: string;
  provider: string;
  authorizeEndpoint?: string;
  tokenEndpoint?: string;
  logoutEndpoint?: string;
  audience?: string;
  redirectUri?: string;
  scopes: string[];
  autoRefresh?: boolean;
  refreshSlack?: number;
  durableLogin: boolean;
}

export interface AuthTokens {
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  expires_at?: number; // calculated on login
  token_type: string;
}

export interface JWTIDToken {
  name: string;
  email: string;
  documento: string;
  picture: string;
  id_pessoa: number;
  id_funcionario: number;
  id_filiado: number;
  id_promotorvendas: number;
  phone_number: string;
}

export interface TokenRequestBody {
  clientId: string;
  grantType: string;
  redirectUri?: string;
  refresh_token?: string;
  clientSecret?: string;
  code?: string;
  codeVerifier?: string;
}

export class AuthService<TIDToken = JWTIDToken> {
  props: AuthServiceProps;
  timeout?: number;

  constructor(props: AuthServiceProps) {
    this.props = props;
  }

  async loadDataFromCodeIfNeeded() {

    const code = this.getCodeFromLocation(window.location);

    if (code !== null) {
      try {
        await this.fetchToken(code);
        this.restoreUri();
      } catch (e) {
        this.removeItem('pkce');
        this.removeItem('auth');
        this.removeCodeFromLocation();
        console.warn({ e });
      }
    } else if (this.props.autoRefresh) {
      this.startTimer();
    }
  }

  getUser(): TIDToken | null {
    let result: TIDToken | null = null;
    const t = this.getAuthTokens();
    if (t?.id_token) {
      result = jwt_decode(t.id_token) as TIDToken;
    }
    return result;
  }

  get storage(): Storage {
    const { durableLogin } = this.props;
    return durableLogin ? window.localStorage : window.sessionStorage;
  }

  getCodeFromLocation(location: Location): string | null {
    const split = location.toString().split('?');
    if (split.length < 2) {
      return null;
    }
    const pairs = split[1].split('&');
    for (const pair of pairs) {
      const [key, value] = pair.split('=');
      if (key === 'code') {
        return decodeURIComponent(value || '');
      }
    }
    return null;
  }

  removeCodeFromLocation(): void {
    const [base, search] = window.location.href.split('?');
    if (!search) {
      return;
    }
    const newSearch = search
      .split('&')
      .map((param) => param.split('='))
      .filter(([key]) => key !== 'code')
      .map((keyAndVal) => keyAndVal.join('='))
      .join('&');
    window.history.replaceState(window.history.state, 'null', base + (newSearch.length ? `?${newSearch}` : ''));
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }
  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  getPkce(): PKCECodePair {
    const pkce = this.storage.getItem('pkce');
    if (null === pkce) {
      throw new Error('PKCE pair not found in local storage');
    } else {
      return JSON.parse(pkce);
    }
  }

  setAuthTokens(auth: AuthTokens): void {
    const { refreshSlack = 5 } = this.props;
    const now = new Date().getTime();
    auth.expires_at = now + (auth.expires_in + refreshSlack) * 1000;
    this.storage.setItem('auth', JSON.stringify(auth));
  }

  getAuthTokens(): AuthTokens {
    return JSON.parse(this.storage.getItem('auth') || '{}');
  }

  isPending(): boolean {
    return this.storage.getItem('pkce') !== null && this.storage.getItem('auth') === null;
  }

  isAuthenticated(): boolean {
    return this.storage.getItem('auth') !== null;
  }

  logout(shouldEndSession = false): boolean {
    this.removeItem('pkce');
    this.removeItem('auth');
    if (shouldEndSession) {
      const { clientId, provider, logoutEndpoint, redirectUri } = this.props;
      const query = {
        client_id: clientId,
        post_logout_redirect_uri: redirectUri,
      };
      const url = `${logoutEndpoint || `${provider}/endsession`}?${toUrlEncoded(query)}`;
      window.location.replace(url);
      return true;
    } else {
      window.location.reload();
      return true;
    }
  }

  async login(): Promise<void> {
    this.authorize();
  }

  // this will do a full page reload and to to the OAuth2 provider's login page and then redirect back to redirectUri
  async authorize(): Promise<boolean> {
    const { clientId, provider, authorizeEndpoint, redirectUri, scopes, audience } = this.props;

    const pkce = createPKCECodes();

    this.storage.setItem('pkce', JSON.stringify(pkce));
    this.storage.setItem('preAuthUri', window.location.href);
    this.storage.removeItem('auth');
    const codeChallenge = await pkce.codeChallenge;

    const query = {
      clientId,
      scope: scopes.join(' '),
      responseType: 'code',
      redirectUri,
      ...(audience && { audience }),
      codeChallenge,
      codeChallengeMethod: 'S256',
    };

    // Responds with a 302 redirect
    const url = `${authorizeEndpoint || `${provider}/authorize`}?${toUrlEncoded(query)}`;
    window.location.replace(url);
    return true;
  }

  // this happens after a full page reload. Read the code from localstorage
  async fetchToken(code: string, isRefresh = false): Promise<AuthTokens> {
    
    const { clientId, clientSecret, contentType, provider, tokenEndpoint, redirectUri, autoRefresh = true } = this.props;
    const grantType = 'authorization_code';

    let payload: TokenRequestBody = {
      clientId,
      ...(clientSecret ? { clientSecret } : {}),
      redirectUri,
      grantType,
    };
    if (isRefresh) {
      payload = {
        ...payload,
        grantType: 'refresh_token',
        refresh_token: code,
      };
    } else {
      const pkce: PKCECodePair = this.getPkce();
      const codeVerifier = pkce.codeVerifier;
      payload = {
        ...payload,
        code,
        codeVerifier,
      };
    }

    const response = await window.fetch(`${tokenEndpoint || `${provider}/token`}`, {
      headers: {
        'Content-Type': contentType || 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: toUrlEncoded(payload),
    });

    this.removeItem('pkce');
    const json = await response.json();
    if (isRefresh && !json.refresh_token) {
      json.refresh_token = payload.refresh_token;
    }
    this.setAuthTokens(json as AuthTokens);
    if (autoRefresh) {
      this.startTimer();
    }
    return this.getAuthTokens();
  }

  armRefreshTimer(refreshToken: string, timeoutDuration: number): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(() => {
      this.fetchToken(refreshToken, true)
        .then(({ refresh_token: newRefreshToken, expires_at: expiresAt }) => {
          if (!expiresAt) return;
          const now = new Date().getTime();
          const timeout = expiresAt - now;
          if (timeout > 0) {
            this.armRefreshTimer(newRefreshToken, timeout);
          } else {
            this.removeItem('auth');
            this.removeCodeFromLocation();
          }
        })
        .catch((e) => {
          this.removeItem('auth');
          this.removeCodeFromLocation();
          console.warn({ e });
        });
    }, timeoutDuration);
  }

  startTimer(): void {
    const authTokens = this.getAuthTokens();
    if (!authTokens) {
      return;
    }
    const { refresh_token: refreshToken, expires_at: expiresAt } = authTokens;
    if (!expiresAt || !refreshToken) {
      return;
    }
    const now = new Date().getTime();
    const timeout = expiresAt - now;
    if (timeout > 0) {
      this.armRefreshTimer(refreshToken, timeout);
    } else {
      this.removeItem('auth');
      this.removeCodeFromLocation();
    }
  }

  restoreUri(): void {
    const uri = this.storage.getItem('preAuthUri');
    this.storage.removeItem('preAuthUri');
    if (uri !== null) {
      window.location.replace(uri);
    }
    this.removeCodeFromLocation();
  }
}
