/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Pdf = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.55261 2.46863C3.85267 2.16857 4.25964 2 4.68398 2H14.284C14.4962 2 14.6996 2.08429 14.8497 2.23431L20.4497 7.83431C20.5997 7.98434 20.684 8.18783 20.684 8.4V12.4C20.684 12.8418 20.3258 13.2 19.884 13.2C19.4422 13.2 19.084 12.8418 19.084 12.4V9.23301H14.4866C13.9343 9.23301 13.4866 8.78529 13.4866 8.23301V3.6L4.68398 3.6L4.68398 12.4C4.68398 12.8418 4.32581 13.2 3.88398 13.2C3.44216 13.2 3.08398 12.8418 3.08398 12.4V3.6C3.08398 3.17565 3.25256 2.76869 3.55261 2.46863ZM15.0866 4.73401L17.9856 7.63301H15.0866V4.73401Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.88398 15.6C3.44216 15.6 3.08398 15.9582 3.08398 16.4V21.2C3.08398 21.6418 3.44216 22 3.88398 22C4.32581 22 4.68398 21.6418 4.68398 21.2V20.4H5.48398C6.1205 20.4 6.73095 20.1471 7.18104 19.6971C7.63113 19.247 7.88398 18.6365 7.88398 18C7.88398 17.3635 7.63113 16.753 7.18104 16.3029C6.73095 15.8529 6.1205 15.6 5.48398 15.6H3.88398ZM5.48398 18.8H4.68398V17.2H5.48398C5.69616 17.2 5.89964 17.2843 6.04967 17.4343C6.1997 17.5843 6.28398 17.7878 6.28398 18C6.28398 18.2122 6.1997 18.4157 6.04967 18.5657C5.89964 18.7157 5.69616 18.8 5.48398 18.8Z"
      fill={color}
    />
    <path
      d="M17.884 15.6C17.4422 15.6 17.084 15.9582 17.084 16.4V21.2C17.084 21.6418 17.4422 22 17.884 22C18.3258 22 18.684 21.6418 18.684 21.2V20H20.284C20.7258 20 21.084 19.6418 21.084 19.2C21.084 18.7582 20.7258 18.4 20.284 18.4H18.684V17.2H20.684C21.1258 17.2 21.484 16.8418 21.484 16.4C21.484 15.9582 21.1258 15.6 20.684 15.6H17.884Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.68398 16.4C9.68398 15.9582 10.0422 15.6 10.484 15.6H11.884C12.7327 15.6 13.5466 15.9371 14.1467 16.5373C14.7468 17.1374 15.084 17.9513 15.084 18.8C15.084 19.6487 14.7468 20.4626 14.1467 21.0627C13.5466 21.6629 12.7327 22 11.884 22H10.484C10.0422 22 9.68398 21.6418 9.68398 21.2V16.4ZM11.284 20.4H11.884C12.3083 20.4 12.7153 20.2314 13.0154 19.9314C13.3154 19.6313 13.484 19.2243 13.484 18.8C13.484 18.3757 13.3154 17.9687 13.0154 17.6686C12.7153 17.3686 12.3083 17.2 11.884 17.2H11.284V20.4Z"
      fill={color}
    />
  </>
);
