/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ImageToLeft = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M2.00003 5.11111C2.00003 5.72476 2.49749 6.22222 3.11113 6.22222H20.8889C21.5025 6.22222 22 5.72476 22 5.11111C22 4.49746 21.5025 4 20.8889 4H3.11114C2.49749 4 2.00003 4.49746 2.00003 5.11111ZM2.00003 18.4444C2.00003 17.8308 2.49749 17.3333 3.11113 17.3333H20.8889C21.5025 17.3333 22 17.8308 22 18.4444C22 19.0581 21.5025 19.5555 20.8889 19.5555H3.11114C2.49749 19.5555 2.00003 19.0581 2.00003 18.4444ZM13.1111 14C13.1111 13.3863 13.6086 12.8889 14.2222 12.8889H20.8889C21.5025 12.8889 22 13.3863 22 14C22 14.6136 21.5025 15.1111 20.8889 15.1111H14.2222C13.6086 15.1111 13.1111 14.6136 13.1111 14ZM13.1111 9.55555C13.1111 8.9419 13.6086 8.44444 14.2222 8.44444H20.8889C21.5025 8.44444 22 8.9419 22 9.55555C22 10.1692 21.5025 10.6667 20.8889 10.6667H14.2222C13.6086 10.6667 13.1111 10.1692 13.1111 9.55555Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.2222 15.111C10.1427 15.111 10.8889 14.3648 10.8889 13.4444V10.111C10.8889 9.19063 10.1427 8.44437 9.2222 8.44437H3.66666C2.74618 8.44437 2 9.19063 2 10.111V13.4444C2 14.3648 2.74618 15.111 3.66666 15.111H9.2222ZM9.2222 10.111H3.66666V13.4385L9.2222 13.4444V10.111Z"
      fill={color}
    />
  </>
);
