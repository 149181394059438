/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Templates = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.84615 12.7692H10.4615C10.6656 12.7692 10.8612 12.6882 11.0055 12.544C11.1497 12.3996 11.2308 12.204 11.2308 12V5.84615C11.2308 5.64211 11.1497 5.44651 11.0055 5.30219C10.8612 5.15796 10.6656 5.07692 10.4615 5.07692H5.84615C5.64214 5.07692 5.44648 5.15796 5.30223 5.30219C5.15797 5.44651 5.07692 5.64211 5.07692 5.84615V12C5.07692 12.204 5.15797 12.3996 5.30223 12.544C5.44648 12.6882 5.64214 12.7692 5.84615 12.7692ZM6.61538 6.61538H9.69231V11.2308H6.61538V6.61538Z"
      fill={color}
    />
    <path
      d="M12.9945 6.84065C12.8503 6.98498 12.7692 7.18057 12.7692 7.38462C12.7692 7.58866 12.8503 7.78426 12.9945 7.92858C13.1388 8.07281 13.3344 8.15385 13.5385 8.15385H18.1538C18.3579 8.15385 18.5535 8.07281 18.6978 7.92858C18.842 7.78426 18.9231 7.58866 18.9231 7.38462C18.9231 7.18057 18.842 6.98498 18.6978 6.84065C18.5535 6.69642 18.3579 6.61538 18.1538 6.61538H13.5385C13.3344 6.61538 13.1388 6.69642 12.9945 6.84065Z"
      fill={color}
    />
    <path
      d="M12.7692 10.4615C12.7692 10.2575 12.8503 10.0619 12.9945 9.91757C13.1388 9.77334 13.3344 9.69231 13.5385 9.69231H18.1538C18.3579 9.69231 18.5535 9.77334 18.6978 9.91757C18.842 10.0619 18.9231 10.2575 18.9231 10.4615C18.9231 10.6656 18.842 10.8612 18.6978 11.0055C18.5535 11.1497 18.3579 11.2308 18.1538 11.2308H13.5385C13.3344 11.2308 13.1388 11.1497 12.9945 11.0055C12.8503 10.8612 12.7692 10.6656 12.7692 10.4615Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.07692 15.0769C5.07692 14.6521 5.42132 14.3077 5.84615 14.3077H18.1538C18.5787 14.3077 18.9231 14.6521 18.9231 15.0769V18.1538C18.9231 18.5787 18.5787 18.9231 18.1538 18.9231H5.84615C5.42132 18.9231 5.07692 18.5787 5.07692 18.1538V15.0769ZM6.61538 15.8462H17.3846V17.3846H6.61538V15.8462Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.53846 2H20.4615C20.8696 2 21.2609 2.16207 21.5494 2.45063C21.8379 2.73909 22 3.13047 22 3.53846V20.4615C22 21.3112 21.3112 22 20.4615 22H3.53846C2.68879 22 2 21.3112 2 20.4615V3.53846C2 3.13047 2.16209 2.73909 2.4506 2.45063C2.73912 2.16207 3.13044 2 3.53846 2ZM3.53846 3.53846H20.4615V20.4615H3.53846V3.53846Z"
      fill={color}
    />
  </>
);
