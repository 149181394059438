/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Doc = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.95261 2.46863C4.25267 2.16857 4.65964 2 5.08398 2H14.684C14.8962 2 15.0996 2.08429 15.2497 2.23431L20.8497 7.83431C20.9997 7.98434 21.084 8.18783 21.084 8.4V12.4C21.084 12.8418 20.7258 13.2 20.284 13.2C19.8422 13.2 19.484 12.8418 19.484 12.4V9.23301H14.8866C14.3343 9.23301 13.8866 8.78529 13.8866 8.23301V3.6L5.08398 3.6L5.08398 12.4C5.08398 12.8418 4.72581 13.2 4.28398 13.2C3.84216 13.2 3.48398 12.8418 3.48398 12.4V3.6C3.48398 3.17565 3.65256 2.76869 3.95261 2.46863ZM15.4866 4.73401L18.3856 7.63301H15.4866V4.73401Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.08398 16.4C3.08398 15.9582 3.44216 15.6 3.88398 15.6H5.28398C6.13268 15.6 6.94661 15.9371 7.54673 16.5373C8.14684 17.1374 8.48398 17.9513 8.48398 18.8C8.48398 19.6487 8.14684 20.4626 7.54673 21.0627C6.94661 21.6629 6.13268 22 5.28398 22H3.88398C3.44216 22 3.08398 21.6418 3.08398 21.2V16.4ZM4.68398 17.2V20.4H5.28398C5.70833 20.4 6.1153 20.2314 6.41536 19.9314C6.71541 19.6313 6.88398 19.2243 6.88398 18.8C6.88398 18.3757 6.71541 17.9687 6.41536 17.6686C6.1153 17.3686 5.70833 17.2 5.28398 17.2H4.68398Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.48398 18.8C9.48398 17.0996 10.763 15.6 12.484 15.6C14.2049 15.6 15.484 17.0996 15.484 18.8C15.484 20.5004 14.2049 22 12.484 22C10.763 22 9.48398 20.5004 9.48398 18.8ZM12.484 17.2C11.7749 17.2 11.084 17.8494 11.084 18.8C11.084 19.7506 11.7749 20.4 12.484 20.4C13.1931 20.4 13.884 19.7506 13.884 18.8C13.884 17.8494 13.1931 17.2 12.484 17.2Z"
      fill={color}
    />
    <path
      d="M19.2808 17.2C18.5681 17.2019 17.884 17.8461 17.884 18.8C17.884 19.7539 18.5681 20.3981 19.2808 20.4C19.6006 20.3966 19.9081 20.2754 20.1442 20.0595C20.4703 19.7614 20.9763 19.7841 21.2744 20.1102C21.5725 20.4363 21.5499 20.9423 21.2238 21.2405C20.6952 21.7237 20.0066 21.9942 19.2904 22L19.284 22C17.5579 22 16.284 20.5047 16.284 18.8C16.284 17.0953 17.5579 15.6 19.284 15.6L19.2904 15.6C20.0066 15.6058 20.6952 15.8763 21.2238 16.3596C21.5499 16.6577 21.5725 17.1637 21.2744 17.4898C20.9763 17.8159 20.4703 17.8386 20.1442 17.5405C19.9081 17.3246 19.6006 17.2034 19.2808 17.2Z"
      fill={color}
    />
  </>
);
