/* eslint-disable max-len */
import React from 'react';

import { IconProps } from '../types';

export const BatchInvitations = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M17.9022 10.8245C18.3271 10.8245 18.6716 10.48 18.6716 10.0551V5.41269C18.6716 4.59644 18.3473 3.81362 17.7702 3.23644C17.193 2.65926 16.4102 2.33501 15.5939 2.33501H4.82201C4.00575 2.33501 3.22293 2.65926 2.64575 3.23644C2.06857 3.81362 1.74432 4.59644 1.74432 5.4127L1.74432 10.2143C0.894439 10.2143 0.205475 10.9032 0.205475 11.7531V20.1261C0.205475 20.5343 0.367603 20.9257 0.656192 21.2143C0.944782 21.5029 1.33619 21.665 1.74432 21.665H9.02388C9.44882 21.665 9.7933 21.3205 9.7933 20.8956C9.7933 20.4706 9.44882 20.1261 9.02388 20.1261H1.74432V11.6173L8.08243 16.1376C8.21293 16.2309 8.36929 16.281 8.52966 16.281H9.02292C9.44839 16.281 9.7933 15.936 9.7933 15.5106C9.7933 15.0851 9.44839 14.7402 9.02292 14.7402H8.77588L3.28316 10.8231L3.28316 5.4127C3.28316 5.00457 3.44529 4.61316 3.73388 4.32457C4.02247 4.03598 4.41388 3.87385 4.82201 3.87385L15.5939 3.87385C16.002 3.87385 16.3935 4.03598 16.682 4.32457C16.9706 4.61316 17.1328 5.00457 17.1328 5.41269L17.1328 10.0551C17.1328 10.48 17.4772 10.8245 17.9022 10.8245Z"
      fill={color}
    />
    <path
      d="M13.2857 8.43732L7.13027 8.43732C6.92621 8.43732 6.7305 8.35625 6.58621 8.21196C6.44191 8.06766 6.36085 7.87196 6.36085 7.66789C6.36085 7.46383 6.44191 7.26813 6.58621 7.12383C6.7305 6.97954 6.92621 6.89847 7.13027 6.89847L13.2857 6.89847C13.4897 6.89847 13.6854 6.97954 13.8297 7.12383C13.974 7.26812 14.0551 7.46383 14.0551 7.66789C14.0551 7.87196 13.974 8.06766 13.8297 8.21196C13.6854 8.35625 13.4897 8.43732 13.2857 8.43732Z"
      fill={color}
    />
    <path
      d="M6.36085 10.7456C6.36085 10.5415 6.44191 10.3458 6.58621 10.2015C6.7305 10.0572 6.92621 9.97616 7.13027 9.97616L13.2857 9.97616C13.4897 9.97616 13.6854 10.0572 13.8297 10.2015C13.974 10.3458 14.0551 10.5415 14.0551 10.7456C14.0551 10.9496 13.974 11.1454 13.8297 11.2896C13.6854 11.4339 13.4897 11.515 13.2857 11.515L7.13027 11.515C6.92621 11.515 6.7305 11.4339 6.58621 11.2896C6.44191 11.1454 6.36085 10.9496 6.36085 10.7456Z"
      fill={color}
    />
    <path
      d="M14.8704 13.6882C14.6548 13.6329 14.428 13.6401 14.2163 13.709C14.0046 13.7779 13.817 13.9055 13.6752 14.0771C13.5334 14.2487 13.4434 14.4571 13.4156 14.6779C13.3879 14.8988 13.4236 15.123 13.5185 15.3243C13.6134 15.5256 13.7637 15.6957 13.9517 15.8148C14.1398 15.9339 14.3578 15.9971 14.5804 15.9968C14.5907 15.9968 14.6008 15.9972 14.6109 15.998C14.6174 15.9985 14.6238 15.9991 14.6302 15.9999C14.823 16.0242 14.9721 16.189 14.9717 16.3884C14.9714 16.6043 14.7961 16.779 14.5802 16.7786C14.1858 16.778 13.7967 16.8695 13.444 17.0459C13.0913 17.2222 12.7846 17.4786 12.5485 17.7945C12.4193 17.9674 12.1743 18.0028 12.0014 17.8736C11.8285 17.7443 11.7931 17.4994 11.9223 17.3264C12.2313 16.913 12.6327 16.5774 13.0944 16.3466C13.1497 16.3189 13.2057 16.2929 13.2623 16.2684C13.0743 16.097 12.9208 15.8898 12.8114 15.6577C12.6531 15.3222 12.5937 14.9485 12.6399 14.5805C12.6862 14.2124 12.8362 13.8651 13.0725 13.5791C13.3088 13.2932 13.6216 13.0803 13.9744 12.9655C14.3271 12.8507 14.7053 12.8387 15.0646 12.9308C15.424 13.023 15.7497 13.2155 16.0037 13.4858C16.2577 13.7562 16.4295 14.0933 16.499 14.4577C16.5395 14.6697 16.4004 14.8744 16.1884 14.9149C15.9763 14.9554 15.7716 14.8163 15.7311 14.6042C15.6894 14.3856 15.5863 14.1834 15.4339 14.0211C15.2815 13.8589 15.086 13.7434 14.8704 13.6882Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9035 15.215C16.6082 15.215 15.5581 16.2651 15.5581 17.5604C15.5581 18.3209 15.9199 18.9968 16.4809 19.4253C16.3282 19.4927 16.1799 19.5711 16.0373 19.6602C15.4776 20.0098 15.0273 20.5095 14.7377 21.1025C14.643 21.2965 14.7235 21.5306 14.9175 21.6253C15.1115 21.72 15.3455 21.6395 15.4403 21.4455C15.6656 20.9841 16.0159 20.5953 16.4514 20.3233C16.8869 20.0513 17.39 19.9071 17.9035 19.9071C18.417 19.9071 18.9201 20.0513 19.3556 20.3233C19.7911 20.5953 20.1414 20.9841 20.3667 21.4455C20.4615 21.6395 20.6955 21.72 20.8895 21.6253C21.0835 21.5306 21.164 21.2965 21.0693 21.1025C20.7797 20.5095 20.3294 20.0098 19.7697 19.6602C19.6271 19.5711 19.4788 19.4927 19.3261 19.4253C19.887 18.9967 20.2489 18.3209 20.2489 17.5604C20.2489 16.2651 19.1988 15.215 17.9035 15.215ZM16.3399 17.5604C16.3399 16.6969 17.0399 15.9968 17.9035 15.9968C18.767 15.9968 19.4671 16.6969 19.4671 17.5604C19.4671 18.424 18.767 19.1241 17.9035 19.1241C17.0399 19.1241 16.3399 18.424 16.3399 17.5604Z"
      fill={color}
    />
    <path
      d="M22.363 17.0459C22.0103 16.8695 21.6212 16.778 21.2268 16.7786L21.2257 16.7786C21.1991 16.7786 21.1731 16.7759 21.148 16.7708C20.9694 16.7346 20.835 16.5766 20.8352 16.3873C20.8354 16.1714 21.0106 15.9966 21.2265 15.9968C21.4491 15.9971 21.6671 15.9339 21.8552 15.8148C22.0432 15.6957 22.1935 15.5256 22.2884 15.3243C22.3833 15.123 22.419 14.8988 22.3913 14.6779C22.3635 14.4571 22.2735 14.2487 22.1317 14.0771C21.9899 13.9055 21.8023 13.7779 21.5906 13.709C21.379 13.6401 21.1521 13.6329 20.9365 13.6882C20.7209 13.7434 20.5254 13.8589 20.373 14.0211C20.2206 14.1834 20.1175 14.3856 20.0758 14.6042C20.0353 14.8163 19.8306 14.9554 19.6185 14.9149C19.4065 14.8744 19.2674 14.6697 19.3079 14.4577C19.3774 14.0933 19.5492 13.7562 19.8032 13.4858C20.0572 13.2155 20.383 13.023 20.7423 12.9308C21.1016 12.8387 21.4798 12.8507 21.8325 12.9655C22.1853 13.0803 22.4981 13.2932 22.7344 13.5791C22.9707 13.8651 23.1207 14.2124 23.167 14.5805C23.2132 14.9485 23.1538 15.3222 22.9956 15.6577C22.8861 15.8898 22.7326 16.097 22.5446 16.2684C22.6013 16.2928 22.6573 16.3189 22.7126 16.3466C23.1743 16.5774 23.5757 16.913 23.8847 17.3264C24.0139 17.4994 23.9785 17.7443 23.8056 17.8736C23.6327 18.0028 23.3877 17.9674 23.2585 17.7945C23.0224 17.4786 22.7157 17.2222 22.363 17.0459Z"
      fill={color}
    />
  </>
);
