/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Text = ({ color = '#191919' }: IconProps) => (
  <path
    d="M2 3.90909C2 3.40701 2.40701 3 2.90909 3H21.0909C21.593 3 22 3.40701 22 3.90909V7.54545C22 8.04753 21.593 8.45455 21.0909 8.45455C20.5888 8.45455 20.1818 8.04753 20.1818 7.54545V4.81818H12.9091V19.3636H15.6364C16.1384 19.3636 16.5455 19.7706 16.5455 20.2727C16.5455 20.7748 16.1384 21.1818 15.6364 21.1818H8.36364C7.86156 21.1818 7.45455 20.7748 7.45455 20.2727C7.45455 19.7706 7.86156 19.3636 8.36364 19.3636H11.0909V4.81818H3.81818V7.54545C3.81818 8.04753 3.41117 8.45455 2.90909 8.45455C2.40701 8.45455 2 8.04753 2 7.54545V3.90909Z"
    fill={color}
  />
);
