/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const TextSize = ({ color = '#191919' }: IconProps) => (
  <path
    d="M8.19698 4.59354C8.13015 4.45137 8.02423 4.33116 7.89161 4.24696C7.75899 4.16276 7.60514 4.11804 7.44805 4.11804C7.29095 4.11804 7.1371 4.16276 7.00448 4.24696C6.87186 4.33116 6.76594 4.45137 6.69911 4.59354L0.0786927 18.6619C0.0324376 18.7603 0.00600716 18.8668 0.000910514 18.9753C-0.00418613 19.0839 0.0121508 19.1924 0.0489885 19.2947C0.123386 19.5012 0.276771 19.6697 0.475401 19.7631C0.67403 19.8565 0.901634 19.8672 1.10814 19.7928C1.31465 19.7184 1.48315 19.565 1.57656 19.3664L3.29994 15.7045H11.5962L13.3195 19.3664C13.3658 19.4647 13.431 19.553 13.5113 19.6262C13.5917 19.6993 13.6857 19.756 13.7879 19.7928C13.8902 19.8296 13.9987 19.846 14.1073 19.8409C14.2158 19.8358 14.3223 19.8093 14.4207 19.7631C14.519 19.7168 14.6073 19.6517 14.6805 19.5713C14.7536 19.4909 14.8103 19.3969 14.8471 19.2947C14.8839 19.1924 14.9003 19.0839 14.8952 18.9753C14.8901 18.8668 14.8637 18.7603 14.8174 18.6619L8.19698 4.59354ZM4.07887 14.0494L7.44805 6.89L10.8172 14.0494H4.07887ZM19.8613 9.08405C18.5414 9.08405 17.51 9.443 16.7963 10.1516C16.6469 10.3074 16.5642 10.5154 16.566 10.7312C16.5677 10.9471 16.6538 11.1537 16.8057 11.307C16.9577 11.4604 17.1636 11.5482 17.3794 11.5519C17.5952 11.5556 17.804 11.4748 17.9611 11.3267C18.3542 10.9367 18.9955 10.7392 19.8613 10.7392C21.2299 10.7392 22.344 11.6701 22.344 12.808V13.1411C21.6096 12.6498 20.7449 12.3897 19.8613 12.3943C17.5794 12.3943 15.7236 14.0649 15.7236 16.1182C15.7236 18.1716 17.5794 19.8422 19.8613 19.8422C20.7453 19.8461 21.61 19.5849 22.344 19.0923C22.3543 19.3117 22.4513 19.5181 22.6138 19.6661C22.7763 19.814 22.9909 19.8913 23.2103 19.881C23.4298 19.8707 23.6362 19.7737 23.7841 19.6112C23.9321 19.4487 24.0094 19.2342 23.9991 19.0147V12.808C23.9991 10.7547 22.1433 9.08405 19.8613 9.08405ZM19.8613 18.1871C18.4928 18.1871 17.3787 17.2561 17.3787 16.1182C17.3787 14.9804 18.4928 14.0494 19.8613 14.0494C21.2299 14.0494 22.344 14.9804 22.344 16.1182C22.344 17.2561 21.2299 18.1871 19.8613 18.1871Z"
    fill={color}
  />
);
