/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Code = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M15.3772 4.89468C15.503 4.54865 15.3245 4.16615 14.9785 4.04032C14.6325 3.91449 14.25 4.093 14.1241 4.43902L8.7908 19.1057C8.66498 19.4517 8.84348 19.8342 9.1895 19.96C9.53552 20.0859 9.91803 19.9074 10.0439 19.5613L15.3772 4.89468Z"
      fill={color}
    />
    <path
      d="M7.26279 8.24005C7.4985 8.5229 7.46029 8.94328 7.17744 9.17899L3.79202 12.0002L7.17744 14.8214C7.46029 15.0571 7.4985 15.4774 7.26279 15.7603C7.02708 16.0431 6.60671 16.0814 6.32386 15.8457L2.32386 12.5123C2.17187 12.3857 2.08398 12.198 2.08398 12.0002C2.08398 11.8023 2.17187 11.6147 2.32386 11.488L6.32386 8.1547C6.60671 7.91899 7.02708 7.9572 7.26279 8.24005Z"
      fill={color}
    />
    <path
      d="M16.9052 8.24005C17.1409 7.9572 17.5613 7.91899 17.8441 8.1547L21.8441 11.488C21.9961 11.6147 22.084 11.8023 22.084 12.0002C22.084 12.198 21.9961 12.3857 21.8441 12.5123L17.8441 15.8457C17.5613 16.0814 17.1409 16.0431 16.9052 15.7603C16.6695 15.4774 16.7077 15.0571 16.9905 14.8214L20.376 12.0002L16.9905 9.17899C16.7077 8.94328 16.6695 8.5229 16.9052 8.24005Z"
      fill={color}
    />
  </>
);
