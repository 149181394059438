/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Chat = ({ color = '#191919' }: IconProps) => (
  <path
    fill={color}
    d="M21.716 6.8h-3.2V3.6a1.6 1.6 0 0 0-1.6-1.6h-12.8a1.6 1.6 0 0 0-1.6 1.6v12.8a.8.8 0 0 0 1.3.622l3.5-2.822v3a1.6 1.6 0 0 0 1.6 1.6h9.359l3.741 3.022a.8.8 0 0 0 1.066-.056.8.8 0 0 0 .234-.566V8.4a1.6 1.6 0 0 0-1.6-1.6ZM6.771 12.578l-2.655 2.147V3.6h12.8v8.8H7.274a.8.8 0 0 0-.503.178Zm14.945 6.947-2.655-2.147a.8.8 0 0 0-.5-.178H8.916V14h8a1.6 1.6 0 0 0 1.6-1.6v-4h3.2v11.125Z"
  />
);
