/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const EvaluationCycle = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M20.8149 2.88342C21.2365 2.92333 21.5508 3.29794 21.5171 3.72053L21.1819 7.933C21.1445 8.40367 20.7158 8.73783 20.2502 8.65926L16.2185 7.97889C15.7953 7.90747 15.5051 7.50639 15.5701 7.08259C15.6353 6.65717 16.0335 6.37057 16.4581 6.44339L18.6438 6.81819C16.4089 3.95474 12.5576 2.7954 9.0857 4.04333C6.07003 5.12727 4.07158 7.76992 3.64882 10.7559C3.60513 11.0645 3.40217 11.3328 3.10564 11.4394C2.57688 11.6294 2.02084 11.2383 2.08981 10.6869C2.54007 7.0865 4.93027 3.88524 8.54816 2.58485C12.6132 1.12373 17.125 2.47508 19.8101 5.80437L19.9888 3.57734C20.0227 3.15421 20.3927 2.84346 20.8149 2.88342Z"
      fill={color}
    />
    <path
      d="M21.0483 13.9368C20.7897 14.0298 20.5979 14.2451 20.5193 14.5056C19.7795 16.9569 17.9429 19.0284 15.3603 19.9567C11.8884 21.2046 8.03715 20.0453 5.80223 17.1818L7.98788 17.5566C8.41253 17.6294 8.81071 17.3428 8.87593 16.9174C8.94089 16.4936 8.65075 16.0925 8.22755 16.0211L4.19585 15.3407C3.73027 15.2622 3.30156 15.5963 3.26411 16.067L2.92889 20.2795C2.89526 20.7021 3.20953 21.0767 3.63117 21.1166C4.05336 21.1565 4.42329 20.8458 4.45724 20.4227L4.63592 18.1956C7.32103 21.5249 11.8328 22.8763 15.8979 21.4152C19.0095 20.2967 21.213 17.7721 22.0552 14.7969C22.2223 14.2065 21.6316 13.7272 21.0483 13.9368Z"
      fill={color}
    />
  </>
);
