export const gridSpacingOrder = ['xs','sm','md','lg','xl','xxl'] as const;
export const ESpacing = {
  '0': '0rem',
  '4px': '0.25rem',
  '8px': '0.5rem',
  '12px': '0.75rem',
  '16px': '1rem',
  '20px': '1.25rem',
  '24px': '1.5rem',
  '28px': '1.75rem',
  '32px': '2rem',
  '36px': '2.25rem',
  '40px': '2.5rem',
  '44px': '2.75rem',
  '48px': '3rem',
  '56px': '3.5rem',
  '64px': '4rem',
  '80px': '5rem',
  '96px': '6rem',
  '112px': '7rem',
  '128px': '8rem',
  '144px': '9rem',
  '160px': '10rem',
  '176px': '11rem',
  '192px': '12rem',
  '208px': '13rem',
  '224px': '14rem',
  '240px': '15rem',
  '256px': '16rem',
  '288px': '18rem',
  '320px': '20rem',
  '384px': '24rem',
};