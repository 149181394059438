/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Rewind = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M12.7268 3.55192C13.0818 3.19689 13.0818 2.62129 12.7268 2.26627C12.3718 1.91124 11.7962 1.91124 11.4412 2.26627L2.35025 11.3572C1.99523 11.7122 1.99523 12.2878 2.35025 12.6428L11.4412 21.7337C11.7962 22.0888 12.3718 22.0888 12.7268 21.7337C13.0818 21.3787 13.0818 20.8031 12.7268 20.4481L4.27872 12L12.7268 3.55192Z"
      fill={color}
    />
    <path
      d="M21.8177 3.55192C22.1727 3.19689 22.1727 2.62129 21.8177 2.26627C21.4627 1.91124 20.8871 1.91124 20.5321 2.26627L11.4412 11.3572C11.0861 11.7122 11.0861 12.2878 11.4412 12.6428L20.5321 21.7337C20.8871 22.0888 21.4627 22.0888 21.8177 21.7337C22.1727 21.3787 22.1727 20.8031 21.8177 20.4481L13.3696 12L21.8177 3.55192Z"
      fill={color}
    />
  </>
);
