/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Html = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66863 2.46863C2.96869 2.16857 3.37565 2 3.8 2H13.4C13.6122 2 13.8157 2.08429 13.9657 2.23431L19.5657 7.83431C19.7157 7.98434 19.8 8.18783 19.8 8.4V12.4C19.8 12.8418 19.4418 13.2 19 13.2C18.5582 13.2 18.2 12.8418 18.2 12.4V9.23301H13.6026C13.0504 9.23301 12.6026 8.78529 12.6026 8.23301V3.6L3.8 3.6L3.8 12.4C3.8 12.8418 3.44183 13.2 3 13.2C2.55817 13.2 2.2 12.8418 2.2 12.4V3.6C2.2 3.17565 2.36857 2.76869 2.66863 2.46863ZM14.2026 4.73401L17.1016 7.63301H14.2026V4.73401Z"
      fill={color}
    />
    <path
      d="M12.3559 16.4381C12.687 16.332 13.0488 16.452 13.251 16.735L14.6 18.6236L15.949 16.735C16.1512 16.452 16.513 16.332 16.8441 16.4381C17.1753 16.5443 17.4 16.8522 17.4 17.2V21.2C17.4 21.6418 17.0418 22 16.6 22C16.1582 22 15.8 21.6418 15.8 21.2V19.6963L15.251 20.4649C15.1008 20.6752 14.8584 20.8 14.6 20.8C14.3416 20.8 14.0992 20.6752 13.949 20.4649L13.4 19.6963V21.2C13.4 21.6418 13.0418 22 12.6 22C12.1582 22 11.8 21.6418 11.8 21.2V17.2C11.8 16.8522 12.0247 16.5443 12.3559 16.4381Z"
      fill={color}
    />
    <path
      d="M1.8 16.4C2.24183 16.4 2.6 16.7582 2.6 17.2V18.4H3.8V17.2C3.8 16.7582 4.15817 16.4 4.6 16.4C5.04183 16.4 5.4 16.7582 5.4 17.2V21.2C5.4 21.6418 5.04183 22 4.6 22C4.15817 22 3.8 21.6418 3.8 21.2V20H2.6V21.2C2.6 21.6418 2.24183 22 1.8 22C1.35817 22 1 21.6418 1 21.2V17.2C1 16.7582 1.35817 16.4 1.8 16.4Z"
      fill={color}
    />
    <path
      d="M6.2 17.2C6.2 16.7582 6.55817 16.4 7 16.4H10.2C10.6418 16.4 11 16.7582 11 17.2C11 17.6418 10.6418 18 10.2 18H9.4V21.2C9.4 21.6418 9.04183 22 8.6 22C8.15817 22 7.8 21.6418 7.8 21.2V18H7C6.55817 18 6.2 17.6418 6.2 17.2Z"
      fill={color}
    />
    <path
      d="M20.2 17.2C20.2 16.7582 19.8418 16.4 19.4 16.4C18.9582 16.4 18.6 16.7582 18.6 17.2V21.2C18.6 21.6418 18.9582 22 19.4 22H21.8C22.2418 22 22.6 21.6418 22.6 21.2C22.6 20.7582 22.2418 20.4 21.8 20.4H20.2V17.2Z"
      fill={color}
    />
  </>
);
