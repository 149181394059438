/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const CheckBoxOn = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M18.6667 4H5.33333C4.97971 4 4.64057 4.14048 4.39052 4.39052C4.14048 4.64057 4 4.97971 4 5.33333V18.6667C4 19.0203 4.14048 19.3594 4.39052 19.6095C4.64057 19.8595 4.97971 20 5.33333 20H18.6667C19.0203 20 19.3594 19.8595 19.6095 19.6095C19.8595 19.3594 20 19.0203 20 18.6667V5.33333C20 4.97971 19.8595 4.64057 19.6095 4.39052C19.3594 4.14048 19.0203 4 18.6667 4ZM16.125 10.4833L11.2417 15.15C11.1154 15.2688 10.9483 15.3344 10.775 15.3333C10.6042 15.334 10.4399 15.2682 10.3167 15.15L7.875 12.8167C7.80728 12.7576 7.7522 12.6854 7.71308 12.6045C7.67396 12.5236 7.6516 12.4356 7.64736 12.3458C7.64311 12.256 7.65706 12.1663 7.68836 12.0821C7.71967 11.9978 7.76769 11.9208 7.82953 11.8556C7.89137 11.7904 7.96576 11.7383 8.04823 11.7026C8.13069 11.6669 8.21953 11.6482 8.30941 11.6477C8.39928 11.6471 8.48833 11.6648 8.57121 11.6996C8.65408 11.7343 8.72907 11.7855 8.79167 11.85L10.775 13.7417L15.2083 9.51667C15.338 9.40357 15.5063 9.34497 15.6781 9.3531C15.85 9.36123 16.012 9.43546 16.1304 9.56029C16.2487 9.68512 16.3143 9.85087 16.3133 10.0229C16.3123 10.1949 16.2448 10.3599 16.125 10.4833Z"
      fill={color}
    />
  </>
);
