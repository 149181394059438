/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Teacher = ({ color = '#191919' }: IconProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.22694 8.85706C5.22694 11.3582 6.56602 13.5465 8.56635 14.7443C7.80483 14.9907 7.06818 15.3173 6.36932 15.7209C4.63187 16.7241 3.18913 18.167 2.18615 19.9046C1.97579 20.269 2.10069 20.735 2.46512 20.9454C2.82955 21.1557 3.2955 21.0308 3.50586 20.6664C3.97509 19.8535 4.55538 19.115 5.22694 18.4707V20.2855C5.22694 20.7063 5.56805 21.0474 5.98884 21.0474C6.40962 21.0474 6.75073 20.7063 6.75073 20.2855V17.2718C6.87566 17.1919 7.00253 17.1148 7.13127 17.0405C7.40617 16.8817 7.68781 16.7367 7.97514 16.6057L10.844 20.6222C11.4518 21.473 12.7162 21.473 13.324 20.6222L16.1929 16.6058C16.4802 16.7368 16.7618 16.8818 17.0367 17.0405C17.1654 17.1148 17.2923 17.1919 17.4173 17.2718V20.2855C17.4173 20.7063 17.7584 21.0474 18.1792 21.0474C18.5999 21.0474 18.9411 20.7063 18.9411 20.2855V18.4707C19.6126 19.115 20.1929 19.8535 20.6621 20.6664C20.8725 21.0308 21.3384 21.1557 21.7028 20.9454C22.0673 20.735 22.1922 20.269 21.9818 19.9046C20.9788 18.167 19.5361 16.7241 17.7987 15.7209C17.0998 15.3173 16.3631 14.9907 15.6016 14.7443C17.602 13.5465 18.9411 11.3582 18.9411 8.85706C18.9411 5.07001 15.871 2 12.084 2C8.29695 2 5.22694 5.07001 5.22694 8.85706ZM12.084 3.52379C9.13852 3.52379 6.75073 5.91157 6.75073 8.85706C6.75073 11.7763 9.09617 14.1477 12.0054 14.1898C12.0316 14.1896 12.0578 14.1895 12.084 14.1895C12.1102 14.1895 12.1364 14.1896 12.1626 14.1898C15.0718 14.1477 17.4173 11.7763 17.4173 8.85706C17.4173 5.91157 15.0295 3.52379 12.084 3.52379ZM14.7055 16.0665C13.8814 15.8403 13.0282 15.7208 12.1669 15.7136C12.1393 15.7139 12.1117 15.7141 12.084 15.7141C12.0563 15.7141 12.0287 15.7139 12.0011 15.7136C11.1398 15.7208 10.2866 15.8403 9.46255 16.0665L12.084 19.7365L14.7055 16.0665Z"
    fill={color}
  />
);
