/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Delete = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M10.0071 9.69231C10.4319 9.69231 10.7763 10.0367 10.7763 10.4615V16.6154C10.7763 17.0402 10.4319 17.3846 10.0071 17.3846C9.58223 17.3846 9.23783 17.0402 9.23783 16.6154V10.4615C9.23783 10.0367 9.58223 9.69231 10.0071 9.69231Z"
      fill={color}
    />
    <path
      d="M15.3917 16.6154V10.4615C15.3917 10.0367 15.0473 9.69231 14.6224 9.69231C14.1976 9.69231 13.8532 10.0367 13.8532 10.4615V16.6154C13.8532 17.0402 14.1976 17.3846 14.6224 17.3846C15.0473 17.3846 15.3917 17.0402 15.3917 16.6154Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0071 2C9.39502 2 8.80805 2.24313 8.37528 2.67591C7.9425 3.10868 7.69937 3.69565 7.69937 4.30769V5.07692H3.85322C3.42838 5.07692 3.08398 5.42132 3.08398 5.84615C3.08398 6.27099 3.42838 6.61538 3.85322 6.61538H4.62245V20.4615C4.62245 20.8696 4.78453 21.2609 5.07305 21.5494C5.36157 21.8379 5.75288 22 6.16091 22H18.4686C18.8766 22 19.2679 21.8379 19.5565 21.5494C19.845 21.2609 20.0071 20.8696 20.0071 20.4615V6.61538H20.7763C21.2011 6.61538 21.5455 6.27099 21.5455 5.84615C21.5455 5.42132 21.2011 5.07692 20.7763 5.07692H16.9301V4.30769C16.9301 3.69565 16.687 3.10868 16.2542 2.67591C15.8215 2.24313 15.2345 2 14.6224 2H10.0071ZM15.3917 5.07692V4.30769C15.3917 4.10368 15.3106 3.90802 15.1664 3.76376C15.0221 3.61951 14.8265 3.53846 14.6224 3.53846H10.0071C9.80305 3.53846 9.60739 3.61951 9.46313 3.76376C9.31887 3.90802 9.23783 4.10368 9.23783 4.30769V5.07692H15.3917ZM6.16091 6.61538V20.4615H18.4686V6.61538H6.16091Z"
      fill={color}
    />
  </>
);
