/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const TransferUser = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7716 14.1413C12.4027 13.957 12.0232 13.7945 11.6325 13.6534C13.2382 12.5795 14.2255 10.7461 14.2255 8.81461C14.2255 5.60369 11.6109 3 8.41065 3C5.21011 3 2.59577 5.61434 2.59577 8.81461C2.59577 10.7567 3.58307 12.5795 5.18853 13.6534C5.01701 13.7152 4.84656 13.7823 4.67797 13.8542C4.31336 14.0092 3.95674 14.1871 3.61556 14.3799C2.15099 15.2263 0.935973 16.4414 0.100482 17.895C-0.105661 18.2642 0.0136566 18.7302 0.38253 18.9476C0.418219 18.9673 0.454973 18.9838 0.492526 18.9976C0.58015 19.0291 0.671236 19.0451 0.762323 19.0451C0.864329 19.0451 0.966068 19.0232 1.06088 18.9827C1.20817 18.9199 1.33814 18.8107 1.42416 18.6653C2.12915 17.4396 3.14895 16.4195 4.37488 15.7036C5.57898 15.0096 6.94607 14.6298 8.33474 14.6191H8.48655C9.69065 14.6191 10.9057 14.9227 12.0014 15.4543C12.24 15.5736 12.533 15.5625 12.7716 15.4325C12.9996 15.3132 13.1405 15.0964 13.173 14.8466C13.2057 14.5429 13.0537 14.2606 12.7934 14.12L12.7716 14.1413ZM12.6957 8.80396C12.6957 11.1365 10.8082 13.0568 8.47563 13.0893H8.3454C6.02376 13.0568 4.12533 11.1365 4.12533 8.80396C4.12533 6.4714 6.0456 4.51864 8.41065 4.51864C10.7754 4.51864 12.6957 6.43891 12.6957 8.80396Z"
      fill={color}
    />
    <path
      d="M23.8266 18.0468L23.6311 17.8513L22.3727 16.6038C22.3154 16.5522 22.2494 16.5127 22.1756 16.4872C22.1096 16.4643 22.0376 16.452 21.9607 16.452C21.7979 16.452 21.6459 16.517 21.5266 16.6257C21.4182 16.7343 21.3532 16.8861 21.3532 17.0486C21.3532 17.2004 21.4072 17.3309 21.4941 17.4391L21.9063 17.8513H16.2542C15.9178 17.8513 15.6467 18.1225 15.6467 18.4591C15.6467 18.7952 15.8962 19.045 16.2217 19.0664H21.9063L21.4941 19.4898C21.4496 19.5452 21.4136 19.6065 21.3891 19.6736C21.366 19.7375 21.3532 19.8062 21.3532 19.8803C21.3532 20.0427 21.4182 20.1946 21.5266 20.3032C21.6352 20.4119 21.787 20.4769 21.9607 20.4769C22.027 20.4769 22.0933 20.4662 22.1559 20.4465C22.2369 20.4209 22.3117 20.3799 22.3727 20.3251L23.6311 19.0775L23.8373 18.8714C23.8807 18.8171 23.9241 18.7521 23.9566 18.6759C23.9784 18.6109 23.9893 18.5348 23.9893 18.4591C23.9893 18.3829 23.9784 18.318 23.9566 18.2418C23.9241 18.1661 23.8916 18.1012 23.8373 18.0468H23.8266Z"
      fill={color}
    />
    <path
      d="M16.0153 15.0314L15.8201 14.8252C15.766 14.7603 15.7226 14.6947 15.7008 14.6298C15.6792 14.5541 15.6683 14.4886 15.6683 14.413C15.6683 14.3645 15.6725 14.3165 15.6813 14.2713C15.6864 14.2452 15.6928 14.2196 15.7008 14.1956C15.7167 14.1583 15.733 14.1237 15.7516 14.0918C15.7708 14.0587 15.7926 14.0284 15.8201 14.0007L16.0153 13.8052L17.2737 12.5577C17.3824 12.4602 17.5342 12.4059 17.686 12.4059C17.792 12.4059 17.8898 12.4298 17.9747 12.4735C18.0288 12.5012 18.0778 12.5369 18.1201 12.5795C18.2285 12.6882 18.2935 12.84 18.2935 13.0025C18.2935 13.1543 18.2395 13.2848 18.1526 13.3929L17.7403 13.8052H23.3925C23.7289 13.8052 24 14.0875 24 14.413C24 14.7384 23.7395 14.9989 23.425 15.0202H17.7403L18.1526 15.4437C18.2056 15.5097 18.2464 15.5838 18.2701 15.6663C18.2853 15.7191 18.2935 15.775 18.2935 15.8341C18.2935 15.9966 18.2285 16.1484 18.1201 16.2571C18.0008 16.3657 17.8487 16.4307 17.686 16.4307C17.5233 16.4307 17.3824 16.3764 17.2737 16.2789L16.0153 15.0314Z"
      fill={color}
    />
  </>
);
