/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Next = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M3.55192 2.26627C3.19689 1.91124 2.62129 1.91124 2.26627 2.26627C1.91124 2.62129 1.91124 3.19689 2.26627 3.55192L10.7144 12L2.26627 20.4481C1.91124 20.8031 1.91124 21.3787 2.26627 21.7337C2.62129 22.0888 3.19689 22.0888 3.55192 21.7337L12.6428 12.6428C12.9978 12.2878 12.9978 11.7122 12.6428 11.3572L3.55192 2.26627Z"
      fill={color}
    />
    <path
      d="M12.6428 2.26627C12.2878 1.91124 11.7122 1.91124 11.3572 2.26627C11.0022 2.62129 11.0022 3.19689 11.3572 3.55192L19.8053 12L11.3572 20.4481C11.0022 20.8031 11.0022 21.3787 11.3572 21.7337C11.7122 22.0888 12.2878 22.0888 12.6428 21.7337L21.7337 12.6428C22.0888 12.2878 22.0888 11.7122 21.7337 11.3572L12.6428 2.26627Z"
      fill={color}
    />
  </>
);
