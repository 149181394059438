/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const HorizontalDots = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M4.70856 12C4.70856 13.277 3.67331 14.3123 2.39627 14.3123C1.11923 14.3123 0.0839844 13.277 0.0839844 12C0.0839844 10.723 1.11923 9.68771 2.39627 9.68771C3.67331 9.68771 4.70856 10.723 4.70856 12Z"
      fill={color}
    />
    <path
      d="M11.9284 14.3123C13.2055 14.3123 14.2407 13.277 14.2407 12C14.2407 10.723 13.2055 9.68771 11.9284 9.68771C10.6514 9.68771 9.61615 10.723 9.61615 12C9.61615 13.277 10.6514 14.3123 11.9284 14.3123Z"
      fill={color}
    />
    <path
      d="M21.7717 14.3123C23.0487 14.3123 24.084 13.277 24.084 12C24.084 10.723 23.0487 9.68771 21.7717 9.68771C20.4947 9.68771 19.4594 10.723 19.4594 12C19.4594 13.277 20.4947 14.3123 21.7717 14.3123Z"
      fill={color}
    />
  </>
);
