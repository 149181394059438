/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ChangeProfile = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2764 8.82992C14.2764 5.61176 11.6644 3 8.44653 3C5.22837 3 2.61633 5.61176 2.61633 8.82992C2.61633 10.77 3.6084 12.6104 5.20632 13.6795C4.96681 13.7655 4.73165 13.8597 4.50139 13.9625C4.20173 14.0964 3.91078 14.2445 3.63044 14.4067C2.16453 15.2554 0.952273 16.4676 0.10364 17.9335C0.0045699 18.1099 -0.0286351 18.3189 0.0266158 18.5176C0.0815946 18.7158 0.202711 18.8812 0.390238 18.9803C0.511354 19.0462 0.643639 19.0794 0.775914 19.0794C0.842049 19.0794 0.908184 19.0794 0.974318 19.0576C1.10469 19.0211 1.22036 18.9564 1.3129 18.8633C1.36135 18.8148 1.40326 18.7582 1.43728 18.694C2.14248 17.4703 3.16748 16.4453 4.40178 15.7289C5.60315 15.0349 6.98061 14.6598 8.36923 14.649H8.51266C9.72492 14.649 10.9372 14.9467 12.0283 15.4867C12.2708 15.6081 12.5571 15.5967 12.7996 15.4644C13.031 15.3435 13.1744 15.1231 13.1965 14.8803C13.2294 14.5717 13.0751 14.2962 12.8108 14.1531C12.7074 14.0997 12.6023 14.0485 12.4959 13.999C12.2275 13.8744 11.9516 13.7628 11.6756 13.6681C13.2847 12.5881 14.2656 10.7585 14.2656 8.81904L14.2764 8.82992ZM12.7335 8.82992C12.7335 11.1554 10.8381 13.084 8.51266 13.1172H8.38039C6.05495 13.084 4.15927 11.1554 4.15927 8.82992C4.15927 6.50449 6.08788 4.53178 8.45741 4.53178C10.8269 4.53178 12.7556 6.46039 12.7556 8.82992H12.7335Z"
      fill={color}
    />
    <path
      d="M22.6451 13.3316C21.4581 12.1777 19.7 11.7525 18.0979 12.3284C16.5175 12.8964 15.4747 14.2947 15.2783 15.8647C15.2523 16.073 15.3449 16.2464 15.4887 16.3491C15.6307 16.4505 15.8233 16.4839 16.0026 16.4195C16.2112 16.3445 16.3473 16.1586 16.3763 15.9537C16.5398 14.7991 17.3119 13.7799 18.4749 13.3619C19.5994 12.9577 20.8278 13.2072 21.7046 13.9512L21.484 13.9133C21.1826 13.8616 20.8985 14.0658 20.8521 14.3684C20.8062 14.6674 21.0102 14.9489 21.3082 14.9992L22.9749 15.2805C23.2998 15.3353 23.6001 15.1012 23.6263 14.7728L23.7648 13.0313C23.7886 12.7322 23.567 12.4676 23.268 12.4393C22.9662 12.4108 22.7011 12.6337 22.6769 12.9357L22.6451 13.3316Z"
      fill={color}
    />
    <path
      d="M23.2656 17.0214C23.4643 16.95 23.6708 16.9947 23.8144 17.1151C23.9606 17.2376 24.0403 17.4391 23.9794 17.6546C23.612 18.9522 22.6502 20.0551 21.2911 20.5436C19.689 21.1194 17.9309 20.6942 16.7438 19.5403L16.7121 19.9363C16.6878 20.2382 16.4228 20.4612 16.121 20.4326C15.822 20.4043 15.6003 20.1398 15.6241 19.8407L15.7627 18.0992C15.7889 17.7707 16.0892 17.5367 16.414 17.5915L18.0808 17.8728C18.3788 17.923 18.5827 18.2046 18.5369 18.5036C18.4905 18.8061 18.2064 19.0103 17.905 18.9586L17.6844 18.9208C18.5612 19.6648 19.7896 19.9142 20.9141 19.5101C21.9103 19.152 22.6193 18.3532 22.9052 17.4057C22.957 17.2341 23.0854 17.0862 23.2656 17.0214Z"
      fill={color}
    />
  </>
);
