/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Notification = ({ color = '#191919' }: IconProps) => (
  <path
    fill={color}
    d="M21.295 16.069c-.548-.948-1.207-2.747-1.207-5.924v-.659C20.095 5.392 16.8 2.03 12.758 2a7.235 7.235 0 0 0-2.844.556c-.904.37-1.718.91-2.414 1.606A7.36 7.36 0 0 0 5.886 6.57a7.322 7.322 0 0 0-.563 2.843v.74c0 3.177-.66 4.977-1.207 5.924-.133.223-.2.482-.2.74 0 .26.067.52.2.741.126.222.318.415.54.54.223.134.482.2.74.2H9.01c0 .993.385 1.918 1.081 2.622A3.697 3.697 0 0 0 12.713 22c.992 0 1.918-.385 2.62-1.081a3.654 3.654 0 0 0 1.082-2.621h3.613c.26 0 .519-.074.74-.2.223-.133.415-.319.541-.54.134-.223.2-.482.2-.741 0-.26-.066-.518-.2-.74l-.014-.008Zm-7.02 3.791c-.83.83-2.31.83-3.14 0a2.232 2.232 0 0 1-.651-1.57h4.443c0 .585-.237 1.155-.652 1.57Zm-8.878-3.05c.644-1.104 1.407-3.162 1.407-6.665v-.74c0-.785.148-1.548.451-2.273a5.942 5.942 0 0 1 1.289-1.926 5.837 5.837 0 0 1 1.932-1.28 5.857 5.857 0 0 1 2.244-.445h.03c3.236.022 5.864 2.718 5.864 6.005v.66c0 3.502.763 5.56 1.407 6.663H5.397Z"
  />
);
