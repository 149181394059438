/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Xls = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.4663 2.4663C3.76486 2.16773 4.1698 2 4.59204 2H14.1443C14.3554 2 14.5579 2.08387 14.7071 2.23315L20.2793 7.80529C20.4286 7.95457 20.5124 8.15704 20.5124 8.36816V12.3483C20.5124 12.7879 20.156 13.1443 19.7164 13.1443C19.2768 13.1443 18.9204 12.7879 18.9204 12.3483V9.19702H14.3459C13.7964 9.19702 13.3509 8.75153 13.3509 8.202V3.59204L4.59204 3.59204L4.59204 12.3483C4.59204 12.7879 4.23565 13.1443 3.79602 13.1443C3.35639 13.1443 3 12.7879 3 12.3483V3.59204C3 3.1698 3.16773 2.76486 3.4663 2.4663ZM14.9429 4.72041L17.8275 7.60498H14.9429V4.72041Z"
      fill={color}
    />
    <path
      d="M17.3284 17.5224C17.3284 17.3382 17.3897 17.2425 17.4608 17.1815C17.5449 17.1094 17.7187 17.0251 18.0238 17.0249C18.3907 17.0261 18.7474 17.1451 19.0415 17.3644C19.3939 17.6273 19.8926 17.5547 20.1555 17.2023C20.4183 16.8499 20.3457 16.3511 19.9933 16.0883C19.4252 15.6645 18.7358 15.4347 18.027 15.4328H18.0249C17.4351 15.4328 16.8629 15.5971 16.4247 15.9727C15.9735 16.3595 15.7363 16.9105 15.7363 17.5224C15.7363 17.8612 15.8301 18.1722 16.0128 18.4386C16.1885 18.6947 16.4191 18.8699 16.6369 18.9936C17.019 19.2105 17.4941 19.3359 17.8497 19.4298L17.92 19.4484C18.3451 19.5614 18.6232 19.6447 18.8047 19.7553C18.9211 19.8262 18.9209 19.8528 18.9204 19.9013L18.9204 19.9104C18.9204 20.0515 18.8928 20.1252 18.8741 20.1608C18.8562 20.1946 18.8293 20.2266 18.7774 20.2599C18.6526 20.3397 18.4089 20.4078 18.026 20.408C17.6591 20.4068 17.3024 20.2877 17.0083 20.0684C16.6559 19.8055 16.1571 19.8781 15.8943 20.2305C15.6314 20.5829 15.704 21.0817 16.0564 21.3445C16.6246 21.7683 17.314 21.9981 18.0228 22L18.0249 22C18.5369 22 19.1385 21.9189 19.6356 21.6008C20.1903 21.2458 20.5124 20.6608 20.5124 19.9104C20.5124 19.169 20.1066 18.6843 19.6331 18.3958C19.2266 18.1481 18.7221 18.0142 18.3531 17.9162L18.3287 17.9098C17.894 17.7943 17.612 17.7164 17.4228 17.6091C17.4178 17.6062 17.4121 17.6032 17.4061 17.6001C17.3726 17.5825 17.3284 17.5593 17.3284 17.5224Z"
      fill={color}
    />
    <path
      d="M10.9602 15.5323C11.3998 15.5323 11.7562 15.8887 11.7562 16.3284V20.3085H13.7463C14.1859 20.3085 14.5423 20.6648 14.5423 21.1045C14.5423 21.5441 14.1859 21.9005 13.7463 21.9005H10.9602C10.5206 21.9005 10.1642 21.5441 10.1642 21.1045V16.3284C10.1642 15.8887 10.5206 15.5323 10.9602 15.5323Z"
      fill={color}
    />
    <path
      d="M4.83089 15.8508C4.56711 15.4991 4.06817 15.4278 3.71646 15.6916C3.36476 15.9554 3.29348 16.4543 3.55726 16.806L4.9901 18.7165L3.55726 20.6269C3.29348 20.9786 3.36476 21.4776 3.71646 21.7413C4.06817 22.0051 4.56711 21.9338 4.83089 21.5821L5.98512 20.0432L7.13935 21.5821C7.40313 21.9338 7.90207 22.0051 8.25378 21.7413C8.60548 21.4776 8.67676 20.9786 8.41298 20.6269L6.98015 18.7165L8.41298 16.806C8.67676 16.4543 8.60548 15.9554 8.25378 15.6916C7.90207 15.4278 7.40313 15.4991 7.13935 15.8508L5.98512 17.3898L4.83089 15.8508Z"
      fill={color}
    />
  </>
);
