/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const File = ({ color = '#191919' }: IconProps) => (
  <path
    d="M20.2365 7.75241L14.8519 2.36779C14.7804 2.29638 14.6956 2.23975 14.6022 2.20115C14.5088 2.16254 14.4087 2.14271 14.3077 2.14279H5.07691C4.66889 2.14279 4.27757 2.30488 3.98906 2.5934C3.70054 2.88191 3.53845 3.27323 3.53845 3.68125V20.6043C3.53845 21.0124 3.70054 21.4037 3.98906 21.6922C4.27757 21.9807 4.66889 22.1428 5.07691 22.1428H18.9231C19.3311 22.1428 19.7224 21.9807 20.0109 21.6922C20.2994 21.4037 20.4615 21.0124 20.4615 20.6043V8.29664C20.4616 8.19559 20.4418 8.09552 20.4032 8.00214C20.3646 7.90876 20.3079 7.8239 20.2365 7.75241ZM15.0769 4.76875L17.8356 7.52741H15.0769V4.76875ZM18.9231 20.6043H5.07691V3.68125H13.5385V8.29664C13.5385 8.50065 13.6195 8.69631 13.7638 8.84057C13.908 8.98483 14.1037 9.06587 14.3077 9.06587H18.9231V20.6043Z"
    fill={color}
    fillRule="evenodd"
  />
);
