/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const PublishGrade = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.49164 3.72241C7.49164 3.32343 7.16821 3 6.76923 3H5.5288C5.14561 3 4.77812 3.15222 4.50716 3.42318C4.2362 3.69413 4.08398 4.06163 4.08398 4.44482V15.2207C4.08398 15.4123 4.16009 15.5961 4.29557 15.7316L9.35243 20.7884C9.48791 20.9239 9.67165 21 9.86325 21H18.5321C18.9153 21 19.2828 20.8478 19.5538 20.5768C19.8247 20.3059 19.977 19.9384 19.977 19.5552V15.624C19.977 15.225 19.6535 14.9016 19.2546 14.9016C18.8556 14.9016 18.5321 15.225 18.5321 15.624V19.5552H10.5857V15.9431C10.5857 15.1452 9.93879 14.4983 9.14084 14.4983H5.5288V4.44482H6.76923C7.16821 4.44482 7.49164 4.12138 7.49164 3.72241ZM6.55044 15.9431L9.14084 18.5335V15.9431H6.55044Z"
      fill={color}
    />
    <path
      d="M13.6127 3.04499C13.1778 3.04499 12.8252 3.39756 12.8252 3.83249C12.8252 4.26741 13.1778 4.61999 13.6127 4.61999H17.6178L11.8746 10.3631C11.5671 10.6707 11.5671 11.1693 11.8746 11.4768C12.1822 11.7844 12.6808 11.7844 12.9883 11.4768L18.7315 5.73368V9.73874C18.7315 10.1737 19.0841 10.5262 19.519 10.5262C19.9539 10.5262 20.3065 10.1737 20.3065 9.73874V3.83249C20.3065 3.39756 19.9539 3.04499 19.519 3.04499H13.6127Z"
      fill={color}
    />
  </>
);
