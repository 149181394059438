/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Invites = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M15.1609 8.43819L9.00706 8.4382C8.80305 8.4382 8.60739 8.35715 8.46313 8.21289C8.31887 8.06863 8.23783 7.87298 8.23783 7.66896C8.23783 7.46495 8.31887 7.26929 8.46313 7.12504C8.60739 6.98078 8.80305 6.89973 9.00706 6.89973L15.1609 6.89973C15.3649 6.89973 15.5606 6.98078 15.7048 7.12503C15.8491 7.26929 15.9301 7.46495 15.9301 7.66896C15.9301 7.87298 15.8491 8.06863 15.7048 8.21289C15.5606 8.35715 15.3649 8.43819 15.1609 8.43819Z"
      fill={color}
    />
    <path
      d="M8.46313 10.202C8.31887 10.3462 8.23783 10.5419 8.23783 10.7459C8.23783 10.9499 8.31887 11.1456 8.46313 11.2898C8.60739 11.4341 8.80305 11.5151 9.00706 11.5151L15.1609 11.5151C15.3649 11.5151 15.5606 11.4341 15.7048 11.2898C15.8491 11.1456 15.9301 10.9499 15.9301 10.7459C15.9301 10.5419 15.8491 10.3462 15.7048 10.202C15.5606 10.0577 15.3649 9.97666 15.1609 9.97666L9.00706 9.97666C8.80305 9.97666 8.60739 10.0577 8.46313 10.202Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5455 10.2147C21.3952 10.2147 22.084 10.9035 22.084 11.7532V20.1241C22.084 20.5321 21.9219 20.9235 21.6334 21.212C21.3449 21.5005 20.9535 21.6626 20.5455 21.6626H3.62245C3.21442 21.6626 2.82311 21.5005 2.53459 21.212C2.24607 20.9235 2.08398 20.5321 2.08398 20.1241V11.7532C2.08398 10.9035 2.77278 10.2147 3.62245 10.2147L3.62245 5.41433C3.62245 4.59828 3.94662 3.81565 4.52366 3.23861C5.10069 2.66158 5.88332 2.3374 6.69937 2.3374H17.4686C18.2847 2.3374 19.0673 2.66158 19.6443 3.23861C20.2213 3.81565 20.5455 4.59827 20.5455 5.41432V10.2147ZM19.0071 10.8248L13.5176 14.7395H10.6523L5.16091 10.8234L5.16091 5.41433C5.16091 5.0063 5.323 4.61499 5.61151 4.32647C5.90003 4.03795 6.29134 3.87586 6.69937 3.87586L17.4686 3.87586C17.8766 3.87586 18.2679 4.03795 18.5565 4.32647C18.845 4.61499 19.0071 5.0063 19.0071 5.41432L19.0071 10.8248ZM3.62245 20.1241V11.6174L9.95898 16.1366C10.0895 16.2298 10.2458 16.2799 10.4061 16.2799H13.7619C13.9222 16.2799 14.0785 16.2298 14.209 16.1366L20.5455 11.6174V20.1241H3.62245Z"
      fill={color}
    />
  </>
);
