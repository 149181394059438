/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const CatLearning = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M5.8686 21.9656H17.351C18.5792 21.9656 19.5646 20.9659 19.5646 19.752V10.4261C21.5783 10.2833 22.7637 8.02682 21.664 6.28447L21.0356 5.28476C20.5358 4.48499 19.6646 3.99942 18.722 3.99942H17.0368V3.3139C17.0368 2.58554 16.4512 2 15.7229 2C13.9948 2 12.5952 3.39959 12.5952 5.12766V9.32644C11.0242 9.49782 9.83887 10.2547 8.9677 11.3116C7.98227 12.4969 7.41101 14.0251 7.06825 15.4818C6.72549 16.9528 6.61124 18.4095 6.56839 19.4949C6.56839 19.9091 6.55411 20.2804 6.56839 20.566H5.85432C4.55469 20.566 3.51214 19.5092 3.51214 18.2238C3.51214 17.624 3.74065 17.0528 4.14053 16.6243L5.28305 15.3961C6.4113 14.1822 6.7969 12.4398 6.26848 10.8688C6.03998 10.1976 5.66866 9.59779 5.1688 9.09793L4.05484 7.98397C3.76921 7.69834 3.32648 7.69834 3.04085 7.98397C2.75522 8.2696 2.75522 8.71233 3.04085 8.99796L4.15481 10.1119C4.49757 10.4547 4.75464 10.8688 4.91173 11.3259C5.26877 12.4113 5.0117 13.5966 4.2405 14.425L3.09797 15.6532C2.44102 16.353 2.08398 17.267 2.08398 18.2238C2.08398 20.309 3.76921 21.9942 5.85432 21.9942L5.8686 21.9656ZM15.6372 3.39959V4.68493C15.6372 5.08482 15.9514 5.39901 16.3513 5.39901H18.7506C19.1933 5.39901 19.6075 5.62752 19.8502 6.01312L20.4786 7.01283C21.007 7.86972 20.3929 8.98368 19.379 8.98368H18.8648C18.4649 8.98368 18.1507 9.29788 18.1507 9.69776V19.7377C18.1507 20.1804 17.7937 20.5232 17.3653 20.5232H16.5798V19.7377C16.5798 17.6811 14.9088 16.0102 12.8523 16.0102H11.5955C11.1956 16.0102 10.8814 16.3244 10.8814 16.7243C10.8814 17.1242 11.1956 17.4384 11.5955 17.4384H12.8523C14.1233 17.4384 15.1516 18.4666 15.1516 19.7377V20.5232H8.02511C8.02511 20.2518 8.02511 19.8948 8.02511 19.4949C8.05368 18.4666 8.16793 17.0956 8.48212 15.7531C8.79632 14.3964 9.31045 13.1111 10.0817 12.1685C10.8386 11.2544 11.8669 10.6546 13.3236 10.6546C13.7235 10.6546 14.0376 10.3404 14.0376 9.94055V5.07053C14.0376 4.1708 14.7374 3.44244 15.6229 3.38531L15.6372 3.39959Z"
      fill={color}
    />
  </>
);
