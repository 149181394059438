/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Underlined = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 20.5C2 20.0398 2.3731 19.6667 2.83333 19.6667H21.1667C21.6269 19.6667 22 20.0398 22 20.5C22 20.9602 21.6269 21.3333 21.1667 21.3333H2.83333C2.3731 21.3333 2 20.9602 2 20.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33333 3C5.79357 3 6.16667 3.3731 6.16667 3.83333V10.5C6.16667 12.0471 6.78125 13.5308 7.87521 14.6248C8.96917 15.7188 10.4529 16.3333 12 16.3333C13.5471 16.3333 15.0308 15.7188 16.1248 14.6248C17.2188 13.5308 17.8333 12.0471 17.8333 10.5V3.83333C17.8333 3.3731 18.2064 3 18.6667 3C19.1269 3 19.5 3.3731 19.5 3.83333V10.5C19.5 12.4891 18.7098 14.3968 17.3033 15.8033C15.8968 17.2098 13.9891 18 12 18C10.0109 18 8.10322 17.2098 6.6967 15.8033C5.29018 14.3968 4.5 12.4891 4.5 10.5V3.83333C4.5 3.3731 4.8731 3 5.33333 3Z"
      fill={color}
    />
  </>
);
