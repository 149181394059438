/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Curriculum = ({ color = '#191919' }: IconProps) => (
  <path
    d="M22.1538 1.84619H1.84615C1.35652 1.84619 0.886947 2.0407 0.540726 2.38692C0.194505 2.73314 0 3.20271 0 3.69235V20.3077C0 20.7974 0.194505 21.2669 0.540726 21.6132C0.886947 21.9594 1.35652 22.1539 1.84615 22.1539H22.1538C22.6435 22.1539 23.1131 21.9594 23.4593 21.6132C23.8055 21.2669 24 20.7974 24 20.3077V3.69235C24 3.20271 23.8055 2.73314 23.4593 2.38692C23.1131 2.0407 22.6435 1.84619 22.1538 1.84619ZM22.1538 20.3077H1.84615V3.69235H22.1538V20.3077ZM18.4615 8.30773C18.4615 8.55254 18.3643 8.78733 18.1912 8.96044C18.0181 9.13355 17.7833 9.23081 17.5385 9.23081H6.46154C6.21672 9.23081 5.98194 9.13355 5.80882 8.96044C5.63571 8.78733 5.53846 8.55254 5.53846 8.30773C5.53846 8.06291 5.63571 7.82813 5.80882 7.65502C5.98194 7.48191 6.21672 7.38465 6.46154 7.38465H17.5385C17.7833 7.38465 18.0181 7.48191 18.1912 7.65502C18.3643 7.82813 18.4615 8.06291 18.4615 8.30773ZM18.4615 12C18.4615 12.2449 18.3643 12.4796 18.1912 12.6528C18.0181 12.8259 17.7833 12.9231 17.5385 12.9231H6.46154C6.21672 12.9231 5.98194 12.8259 5.80882 12.6528C5.63571 12.4796 5.53846 12.2449 5.53846 12C5.53846 11.7552 5.63571 11.5204 5.80882 11.3473C5.98194 11.1742 6.21672 11.077 6.46154 11.077H17.5385C17.7833 11.077 18.0181 11.1742 18.1912 11.3473C18.3643 11.5204 18.4615 11.7552 18.4615 12ZM18.4615 15.6923C18.4615 15.9372 18.3643 16.1719 18.1912 16.3451C18.0181 16.5182 17.7833 16.6154 17.5385 16.6154H6.46154C6.21672 16.6154 5.98194 16.5182 5.80882 16.3451C5.63571 16.1719 5.53846 15.9372 5.53846 15.6923C5.53846 15.4475 5.63571 15.2127 5.80882 15.0396C5.98194 14.8665 6.21672 14.7693 6.46154 14.7693H17.5385C17.7833 14.7693 18.0181 14.8665 18.1912 15.0396C18.3643 15.2127 18.4615 15.4475 18.4615 15.6923Z"
    fill={color}
  />
);
