/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Reschedule = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M10.6524 14.9547C10.6286 14.9591 10.6037 14.9613 10.5772 14.9613L9.23688 14.9662C8.97949 14.9613 8.77067 14.7525 8.77067 14.4951V13.1208C8.78039 12.9217 8.91151 12.7517 9.09118 12.6886C9.13976 12.674 9.1883 12.6643 9.24173 12.6643C9.46998 12.6643 9.65937 12.8245 9.70308 13.0382C9.7128 13.0722 9.7128 13.1013 9.7128 13.1353C9.7128 13.1936 9.69823 13.2519 9.6788 13.3053C9.67394 13.3053 9.67394 13.315 9.67394 13.315L9.66423 13.3344L10.0576 12.9411C10.5044 12.4943 11.0823 12.1981 11.7136 12.0718C12.3692 11.931 13.0297 11.999 13.6464 12.2612C14.2486 12.5137 14.7682 12.9459 15.1324 13.4947C15.4966 14.0386 15.6909 14.6845 15.6909 15.345C15.6909 16.0054 15.4966 16.6367 15.1324 17.1952C14.7682 17.7391 14.2486 18.1616 13.6464 18.4141C13.2385 18.5841 12.8063 18.6667 12.3692 18.6667C12.141 18.6667 11.9321 18.6569 11.7087 18.6132C11.0628 18.4724 10.4704 18.1616 10.009 17.7003C9.80021 17.4914 9.80021 17.1515 10.009 16.9427C10.2033 16.7484 10.5432 16.7484 10.752 16.9572C11.0774 17.268 11.4659 17.4914 11.9176 17.574C12.3643 17.6566 12.8111 17.6177 13.2336 17.4477C13.6561 17.268 14.0058 16.9864 14.2583 16.6076C14.5108 16.2288 14.6371 15.8112 14.6371 15.345C14.6371 14.8787 14.5108 14.4465 14.2583 14.0677C14.0058 13.6987 13.6561 13.4073 13.2336 13.2373C12.816 13.0528 12.3643 13.0139 11.9176 13.0965C11.4805 13.1936 11.1017 13.3879 10.7957 13.6841L10.4461 14.0338H10.4558C10.4629 14.032 10.4701 14.0302 10.477 14.0286C10.4889 14.026 10.5 14.024 10.5092 14.024H10.5772C10.8297 14.024 11.0386 14.2329 11.0386 14.4854C11.0386 14.6796 10.9269 14.8448 10.7569 14.9176C10.7243 14.9354 10.6898 14.9478 10.6524 14.9547Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6154 2.76923C17.6154 2.34439 17.271 2 16.8462 2C16.4213 2 16.0769 2.34439 16.0769 2.76923V3.53847H8.38462V2.76923C8.38462 2.34439 8.04021 2 7.61538 2C7.19055 2 6.84616 2.34439 6.84616 2.76923V3.53847H4.53847C3.68878 3.53847 3 4.22725 3 5.07692V20.4615C3 21.3112 3.68878 22 4.53847 22H19.9231C20.7728 22 21.4615 21.3112 21.4615 20.4615V5.07692C21.4615 4.22725 20.7728 3.53847 19.9231 3.53847H17.6154V2.76923ZM19.9231 5.07692H17.6154V5.84616C17.6154 6.27099 17.271 6.61539 16.8462 6.61539C16.4213 6.61539 16.0769 6.27099 16.0769 5.84616V5.07692H8.38462V5.84616C8.38462 6.27099 8.04021 6.61539 7.61538 6.61539C7.19055 6.61539 6.84616 6.27099 6.84616 5.84616V5.07692H4.53847V8.15384H19.9231V5.07692ZM4.53847 9.69231H19.9231V20.4615H4.53847V9.69231Z"
      fill={color}
    />
  </>
);
