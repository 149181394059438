import styled from 'styled-components';
import { GridStyleProps, TSpacing } from './types';
import { getMediaQuery, getSpacing } from './utils/functions';

const GridStyle = styled.div<GridStyleProps>`
  ${({ $p, theme }) => {
    if (typeof $p === 'object') {
      return getMediaQuery($p, theme.breakpoints, (value) => {
        return `padding: ${value};`;
      });
    }
    const paddingSpacing = getSpacing($p as TSpacing);
    return paddingSpacing ? `padding: ${paddingSpacing};` : '';
  }}
  ${({ $px, theme }) => {
    if (typeof $px === 'object') {
      return getMediaQuery($px, theme.breakpoints, (value) => {
        return `padding-left: ${value};
          padding-right: ${value};`;
      });
    }
    const pxSpacing = getSpacing($px as TSpacing);
    return pxSpacing ? `padding-left: ${pxSpacing};
      padding-right: ${pxSpacing};` : '';
  }}
  ${({ $py, theme }) => {
    if (typeof $py === 'object') {
      return getMediaQuery($py, theme.breakpoints, (value) => {
        return `padding-top: ${value};
          padding-bottom: ${value};`;
      });
    }
    const pySpacing = getSpacing($py as TSpacing);
    return pySpacing ? `padding-top: ${pySpacing};
      padding-bottom: ${pySpacing};` : '';
  }}
  ${({ $pt, theme }) => {
    if (typeof $pt === 'object') {
      return getMediaQuery($pt, theme.breakpoints, (value) => {
        return `padding-top: ${value};`;
      });
    }
    const ptSpacing = getSpacing($pt as TSpacing);
    return ptSpacing ? `padding-top: ${ptSpacing};` : '';
  }}
  ${({ $pb, theme }) => {
    if (typeof $pb === 'object') {
      return getMediaQuery($pb, theme.breakpoints, (value) => {
        return `padding-bottom: ${value};`;
      });
    }
    const pbSpacing = getSpacing($pb as TSpacing);
    return pbSpacing ? `padding-bottom: ${pbSpacing};` : '';
  }}
  ${({ $pl, theme }) => {
    if (typeof $pl === 'object') {
      return getMediaQuery($pl, theme.breakpoints, (value) => {
        return `padding-left: ${value};`;
      });
    }
    const plSpacing = getSpacing($pl as TSpacing);
    return plSpacing ? `padding-left: ${plSpacing};` : '';
  }}
  ${({ $pr, theme }) => {
    if (typeof $pr === 'object') {
      return getMediaQuery($pr, theme.breakpoints, (value) => {
        return `padding-right: ${value};`;
      });
    }
    const prSpacing = getSpacing($pr as TSpacing);
    return prSpacing ? `padding-right: ${prSpacing};` : '';
  }}
  ${({ $m, theme }) => {
    if (typeof $m === 'object') {
      return getMediaQuery($m, theme.breakpoints, (value) => {
        return `margin: ${value};`;
      });
    }
    const mSpacing = getSpacing($m as TSpacing);
    return mSpacing ? `margin: ${mSpacing};` : '';
  }}
  ${({ $mx, theme }) => {
    if (typeof $mx === 'object') {
      return getMediaQuery($mx, theme.breakpoints, (value) => {
        return `margin-left: ${value};
          margin-right: ${value};`;
      });
    }
    const mxSpacing = getSpacing($mx as TSpacing);
    return mxSpacing ? `margin-left: ${mxSpacing};
      margin-right: ${mxSpacing};` : '';
  }}
  ${({ $my, theme }) => {
    if (typeof $my === 'object') {
      return getMediaQuery($my, theme.breakpoints, (value) => {
        return `margin-top: ${value};
          margin-bottom: ${value};`;
      });
    }
    const mySpacing = getSpacing($my as TSpacing);
    return mySpacing ? `margin-top: ${mySpacing};
      margin-bottom: ${mySpacing};` : '';
  }}
  ${({ $mt, theme }) => {
    if (typeof $mt === 'object') {
      return getMediaQuery($mt, theme.breakpoints, (value) => {
        return `margin-top: ${value};`;
      });
    }
    const mtSpacing = getSpacing($mt as TSpacing);
    return mtSpacing ? `margin-top: ${mtSpacing};` : '';
  }}
  ${({ $mb, theme }) => {
    if (typeof $mb === 'object') {
      return getMediaQuery($mb, theme.breakpoints, (value) => {
        return `margin-bottom: ${value};`;
      });
    }
    const mbSpacing = getSpacing($mb as TSpacing);
    return mbSpacing ? `margin-bottom: ${mbSpacing};` : '';
  }}
  ${({ $ml, theme }) => {
    if (typeof $ml === 'object') {
      return getMediaQuery($ml, theme.breakpoints, (value) => {
        return `margin-left: ${value};`;
      });
    }
    const mlSpacing = getSpacing($ml as TSpacing);
    return mlSpacing ? `margin-left: ${mlSpacing};` : '';
  }}
  ${({ $mr, theme }) => {
    if (typeof $mr === 'object') {
      return getMediaQuery($mr, theme.breakpoints, (value) => {
        return `margin-right: ${value};`;
      });
    }
    const mrSpacing = getSpacing($mr as TSpacing);
    return mrSpacing ? `margin-right: ${mrSpacing};` : '';
  }}
  ${({ $justifyContent, theme }) => {
    if (typeof $justifyContent === 'object') {
      return getMediaQuery($justifyContent, theme.breakpoints, (value) => {
        return `justify-content: ${value};`;
      });
    }
    return $justifyContent ? `justify-content: ${$justifyContent};` : '';
  }}
  ${({ $alignContent, theme }) => {
    if (typeof $alignContent === 'object') {
      return getMediaQuery($alignContent, theme.breakpoints, (value) => {
        return `align-content: ${value};`;
      });
    }
    return $alignContent ? `align-content: ${$alignContent};`: '';
  }}
  ${({ $justifyItems, theme }) => {
    if (typeof $justifyItems === 'object') {
      return getMediaQuery($justifyItems, theme.breakpoints, (value) => {
        return `justify-items: ${value};`;
      });
    }
    return $justifyItems ? `justify-items: ${$justifyItems};` : '';
  }}
  ${({ $alignItems, theme }) => {
    if (typeof $alignItems === 'object') {
      return getMediaQuery($alignItems, theme.breakpoints, (value) => {
        return `align-items: ${value};`;
      });
    }
    return $alignItems ? `align-items: ${$alignItems};` : '';
  }}
  ${({ $justifySelf, theme }) => {
    if (typeof $justifySelf === 'object') {
      return getMediaQuery($justifySelf, theme.breakpoints, (value) => {
        return `justify-self: ${value};`;
      });
    }
    return $justifySelf ? `justify-self: ${$justifySelf};` : '';
  }}
  ${({ $alignSelf, theme }) => {
    if (typeof $alignSelf === 'object') {
      return getMediaQuery($alignSelf, theme.breakpoints, (value) => {
        return `align-self: ${value};`;
      });
    }
    return $alignSelf ? `align-self: ${$alignSelf};` : '';
  }}
`;

export const GridContainerStyle = styled(GridStyle)`
  display: ${({ $variant }) => $variant === 'flex' ? 'flex' : 'grid'};
  ${({ $wrap, theme, $variant }) => {
    if ($variant === 'flex') {
      if (typeof $wrap === 'object') {
        return getMediaQuery($wrap, theme.breakpoints, (value) => {
          return `flex-wrap: ${value};`;
        });
      }
      return $wrap && `flex-wrap: ${$wrap};`;
    }
  }}
  ${({ $flow, theme, $variant }) => {
    if ($variant === 'flex') {
      if (typeof $flow === 'object') {
        return getMediaQuery($flow, theme.breakpoints, (value) => {
          return `flex-flow: ${value};`;
        });
      }
      return $flow && `flex-flow: ${$flow};`;
    }
  }}
  ${({ $direction, theme, $variant }) => {
    if ($variant === 'flex') {
      if (typeof $direction === 'object') {
        return getMediaQuery($direction, theme.breakpoints, (value) => {
          return `flex-direction: ${value};`;
        });
      }
      return $direction && `flex-direction: ${$direction};`;
    }
  }}
  ${({ $cols, theme, $variant }) => {
    if ($variant === 'flex') {
      return '';
    }
    if (typeof $cols === 'object') {
      return getMediaQuery($cols, theme.breakpoints, (value) => {
        return `grid-template-columns: repeat(${value}, 1fr);`;
      });
    }
    return `grid-template-columns: repeat(${$cols}, 1fr);`;
  }}
  ${({ $rows, theme, $variant }) => {
    if ($variant === 'flex') {
      return '';
    }
    if (typeof $rows === 'object') {
      return getMediaQuery($rows, theme.breakpoints, (value) => {
        return `grid-template-rows: repeat(${value}, 1fr);`;
      });
    }
    return `grid-template-rows: repeat(${$rows}, 1fr);`;
  }}
  ${({ $columnSpacing, theme }) => {
    if (typeof $columnSpacing === 'object') {
      return getMediaQuery($columnSpacing, theme.breakpoints, (value) => {
        return `column-gap: ${value};`;
      });
    }
    const columnSpacing = getSpacing($columnSpacing as TSpacing);
    return columnSpacing ? `column-gap: ${columnSpacing};` : '';
  }}
  ${({ $rowSpacing, theme }) => {
    if (typeof $rowSpacing === 'object') {
      return getMediaQuery($rowSpacing, theme.breakpoints, (value) => {
        return `row-gap: ${value};`;
      });
    }
    const rowSpacing = getSpacing($rowSpacing as TSpacing);
    return rowSpacing ? `row-gap: ${rowSpacing};` : '';
  }}
  ${({ $gridSpacing, theme, $variant }) => {
    if (typeof $gridSpacing === 'object') {
      return getMediaQuery($gridSpacing, theme.breakpoints, (value) => {
        if ($variant === 'flex') {
          return `gap: ${value};`;
        }
        return `grid-gap: ${value};`;
      });
    }
    const gridSpacing = getSpacing($gridSpacing as TSpacing);
    return gridSpacing ? `grid-gap: ${gridSpacing};` : '';
  }}
`;

export const GridItemStyle = styled(GridStyle)`
  ${({ $rowSpan, theme }) => {
    if (typeof $rowSpan === 'object') {
      return getMediaQuery($rowSpan, theme.breakpoints, (value) => {
        return `grid-row: span ${value};`;
      });
    }
    return `grid-row: span ${$rowSpan};`;
  }}
  ${({ $colSpan, theme }) => {
    if (typeof $colSpan === 'object') {
      return getMediaQuery($colSpan, theme.breakpoints, (value) => {
        return `grid-column: span ${value};`;
      });
    }
    return `grid-column: span ${$colSpan};`;
  }}
`;