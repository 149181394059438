/* eslint-disable max-len */
import React from 'react';

import { IconProps } from '../types';

export const Brackets = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M2.91732 2C2.45708 2 2.08398 2.3731 2.08398 2.83333V21.1667C2.08398 21.6269 2.45708 22 2.91732 22H7.08398C7.54422 22 7.91732 21.6269 7.91732 21.1667C7.91732 20.7064 7.54422 20.3333 7.08398 20.3333H3.75065V3.66667H7.08398C7.54422 3.66667 7.91732 3.29357 7.91732 2.83333C7.91732 2.3731 7.54422 2 7.08398 2H2.91732Z"
      fill={color}
    />
    <path
      d="M17.084 2C16.6237 2 16.2507 2.3731 16.2507 2.83333C16.2507 3.29357 16.6237 3.66667 17.084 3.66667H20.4173V20.3333H17.084C16.6237 20.3333 16.2507 20.7064 16.2507 21.1667C16.2507 21.6269 16.6237 22 17.084 22H21.2507C21.7109 22 22.084 21.6269 22.084 21.1667V2.83333C22.084 2.3731 21.7109 2 21.2507 2H17.084Z"
      fill={color}
    />
  </>
);
