/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ExternalLink = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M14.0833 2.83333C14.0833 2.3731 14.4564 2 14.9167 2H21.1667C21.6269 2 22 2.3731 22 2.83333V9.08333C22 9.54357 21.6269 9.91667 21.1667 9.91667C20.7064 9.91667 20.3333 9.54357 20.3333 9.08333V4.84518L14.2559 10.9226C13.9305 11.248 13.4028 11.248 13.0774 10.9226C12.752 10.5972 12.752 10.0695 13.0774 9.74408L19.1548 3.66667H14.9167C14.4564 3.66667 14.0833 3.29357 14.0833 2.83333Z"
      fill={color}
    />
    <path
      d="M3.66667 5.33333C3.22464 5.33333 2.80072 5.50893 2.48816 5.82149C2.1756 6.13405 2 6.55797 2 7V20.3333C2 20.7754 2.17559 21.1993 2.48816 21.5118C2.80072 21.8244 3.22464 22 3.66667 22H17C17.442 22 17.8659 21.8244 18.1785 21.5118C18.4911 21.1993 18.6667 20.7754 18.6667 20.3333V13.6667C18.6667 13.2064 18.2936 12.8333 17.8333 12.8333C17.3731 12.8333 17 13.2064 17 13.6667V20.3333H3.66667L3.66667 7H10.3333C10.7936 7 11.1667 6.6269 11.1667 6.16667C11.1667 5.70643 10.7936 5.33333 10.3333 5.33333H3.66667Z"
      fill={color}
    />
  </>
);
