import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { User } from '../interfaces/user.model';
import { AuthService } from '../auth/AuthService';

interface AuthContext {
  isAuthenticated: boolean;
  loading: boolean;
  user: User;
  logout: () => void;
}

const Context = React.createContext<AuthContext>({} as AuthContext);
let loaddingUserInfo = false;
export const UseAuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
 
  const [user, setUser] = useState<User>(User.createNullUser());

  const authService = new AuthService({
    clientId: `${process.env.REACT_APP_SSO_CLIENT_ID}`,
    provider: `${process.env.REACT_APP_SSO_BASE_URL}/connect`,
    redirectUri: `${process.env.REACT_APP_SSO_REDIRECT_URI}`,
    scopes: ['openid', 'profile', 'ctn'],
    durableLogin: process.env.REACT_APP_SSO_DURABLE_LOGIN === 'true',
    clientSecret: process.env.REACT_APP_SSO_CLIENT_SECREAT,
  });

  useEffect(() => {
    const logout = async () => authService.logout();

    const login = async () => {
      logout();
      await authService.authorize();
    };

    async function loadUserInfo(): Promise<void> {
      if (loaddingUserInfo) return;
      loaddingUserInfo = true;
      await authService.loadDataFromCodeIfNeeded();

      if (!authService.isAuthenticated()) {
        await login();
        setIsAuthenticated(false);
        return;
      }

      const idToken = authService.getUser();

      if (idToken) {
        setUser(User.createFromIDToken(idToken));
      }

      setIsAuthenticated(true);
    }

    loadUserInfo();
  }, []);

  const logout = async () => {
    setIsAuthenticated(false);

    await authService.logout(true);
  };

  const value = useMemo(
    () => ({
      isAuthenticated,
      loading: loaddingUserInfo,
      user,
      logout,
    }),
    [isAuthenticated, user],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useAuth = () => useContext(Context);
