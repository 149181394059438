/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Italic = ({ color = '#191919' }: IconProps) => (
  <path
    d="M13.6123 4.00019L8.27913 19.9998H3.99997C3.4477 19.9998 3 20.4475 3 20.9997C3 21.552 3.4477 21.9997 3.99997 21.9997H8.9757C8.99194 22.0001 9.00815 22.0001 9.02431 21.9997H13.9997C14.552 21.9997 14.9997 21.552 14.9997 20.9997C14.9997 20.4475 14.552 19.9998 13.9997 19.9998H10.3873L15.7205 4.00019H19.9995C20.5518 4.00019 20.9995 3.55249 20.9995 3.00022C20.9995 2.44795 20.5518 2.00024 19.9995 2.00024H15.0216C15.0069 1.99992 14.9922 1.99992 14.9776 2.00024H9.99981C9.44754 2.00024 8.99984 2.44795 8.99984 3.00022C8.99984 3.55249 9.44754 4.00019 9.99981 4.00019H13.6123Z"
    fill={color}
  />
);
