/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Show = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.08398 11.6667C8.08398 9.46 9.87732 7.66667 12.084 7.66667C14.2906 7.66667 16.084 9.46 16.084 11.6667C16.084 13.8733 14.2906 15.6667 12.084 15.6667C9.87732 15.6667 8.08398 13.8733 8.08398 11.6667ZM9.41732 11.6667C9.41732 13.14 10.6107 14.3333 12.084 14.3333C13.5573 14.3333 14.7507 13.14 14.7507 11.6667C14.7507 10.1933 13.5573 9 12.084 9C10.6107 9 9.41732 10.1933 9.41732 11.6667Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.084 5C19.104 5 21.9106 11.1333 22.024 11.3934C22.104 11.5667 22.104 11.76 22.024 11.9333C21.9106 12.1933 19.104 18.3267 12.084 18.3267C5.06398 18.3267 2.25732 12.1933 2.14398 11.9333C2.06399 11.76 2.06399 11.5667 2.14398 11.3934C2.25732 11.1333 5.06398 5 12.084 5ZM3.49064 11.6667C4.09066 12.8 6.65731 17 12.084 17C17.5107 17 20.084 12.8 20.6773 11.6667C20.0773 10.5333 17.5107 6.33333 12.084 6.33333C6.65731 6.33333 4.08398 10.5333 3.49064 11.6667Z"
      fill={color}
    />
  </>
);
