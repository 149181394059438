/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const EditProfile = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8973 8.6408C13.8973 5.52704 11.37 3 8.25651 3C5.14274 3 2.61544 5.52704 2.61544 8.6408C2.61544 10.5179 3.57533 12.2986 5.12141 13.333C4.88967 13.4163 4.66214 13.5074 4.43935 13.6069C4.14941 13.7365 3.8679 13.8797 3.59666 14.0367C2.17829 14.8578 1.00537 16.0307 0.184263 17.4491C0.088406 17.6197 0.0562782 17.822 0.109737 18.0142C0.162932 18.2059 0.28012 18.366 0.461563 18.4619C0.57875 18.5256 0.706735 18.5578 0.834719 18.5578C0.898712 18.5578 0.962704 18.5578 1.0267 18.5367C1.15284 18.5014 1.26476 18.4387 1.35429 18.3487C1.40117 18.3018 1.44172 18.247 1.47464 18.1849C2.15696 17.0009 3.14871 16.0091 4.34297 15.316C5.50537 14.6445 6.83814 14.2816 8.18172 14.2711H8.3205C9.49343 14.2711 10.6664 14.5592 11.7221 15.0816C11.9567 15.1991 12.2338 15.188 12.4684 15.06C12.6923 14.9431 12.831 14.7298 12.8524 14.4949C12.8842 14.1963 12.7349 13.9298 12.4792 13.7913C12.3791 13.7396 12.2775 13.6901 12.1745 13.6422C11.9149 13.5216 11.6478 13.4136 11.3808 13.322C12.9377 12.277 13.8868 10.5068 13.8868 8.63027L13.8973 8.6408ZM12.4044 8.6408C12.4044 10.8908 10.5705 12.7568 8.3205 12.789H8.19251C5.94251 12.7568 4.10833 10.8908 4.10833 8.6408C4.10833 6.3908 5.97438 4.48209 8.26704 4.48209C10.5597 4.48209 12.4257 6.34814 12.4257 8.6408H12.4044Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5285 11.436C21.6467 11.4855 21.7526 11.5584 21.8424 11.6481L23.7954 13.5998C23.8892 13.6893 23.963 13.7982 24.012 13.9207L24.0781 14.0859L24.0645 14.0995C24.0779 14.1643 24.084 14.2297 24.084 14.2945C24.084 14.43 24.0538 14.5553 24.0085 14.6684L24.0071 14.672C23.9576 14.7906 23.8844 14.8969 23.7943 14.987L18.5318 20.2457C18.4376 20.3398 18.3299 20.4076 18.2167 20.4548C18.0996 20.5035 17.9766 20.5281 17.8497 20.5281H15.8872C15.6282 20.5281 15.3845 20.425 15.2016 20.2422C15.0187 20.0594 14.9154 19.8158 14.9154 19.5568V17.5957C14.9154 17.4709 14.9393 17.3449 14.9881 17.2271C15.0371 17.1055 15.1066 17.0017 15.1981 16.9103L20.4641 11.6481C20.5536 11.5586 20.6593 11.4858 20.7771 11.4364C21.0143 11.3335 21.2904 11.3345 21.5285 11.436ZM20.629 13.2456L22.2064 14.8219L22.731 14.2977L21.1564 12.7186L20.629 13.2456ZM19.7491 14.1181L21.3264 15.6943L17.731 19.2871H16.1569V17.7109L19.7491 14.1181Z"
      fill={color}
    />
  </>
);
