/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Mistake = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M11.9999 4.66667C9.02918 4.66667 7.32285 6.34496 7.32285 8V8.00973C7.3206 8.20302 7.3494 8.39542 7.40827 8.57955C7.54843 9.01793 7.30667 9.48693 6.8683 9.62708C6.42992 9.76724 5.96093 9.52549 5.82077 9.08711C5.70795 8.73426 5.65239 8.36563 5.65619 7.99523C5.65929 5.04866 8.53506 3 11.9999 3C14.6986 3 16.9786 4.20669 17.9065 6.12701C18.1068 6.5414 17.9332 7.03966 17.5188 7.23991C17.1044 7.44015 16.6061 7.26655 16.4059 6.85216C15.8338 5.66831 14.2596 4.66667 11.9999 4.66667Z"
      fill={color}
    />
    <path
      d="M2 12.1667C2 11.7064 2.3731 11.3333 2.83333 11.3333H13.0646C13.0768 11.3331 13.089 11.3331 13.1013 11.3333H21.1667C21.6269 11.3333 22 11.7064 22 12.1667C22 12.6269 21.6269 13 21.1667 13H16.9929C17.9862 13.7641 18.6667 14.8349 18.6667 16.3333C18.6667 17.8236 17.819 19.0936 16.6079 19.9582C15.3952 20.824 13.7631 21.3333 12 21.3333C10.2369 21.3333 8.60478 20.824 7.3921 19.9582C6.18104 19.0936 5.33333 17.8236 5.33333 16.3333C5.33333 15.8731 5.70643 15.5 6.16667 15.5C6.6269 15.5 7 15.8731 7 16.3333C7 17.1451 7.45959 17.9585 8.36051 18.6017C9.2598 19.2438 10.5443 19.6667 12 19.6667C13.4557 19.6667 14.7402 19.2438 15.6395 18.6017C16.5404 17.9585 17 17.1451 17 16.3333C17 15.4048 16.6036 14.7787 15.9036 14.2661C15.1795 13.736 14.1593 13.3537 12.9635 13H2.83333C2.3731 13 2 12.6269 2 12.1667Z"
      fill={color}
    />
  </>
);
