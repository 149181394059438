export enum SIZE_ENUM {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
}

export type SizeOptions = 'sm' | 'md' | 'lg';

export enum TYPE_FILE_ENUM {
  PDF = 'pdf',
  MP3 = 'mp3',
}

export enum REGIONAL_ENUM {
  MG = 1000,
  ES = 2000,
  SP_INTERIOR = 3000,
  SP_VALE_DO_PARAIBA = 4000,
  RJ = 5000,
  SUL = 6000,
  NORDESTE_II = 7000,
  SP_CAPITAL = 8000,
  NORTE = 9000,
  CENTRO_OESTE = 9002,
  NORDESTE_I = 9003,
  NACIONAL = 9004
}

export const REGIONAL_NAMES = {
  [REGIONAL_ENUM.MG]: "MG",
  [REGIONAL_ENUM.ES]: "ES",
  [REGIONAL_ENUM.SP_INTERIOR]: "SP INTERIOR",
  [REGIONAL_ENUM.SP_VALE_DO_PARAIBA]: "SP VALE DO PARAÍBA",
  [REGIONAL_ENUM.RJ]: "RJ",
  [REGIONAL_ENUM.SUL]: "SUL",
  [REGIONAL_ENUM.NORDESTE_II]: "NORDESTE II",
  [REGIONAL_ENUM.SP_CAPITAL]: "SP CAPITAL",
  [REGIONAL_ENUM.NORTE]: "NORTE",
  [REGIONAL_ENUM.CENTRO_OESTE]: "CENTRO OESTE",
  [REGIONAL_ENUM.NORDESTE_I]: "NORDESTE I",
  [REGIONAL_ENUM.NACIONAL]: "NACIONAL"
}