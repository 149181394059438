import { GridItemStyle } from './styles';
import { GridItemProps } from './types';

export const GridItem = ({
  children,
  colSpan=12,
  rowSpan=1,
  justifySelf,
  alignSelf,
  p,px,py,pt,pb,pl,pr,
  m,mx,my,mt,mb,ml,mr,
}:GridItemProps) => {
  return <GridItemStyle
    $justifySelf={justifySelf}
    $alignSelf={alignSelf}
    $colSpan={colSpan}
    $rowSpan={rowSpan}
    $p={p} $px={px} $py={py} $pt={pt} $pb={pb} $pl={pl} $pr={pr}
    $m={m} $mx={mx} $my={my} $mt={mt} $mb={mb} $ml={ml} $mr={mr}
  >
    {children}
  </GridItemStyle>;
};