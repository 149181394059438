/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Correction = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.08361 2C9.52692 2 9.88629 2.35937 9.88629 2.80268C9.88629 3.24598 9.52692 3.60535 9.08361 3.60535H3.60535V14.7759H7.61873C8.50534 14.7759 9.22408 15.4947 9.22408 16.3813V20.3946H18.0535V14.9766C18.0535 14.5333 18.4129 14.1739 18.8562 14.1739C19.2995 14.1739 19.6589 14.5333 19.6589 14.9766V20.3946C19.6589 20.8204 19.4897 21.2287 19.1887 21.5298C18.8876 21.8309 18.4793 22 18.0535 22H8.4214C8.20852 22 8.00436 21.9154 7.85383 21.7649L2.2351 16.1462C2.08457 15.9956 2 15.7915 2 15.5786V3.60535C2 3.17959 2.16913 2.77126 2.4702 2.4702C2.77126 2.16913 3.17959 2 3.60535 2H9.08361ZM7.61873 19.2595L4.74051 16.3813H7.61873V19.2595Z"
      fill={color}
    />
    <path
      d="M21.0235 3.96942C21.3559 4.30179 21.3559 4.84065 21.0235 5.17301L14.215 11.9815C13.8827 12.3139 13.3438 12.3139 13.0114 11.9815L9.60718 8.57727C9.27482 8.24491 9.27482 7.70604 9.60718 7.37368C9.93954 7.04132 10.4784 7.04132 10.8108 7.37368L13.6132 10.1761L19.8199 3.96942C20.1523 3.63706 20.6912 3.63706 21.0235 3.96942Z"
      fill={color}
    />
  </>
);
