/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const AbsentUser = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      d="M14.0737 8.7507C14.0737 5.57865 11.495 3 8.32297 3C5.15091 3 2.57227 5.57865 2.57227 8.7507C2.57227 10.6712 3.54331 12.473 5.12933 13.5412C4.58987 13.7354 4.07198 13.9727 3.57567 14.264C2.1299 15.0948 0.93229 16.3032 0.101513 17.7382C0.00440926 17.9108 -0.0279587 18.1158 0.0259879 18.31C0.0799345 18.5042 0.209406 18.6661 0.382035 18.774C0.500718 18.8387 0.63019 18.8711 0.759661 18.8711C0.824397 18.8711 0.889133 18.8711 0.953869 18.8495C1.14808 18.7956 1.30992 18.6661 1.41781 18.4935C2.11912 17.2851 3.12252 16.2709 4.34171 15.5696C5.52854 14.879 6.88799 14.5122 8.25824 14.5014H8.3985C9.59611 14.5014 10.7937 14.7927 11.8727 15.3322C12.11 15.4509 12.4013 15.4401 12.6387 15.3106C12.8653 15.1919 13.0055 14.9761 13.0271 14.728C13.0595 14.4367 12.9084 14.1454 12.6495 14.0159C12.2827 13.8325 11.9158 13.6706 11.5274 13.5304C13.1134 12.473 14.0845 10.6604 14.0845 8.73992L14.0737 8.7507ZM12.5524 8.7507C12.5524 11.0488 10.6858 12.9477 8.38771 12.9801H8.25824C5.96011 12.9477 4.09356 11.0488 4.09356 8.7507C4.09356 6.45258 5.99248 4.52129 8.32297 4.52129C10.6535 4.52129 12.5524 6.42021 12.5524 8.7507Z"
      fill={color}
    />
    <path
      d="M19.5978 13.0555C19.3281 13.0555 19.1123 13.2712 19.1123 13.541V15.742C19.1123 16.0117 19.3281 16.2275 19.5978 16.2275H21.7989C22.0686 16.2275 22.2844 16.0117 22.2844 15.742C22.2844 15.4723 22.0686 15.2565 21.7989 15.2565H20.0834V13.541C20.0834 13.2712 19.8676 13.0555 19.5978 13.0555Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      d="M19.598 11.3512C17.1704 11.3512 15.1959 13.3256 15.1959 15.7532C15.1959 18.1808 17.1704 20.1553 19.598 20.1553C22.0256 20.1553 24 18.1808 24 15.7532C24 13.3256 22.0256 11.3512 19.598 11.3512ZM22.7592 15.7532C22.7592 17.4903 21.3458 18.9145 19.598 18.9145C17.8501 18.9145 16.4367 17.5011 16.4367 15.7532C16.4367 14.0054 17.8501 12.592 19.598 12.592C21.3458 12.592 22.7592 14.0054 22.7592 15.7532Z"
      fill={color}
    />
  </>
);
