/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const TextMarker = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M3.48398 10.2001C3.48398 9.75823 3.84216 9.40006 4.28398 9.40006H7.08398C7.52581 9.40006 7.88398 9.75823 7.88398 10.2001C7.88398 10.6419 7.52581 11.0001 7.08398 11.0001H6.48398V13.8001C6.48398 14.2419 6.12581 14.6001 5.68398 14.6001C5.24216 14.6001 4.88398 14.2419 4.88398 13.8001V11.0001H4.28398C3.84216 11.0001 3.48398 10.6419 3.48398 10.2001Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.484 3.00006C10.9258 3.00006 11.284 3.35823 11.284 3.80006V19.8001C11.284 20.2419 10.9258 20.6001 10.484 20.6001C10.0422 20.6001 9.68398 20.2419 9.68398 19.8001V18.2001H1.68398C1.25964 18.2001 0.852673 18.0315 0.552613 17.7314C0.252555 17.4314 0.0839844 17.0244 0.0839844 16.6001V7.00006C0.0839844 6.57571 0.252555 6.16875 0.552613 5.86869C0.852671 5.56863 1.25964 5.40006 1.68398 5.40006H9.68398V3.80006C9.68398 3.35823 10.0422 3.00006 10.484 3.00006ZM9.68398 7.00006H1.68398V16.6001H9.68398V7.00006Z"
      fill={color}
    />
    <path
      d="M12.884 6.20006C12.884 5.75823 13.2422 5.40006 13.684 5.40006H22.484C22.9083 5.40006 23.3153 5.56863 23.6154 5.86869C23.9154 6.16875 24.084 6.57572 24.084 7.00006V16.6001C24.084 17.0244 23.9154 17.4314 23.6154 17.7314C23.3153 18.0315 22.9083 18.2001 22.484 18.2001H13.684C13.2422 18.2001 12.884 17.8419 12.884 17.4001C12.884 16.9582 13.2422 16.6001 13.684 16.6001H22.484V7.00006H13.684C13.2422 7.00006 12.884 6.64189 12.884 6.20006Z"
      fill={color}
    />
  </>
);
