/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const AddBookmark = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M8.89113 2C9.33297 2 9.69115 2.35818 9.69115 2.80002C9.69115 3.24185 9.33297 3.60003 8.89113 3.60003H5.99993V19.7574L11.175 16.5223C11.3022 16.4428 11.4491 16.4007 11.599 16.4007C11.749 16.4007 11.8959 16.4428 12.0231 16.5223L17.2002 19.7574V12.4035C17.2002 11.9617 17.5583 11.6035 18.0002 11.6035C18.442 11.6035 18.8002 11.9617 18.8002 12.4035V21.2004C18.8 21.343 18.7617 21.483 18.6892 21.6058C18.6168 21.7287 18.5128 21.8299 18.3881 21.899C18.2633 21.9682 18.1224 22.0027 17.9798 21.9991C17.8372 21.9955 17.6982 21.9538 17.5772 21.8784L11.6 18.1433L5.62393 21.8784C5.50286 21.9541 5.36374 21.996 5.22102 21.9997C5.07829 22.0035 4.93716 21.969 4.81227 21.8998C4.68738 21.8306 4.58327 21.7293 4.51076 21.6063C4.43825 21.4833 4.39997 21.3432 4.3999 21.2004V3.60003C4.3999 3.17568 4.56848 2.7687 4.86854 2.46864C5.1686 2.16857 5.57558 2 5.99993 2H8.89113Z"
      fill={color}
    />
    <path
      d="M15.0485 2.59259C15.0485 2.26531 15.3138 2 15.6411 2C15.9684 2 16.2337 2.26531 16.2337 2.59259V5.16847H19.0076C19.3349 5.16847 19.6002 5.43378 19.6002 5.76105C19.6002 6.08833 19.3349 6.35364 19.0076 6.35364H16.2337V8.92952C16.2337 9.2568 15.9684 9.52211 15.6411 9.52211C15.3138 9.52211 15.0485 9.2568 15.0485 8.92952V6.35364H12.2746C11.9473 6.35364 11.682 6.08833 11.682 5.76105C11.682 5.43378 11.9473 5.16847 12.2746 5.16847H15.0485V2.59259Z"
      fill={color}
    />
  </>
);
