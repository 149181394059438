/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const VerticalDots = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M12.084 4.63659C13.3643 4.63659 14.4023 3.59866 14.4023 2.3183C14.4023 1.03794 13.3643 0 12.084 0C10.8036 0 9.76569 1.03794 9.76569 2.3183C9.76569 3.59866 10.8036 4.63659 12.084 4.63659Z"
      fill={color}
    />
    <path
      d="M12.084 14.3183C13.3643 14.3183 14.4023 13.2804 14.4023 12C14.4023 10.7196 13.3643 9.68171 12.084 9.68171C10.8036 9.68171 9.76569 10.7196 9.76569 12C9.76569 13.2804 10.8036 14.3183 12.084 14.3183Z"
      fill={color}
    />
    <path
      d="M14.4023 21.6817C14.4023 22.9621 13.3643 24 12.084 24C10.8036 24 9.76569 22.9621 9.76569 21.6817C9.76569 20.4013 10.8036 19.3634 12.084 19.3634C13.3643 19.3634 14.4023 20.4013 14.4023 21.6817Z"
      fill={color}
    />
  </>
);
