/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const RotateRight = ({ color = '#191919' }: IconProps) => (
  <path
    d="M22 7.55565V13.4816C22 13.678 21.922 13.8664 21.783 14.0054C21.6441 14.1443 21.4557 14.2223 21.2593 14.2223H15.3333C15.1369 14.2223 14.9485 14.1443 14.8095 14.0054C14.6706 13.8664 14.5926 13.678 14.5926 13.4816C14.5926 13.2851 14.6706 13.0967 14.8095 12.9578C14.9485 12.8189 15.1369 12.7408 15.3333 12.7408H19.463L17.3926 10.6844C16.2532 9.54444 14.8014 8.76802 13.2207 8.45331C11.64 8.1386 10.0015 8.29973 8.51239 8.91633C7.02329 9.53292 5.75049 10.5773 4.85499 11.9173C3.95948 13.2573 3.4815 14.8328 3.48148 16.4445C3.48148 16.641 3.40344 16.8294 3.26452 16.9683C3.12561 17.1072 2.9372 17.1853 2.74074 17.1853C2.54428 17.1853 2.35587 17.1072 2.21696 16.9683C2.07804 16.8294 2 16.641 2 16.4445C1.99997 14.54 2.56472 12.6781 3.62283 11.0945C4.68095 9.5109 6.1849 8.27662 7.94451 7.54777C9.70411 6.81891 11.6403 6.62821 13.5083 6.99978C15.3763 7.37136 17.0922 8.28852 18.4389 9.63528L20.5185 11.701V7.55565C20.5185 7.35919 20.5966 7.17078 20.7355 7.03187C20.8744 6.89295 21.0628 6.81491 21.2593 6.81491C21.4557 6.81491 21.6441 6.89295 21.783 7.03187C21.922 7.17078 22 7.35919 22 7.55565Z"
    fill={color}
  />
);
