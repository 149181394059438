import { useState, useEffect } from "react";
import InputWithLabel from "./InputWithLabel";
import { Grid } from "./Grid";
import { Button } from "./Button";
import DatePicker from 'react-tailwindcss-datepicker';
import apiFranquias from '../services/apiFranquias';
import { REGIONAL_ENUM, REGIONAL_NAMES } from "../types";
import CustomSelect from "./Select";

interface SearchFormProps {
  initialDocumento?: string;
  initialMatricula?: string;
  initialNome?: string;
  initialStartDate?: Date | null;
  initialEndDate?: Date | null;
  regionais: Regional[];
  franquias: Franquia[];
  onSearch: (documento: string, matricula: string, nome: string, dtIni: Date | null, dtFinal: Date | null, regional: number | null, franquia: number | null) => void;
}

interface Regional {
  id: number;
  nome: string;
}

interface Franquia {
  id: number;
  nome: string;
}

const SearchForm: React.FC<SearchFormProps> = ({
  initialDocumento = '',
  initialMatricula = '',
  initialNome = '',
  initialStartDate = null,
  initialEndDate = null,
  onSearch
}) => {
  const [documento, setDocumento] = useState<string>(initialDocumento);
  const [matricula, setMatricula] = useState<string>(initialMatricula);
  const [nome, setNome] = useState<string>(initialNome);
  const [dtIni, setDtIni] = useState<Date | null>(initialStartDate);
  const [dtFinal, setDtFinal] = useState<Date | null>(initialEndDate);
  let [selectedRegional, setSelectedRegional] = useState<number | null>(null);
  const [franquias, setFranquias] = useState<Franquia[]>([]);
  let [selectedFranquia, setSelectedFranquia] = useState<number | null>(null);

  const getRegionalName = (regionalEnumValue: REGIONAL_ENUM): string => {
    return REGIONAL_NAMES[regionalEnumValue] || "Desconhecido";
  };

  const regionais = Object.keys(REGIONAL_ENUM)
    .filter((key) => isNaN(Number(key)))
    .map((key) => ({
      id: REGIONAL_ENUM[key as keyof typeof REGIONAL_ENUM],
      nome: "REGIONAL " + getRegionalName(REGIONAL_ENUM[key as keyof typeof REGIONAL_ENUM])
    }));

    useEffect(() => {
      const fetchFranquias = async () => {
        if (selectedRegional !== null) {
          try {
            const response = await apiFranquias.get(
              `/franquias/franquias-por-regional/${selectedRegional}`
            );
            const franquiasData = response.data.data;
    
            const franquiasComTodas = [{ id: -1, nome: 'TODAS FRANQUIAS' }, ...franquiasData];
            setFranquias(franquiasComTodas);
            setSelectedFranquia(-1);
          } catch (error) {
            console.error("Erro ao buscar franquias:", error);
          }
        } else {
          setFranquias([]);
          setSelectedFranquia(null);
        }
      };
    
      if (selectedRegional !== null) {
        fetchFranquias();
      }
    }, [selectedRegional]);

  const handleRegionalChange = (value: number) => {
    setSelectedRegional(value);
  };

  const handleFranquiaChange = (value: number) => {
    setSelectedFranquia(value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedFranquia === -1){
      selectedFranquia = null;
    }
    onSearch(documento, matricula, nome, dtIni, dtFinal, selectedFranquia, selectedRegional);
  };

  const handleDateChange = (value: { startDate: Date | null, endDate: Date | null }) => {
    setDtIni(value.startDate);
    setDtFinal(value.endDate);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid.Container cols={12} rowSpacing='20px' gridSpacing='20px'>
        <Grid.Item colSpan={2}>
          <label className="block text-sm font-medium text-gray-700">
            Período
          </label>
          <DatePicker
            value={{
              startDate: dtIni,
              endDate: dtFinal
            }}
            onChange={(value: any) => handleDateChange(value)}
            primaryColor={"emerald"}
            separator={"a"}
            displayFormat={"DD/MM/YYYY"}
            placeholder={"Informe um período"}
            inputClassName={"mt-1 h-10 rounded-md border px-4 py-2 w-full"}
            i18n={"pt-BR"}
          />
        </Grid.Item>

        <Grid.Item colSpan={2}>
          <InputWithLabel
            id="nome"
            label="Nome"
            value={nome}
            setValue={setNome}
            placeholder="Informe um nome"
          />
        </Grid.Item>

        <Grid.Item colSpan={1}>
          <InputWithLabel
            id="matricula"
            label="Matricula"
            value={matricula}
            setValue={setMatricula}
            placeholder="Matricula"
          />
        </Grid.Item>

        <Grid.Item colSpan={2}>
          <InputWithLabel
            id="documento"
            label="Documento"
            value={documento}
            setValue={setDocumento}
            placeholder="Informe um documento"
          />
        </Grid.Item>

        <Grid.Item colSpan={2}>
          <CustomSelect
            options={regionais}
            value={selectedRegional}
            onChange={handleRegionalChange}
            label={"Regional"}
            placeholder={"Selecione uma regional"}
          />
        </Grid.Item>

        <Grid.Item colSpan={2}>
          <CustomSelect
            options={franquias}
            value={selectedFranquia}
            onChange={handleFranquiaChange}
            label={"Franquia"}
            placeholder={"Selecione uma franquia"}
          />
        </Grid.Item>

        <Grid.Item colSpan={1} mt='24px'>
          <Button>Buscar</Button>
        </Grid.Item>
      </Grid.Container>
    </form>
  );
};

export default SearchForm;
