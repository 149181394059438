import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/logo-lg.svg';
import { useAuth } from '../hooks/useAuth';
import { ReactComponent as SignOut } from '../assets/icons/sign-out.svg';
import { Button } from './Button';

const Header: React.FC = () => {
  const { logout } = useAuth();

  return (
    <header className="mb-4 flex items-center justify-between p-4 shadow rounded-md pb-3 pt-3 bg-white mt-5 ml-5 mr-5">
      <div className="flex items-center">
        <img src={logo} alt="Logo" className="h-8 mr-4" />
        <nav className="flex gap-4">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "px-4 py-2 bg-white text-green-todos font-bold rounded-md"
                : "px-4 py-2 bg-white text-gray-900 rounded hover:bg-green-todos hover:text-white"
            }
          >
            Energia Todos
          </NavLink>
          <NavLink
            to="/smart-share"
            className={({ isActive }) =>
              isActive
                ? "px-4 py-2 bg-white text-green-todos font-bold rounded-md"
                : "px-4 py-2 bg-white text-gray-900 rounded hover:bg-green-todos hover:text-white"
            }
          >
            SmartShare
          </NavLink>
        </nav>
      </div>
      <Button onClick={logout} className="gap-2 text-gray-900 bg-white border border-white focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-white dark:text-white dark:white dark:hover:bg-gray-300 dark:hover:border-gray-400 dark:focus:ring-gray-400 text-center inline-flex items-center">
        <SignOut />
      </Button>
    </header>
  );
}

export default Header;