/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Import = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M11.407 15.0854C11.4191 15.0977 11.4316 15.1096 11.4445 15.1212C11.5136 15.183 11.5904 15.2313 11.6717 15.2661C11.7725 15.3094 11.8834 15.3333 12 15.3333L11.407 15.0854Z"
      fill={color}
    />
    <path
      d="M7.03581 10.7142L11.407 15.0854L12.0051 15.3333C12.1126 15.3327 12.2154 15.3116 12.3096 15.2739C12.4114 15.2332 12.5069 15.1717 12.5893 15.0892L16.9643 10.7142C17.2897 10.3888 17.2897 9.86117 16.9643 9.53573C16.6389 9.21029 16.1112 9.21029 15.7858 9.53573L12.8334 12.4882V2.83333C12.8334 2.3731 12.4603 2 12 2C11.5398 2 11.1667 2.3731 11.1667 2.83333V12.4881L8.21432 9.53573C7.88889 9.21029 7.36125 9.21029 7.03581 9.53573C6.71038 9.86117 6.71038 10.3888 7.03581 10.7142Z"
      fill={color}
    />
    <path
      d="M3.66669 14.5C3.66669 14.0397 3.29359 13.6666 2.83335 13.6666C2.37311 13.6666 2.00002 14.0397 2.00002 14.5V20.3333C2.00002 20.7754 2.17561 21.1993 2.48817 21.5118C2.80074 21.8244 3.22466 22 3.66669 22H20.3334C20.7755 22 21.1994 21.8244 21.5119 21.5118C21.8245 21.1993 22.0001 20.7754 22.0001 20.3333V14.5C22.0001 14.0397 21.627 13.6666 21.1668 13.6666C20.7065 13.6666 20.3334 14.0397 20.3334 14.5V20.3333H3.66669V14.5Z"
      fill={color}
    />
  </>
);
