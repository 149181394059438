/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Location = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M12 6.28576C11.2936 6.28576 10.6031 6.49522 10.0158 6.88766C9.42846 7.2801 8.9707 7.83788 8.70038 8.49048C8.43006 9.14308 8.35934 9.86118 8.49714 10.554C8.63495 11.2468 8.9751 11.8832 9.47458 12.3826C9.97405 12.8821 10.6104 13.2223 11.3032 13.3601C11.996 13.4979 12.7141 13.4271 13.3667 13.1568C14.0193 12.8865 14.5771 12.4287 14.9695 11.8414C15.362 11.2541 15.5714 10.5636 15.5714 9.85722C15.5714 8.91001 15.1952 8.00159 14.5254 7.33182C13.8556 6.66204 12.9472 6.28576 12 6.28576ZM12 12.0001C11.5762 12.0001 11.1619 11.8744 10.8095 11.639C10.4571 11.4035 10.1824 11.0688 10.0202 10.6773C9.85803 10.2857 9.8156 9.85484 9.89828 9.43917C9.98096 9.02349 10.1851 8.64166 10.4847 8.34198C10.7844 8.04229 11.1662 7.8382 11.5819 7.75552C11.9976 7.67283 12.4285 7.71527 12.82 7.87746C13.2116 8.03965 13.5463 8.31431 13.7817 8.6667C14.0172 9.0191 14.1429 9.4334 14.1429 9.85722C14.1429 10.4255 13.9171 10.9706 13.5152 11.3725C13.1134 11.7743 12.5683 12.0001 12 12.0001ZM12 2C9.91684 2.00236 7.9197 2.83093 6.4467 4.30394C4.9737 5.77694 4.14512 7.77408 4.14276 9.85722C4.14276 12.6608 5.43831 15.6323 7.8928 18.4511C8.99568 19.7248 10.237 20.8717 11.5937 21.8707C11.7138 21.9549 11.8569 22 12.0036 22C12.1502 22 12.2933 21.9549 12.4134 21.8707C13.7676 20.8713 15.0065 19.7244 16.1072 18.4511C18.5581 15.6323 19.8572 12.6608 19.8572 9.85722C19.8548 7.77408 19.0263 5.77694 17.5533 4.30394C16.0803 2.83093 14.0831 2.00236 12 2ZM12 20.393C10.5241 19.2323 5.57135 14.9689 5.57135 9.85722C5.57135 8.15224 6.24865 6.51709 7.45425 5.31149C8.65985 4.10589 10.295 3.42859 12 3.42859C13.705 3.42859 15.3401 4.10589 16.5457 5.31149C17.7513 6.51709 18.4286 8.15224 18.4286 9.85722C18.4286 14.9671 13.4759 19.2323 12 20.393Z"
      fill={color}
    />
  </>
);
