/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Premium = ({ color = '#191919' }: IconProps) => (
  <path
    d="M22.0549 8.8642C21.9956 8.64681 21.8771 8.45906 21.7091 8.31083C21.5411 8.16261 21.3336 8.07368 21.1162 8.04403C20.8889 8.01439 20.6715 8.05391 20.4739 8.15273L16.4422 10.1587L13.0429 4.53607C12.9441 4.36809 12.8058 4.22975 12.6378 4.14081C12.3018 3.95306 11.867 3.95306 11.5311 4.14081C11.3631 4.23963 11.2247 4.36809 11.1259 4.53607L7.73655 10.1784L3.70487 8.16261C3.50724 8.06379 3.27996 8.02427 3.06257 8.05391C2.84517 8.08356 2.63766 8.17249 2.46967 8.32071C2.30169 8.46894 2.18311 8.65669 2.12382 8.8642C2.06453 9.08159 2.07441 9.29899 2.1337 9.51638L5.17722 18.8545C5.21675 18.9632 5.27604 19.062 5.35509 19.1509C5.43414 19.23 5.53296 19.2991 5.64166 19.3387C5.75035 19.3782 5.86893 19.3881 5.98751 19.3881C6.10609 19.3782 6.21479 19.3387 6.3136 19.2794C6.33336 19.2695 8.40849 18.0738 12.0746 18.0738C15.7406 18.0738 17.8157 19.2695 17.8355 19.2794C17.9343 19.3387 18.0529 19.3782 18.1715 19.3881C18.1912 19.3881 18.2209 19.3881 18.2407 19.3881C18.3395 19.3881 18.4284 19.3683 18.5173 19.3387C18.626 19.2991 18.7248 19.23 18.8039 19.1509C18.883 19.062 18.9422 18.9632 18.9818 18.8545L22.0253 9.52627C22.0945 9.31875 22.1043 9.09148 22.0451 8.87408L22.0549 8.8642ZM20.217 10.0302L17.7762 17.5106C16.7881 17.1054 14.8414 16.4928 12.0844 16.4928C9.32748 16.4928 7.38081 17.1054 6.39265 17.5106L3.95191 10.0401L7.40057 11.7595C7.65749 11.888 7.94406 11.9077 8.21086 11.8287C8.47766 11.7496 8.71482 11.5717 8.86305 11.3346L12.0844 5.97878L15.3058 11.3346C15.4541 11.5717 15.6813 11.7496 15.9481 11.8287C16.2149 11.9077 16.5015 11.8781 16.7584 11.7595L20.2071 10.0401L20.217 10.0302Z"
    fill={color}
  />
);
