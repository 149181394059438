import { GridContainer } from './GridContainer';
import { GridItem } from './GridItem';
import { GridItemProps, GridContainerProps } from './types';

type GridType = {
  Container: (props: GridContainerProps) => JSX.Element;
  Item: (props: GridItemProps) => JSX.Element;

}

export const Grid:GridType = {
  Container: GridContainer,
  Item: GridItem,
};