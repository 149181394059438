/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Undo = ({ color = '#191919' }: IconProps) => (
  <path
    d="M8.01253 4.85161C8.31294 4.55121 8.31294 4.06416 8.01253 3.76375C7.71213 3.46335 7.22508 3.46335 6.92468 3.76375L2.30929 8.37914C2.00888 8.67955 2.00888 9.1666 2.30929 9.467L6.92468 14.0824C7.22508 14.3828 7.71213 14.3828 8.01253 14.0824C8.31294 13.782 8.31294 13.2949 8.01253 12.9945L4.7103 9.6923H15.9301C17.1542 9.6923 18.3282 10.1786 19.1937 11.0441C20.0593 11.9097 20.5455 13.0836 20.5455 14.3077C20.5455 15.5318 20.0593 16.7057 19.1937 17.5713C18.3282 18.4368 17.1542 18.9231 15.9301 18.9231H7.46859C7.04375 18.9231 6.69936 19.2675 6.69936 19.6923C6.69936 20.1171 7.04375 20.4615 7.46859 20.4615H15.9301C17.5622 20.4615 19.1275 19.8132 20.2816 18.6591C21.4356 17.5051 22.084 15.9398 22.084 14.3077C22.084 12.6756 21.4356 11.1103 20.2816 9.95626C19.1275 8.80219 17.5622 8.15384 15.9301 8.15384H4.7103L8.01253 4.85161Z"
    fill={color}
  />
);
