/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const School = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M16.084 17.2C16.084 16.7582 16.4422 16.4 16.884 16.4H18.484C18.9258 16.4 19.284 16.7582 19.284 17.2C19.284 17.6418 18.9258 18 18.484 18H16.884C16.4422 18 16.084 17.6418 16.084 17.2Z"
      fill={color}
    />
    <path
      d="M16.884 12.4C16.4422 12.4 16.084 12.7582 16.084 13.2C16.084 13.6418 16.4422 14 16.884 14H18.484C18.9258 14 19.284 13.6418 19.284 13.2C19.284 12.7582 18.9258 12.4 18.484 12.4H16.884Z"
      fill={color}
    />
    <path
      d="M5.68398 6C5.24216 6 4.88398 6.35817 4.88398 6.8C4.88398 7.24183 5.24216 7.6 5.68398 7.6H8.88398C9.32581 7.6 9.68398 7.24183 9.68398 6.8C9.68398 6.35817 9.32581 6 8.88398 6H5.68398Z"
      fill={color}
    />
    <path
      d="M6.48398 13.2C6.48398 12.7582 6.84216 12.4 7.28398 12.4H10.484C10.9258 12.4 11.284 12.7582 11.284 13.2C11.284 13.6418 10.9258 14 10.484 14H7.28398C6.84216 14 6.48398 13.6418 6.48398 13.2Z"
      fill={color}
    />
    <path
      d="M5.68398 16.4C5.24216 16.4 4.88398 16.7582 4.88398 17.2C4.88398 17.6418 5.24216 18 5.68398 18H8.88398C9.32581 18 9.68398 17.6418 9.68398 17.2C9.68398 16.7582 9.32581 16.4 8.88398 16.4H5.68398Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.28398 2C2.85964 2 2.45267 2.16857 2.15261 2.46863C1.85256 2.76869 1.68398 3.17565 1.68398 3.6V20.4H0.883984C0.442157 20.4 0.0839844 20.7582 0.0839844 21.2C0.0839844 21.6418 0.442157 22 0.883984 22H23.284C23.7258 22 24.084 21.6418 24.084 21.2C24.084 20.7582 23.7258 20.4 23.284 20.4H22.484V10C22.484 9.57565 22.3154 9.16869 22.0154 8.86863C21.7153 8.56857 21.3083 8.4 20.884 8.4H14.484V3.6C14.484 3.17566 14.3154 2.76869 14.0154 2.46863C13.7153 2.16857 13.3083 2 12.884 2H3.28398ZM20.884 10V20.4H14.484V10H20.884ZM12.884 3.6V20.4H3.28398V3.6H12.884Z"
      fill={color}
    />
  </>
);
