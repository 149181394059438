/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const UndoLink = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.6161 9.76261C7.92463 10.0711 7.92463 10.5714 7.6161 10.8799L4.82138 13.6746C4.08035 14.4156 3.66404 15.4207 3.66404 16.4687C3.66404 17.5166 4.08035 18.5217 4.82138 19.2627C5.5624 20.0037 6.56745 20.42 7.61543 20.42C8.13433 20.42 8.64815 20.3178 9.12755 20.1193C9.60696 19.9207 10.0426 19.6296 10.4095 19.2627L13.2042 16.468C13.5127 16.1595 14.0129 16.1595 14.3215 16.468C14.63 16.7765 14.63 17.2767 14.3215 17.5852L11.5267 20.38C11.0131 20.8936 10.4033 21.3011 9.73222 21.579C9.06111 21.857 8.34182 22.0001 7.61543 22.0001C6.1484 22.0001 4.74145 21.4173 3.70411 20.38C2.66676 19.3426 2.08398 17.9357 2.08398 16.4687C2.08398 15.0016 2.66676 13.5947 3.70411 12.5573L6.49883 9.76261C6.80736 9.45408 7.30758 9.45408 7.6161 9.76261Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6412 3.62012C13.6786 2.58277 15.0855 2 16.5525 2C18.0196 2 19.4265 2.58277 20.4639 3.62012C21.5012 4.65747 22.084 6.06441 22.084 7.53144C22.084 8.99847 21.5012 10.4054 20.4639 11.4428L17.6691 14.2375C17.3606 14.546 16.8604 14.546 16.5519 14.2375C16.2433 13.929 16.2433 13.4287 16.5519 13.1202L19.3466 10.3255C20.0876 9.58446 20.5039 8.57941 20.5039 7.53144C20.5039 6.48347 20.0876 5.47842 19.3466 4.73739C18.6056 3.99636 17.6005 3.58006 16.5525 3.58006C15.5046 3.58006 14.4995 3.99636 13.7585 4.73739L10.9638 7.53212C10.6552 7.84064 10.155 7.84064 9.8465 7.53212C9.53797 7.22359 9.53797 6.72337 9.8465 6.41485L12.6412 3.62012Z"
      fill={color}
    />
  </>
);
