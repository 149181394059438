/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const SeeUser = ({ color = '#191919' }: IconProps) => (
  <path
    d="M20.3333 2H3.66667C3.22464 2 2.80072 2.17559 2.48816 2.48816C2.17559 2.80072 2 3.22464 2 3.66667V20.3333C2 20.7754 2.17559 21.1993 2.48816 21.5118C2.80072 21.8244 3.22464 22 3.66667 22H20.3333C20.7754 22 21.1993 21.8244 21.5118 21.5118C21.8244 21.1993 22 20.7754 22 20.3333V3.66667C22 3.22464 21.8244 2.80072 21.5118 2.48816C21.1993 2.17559 20.7754 2 20.3333 2ZM8.66667 11.1667C8.66667 10.5074 8.86216 9.86293 9.22843 9.31477C9.59471 8.7666 10.1153 8.33936 10.7244 8.08707C11.3335 7.83478 12.0037 7.76876 12.6503 7.89738C13.2969 8.026 13.8908 8.34347 14.357 8.80964C14.8232 9.27582 15.1407 9.86976 15.2693 10.5164C15.3979 11.163 15.3319 11.8332 15.0796 12.4423C14.8273 13.0514 14.4001 13.572 13.8519 13.9382C13.3037 14.3045 12.6593 14.5 12 14.5C11.1159 14.5 10.2681 14.1488 9.64298 13.5237C9.01786 12.8986 8.66667 12.0507 8.66667 11.1667ZM5.81979 20.3333C6.24623 19.2835 6.93182 18.3589 7.8125 17.6458C8.99825 16.6886 10.4761 16.1666 12 16.1666C13.5239 16.1666 15.0018 16.6886 16.1875 17.6458C17.0682 18.3589 17.7538 19.2835 18.1802 20.3333H5.81979ZM20.3333 20.3333H19.951C19.5805 19.1538 18.9519 18.0717 18.1108 17.1655C17.2697 16.2594 16.2372 15.552 15.0885 15.0948C15.9063 14.4524 16.5035 13.5711 16.7969 12.5735C17.0903 11.5758 17.0653 10.5116 16.7255 9.52877C16.3856 8.54598 15.7478 7.6936 14.9009 7.09029C14.0539 6.48698 13.0399 6.16276 12 6.16276C10.9601 6.16276 9.94611 6.48698 9.09913 7.09029C8.25215 7.6936 7.61436 8.54598 7.27453 9.52877C6.9347 10.5116 6.90974 11.5758 7.20314 12.5735C7.49654 13.5711 8.09369 14.4524 8.91146 15.0948C7.76276 15.552 6.73034 16.2594 5.88925 17.1655C5.04815 18.0717 4.41949 19.1538 4.04896 20.3333H3.66667V3.66667H20.3333V20.3333Z"
    fill={color}
  />
);
