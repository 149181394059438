/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const PrintedTest = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M8.69937 12.7692C8.69937 12.3444 9.04377 12 9.4686 12H15.6224C16.0473 12 16.3917 12.3444 16.3917 12.7692C16.3917 13.1941 16.0473 13.5385 15.6224 13.5385H9.4686C9.04377 13.5385 8.69937 13.1941 8.69937 12.7692Z"
      fill={color}
    />
    <path
      d="M9.4686 15.0769C9.04377 15.0769 8.69937 15.4213 8.69937 15.8462C8.69937 16.271 9.04377 16.6154 9.4686 16.6154H15.6224C16.0473 16.6154 16.3917 16.271 16.3917 15.8462C16.3917 15.4213 16.0473 15.0769 15.6224 15.0769H9.4686Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.62245 2C5.21442 2 4.82311 2.16209 4.53459 2.45061C4.24607 2.73912 4.08398 3.13044 4.08398 3.53846V20.4615C4.08398 20.8696 4.24607 21.2609 4.53459 21.5494C4.82311 21.8379 5.21442 22 5.62245 22H19.4686C19.8766 22 20.2679 21.8379 20.5565 21.5494C20.845 21.2609 21.0071 20.8696 21.0071 20.4615V8.15385C21.0071 7.94983 20.926 7.75418 20.7818 7.60992L15.3971 2.2253C15.2529 2.08104 15.0572 2 14.8532 2H5.62245ZM14.084 3.53846L5.62245 3.53846L5.62245 20.4615H19.4686V8.92308H14.8532C14.4284 8.92308 14.084 8.57868 14.084 8.15385V3.53846ZM18.3807 7.38462L15.6224 4.62632V7.38462H18.3807Z"
      fill={color}
    />
  </>
);
