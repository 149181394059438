import { useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  flexRender,
  ColumnDef,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import Loading from './Loading';
import NoRegister from './NoRegister';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

export type DataItem = { [key: string]: any };
export type TableColumn<T extends object> = ColumnDef<T>;
interface DynamicTableProps<T extends object> {
  columns: TableColumn<T>[];
  data: T[];
  isLoading: boolean;
  filterTable?: boolean;
}

const Table = <T extends object>({
  columns,
  data,
  isLoading,
  filterTable = true,
}: DynamicTableProps<T>) => {
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  const toggleRowExpansion = (rowId: string) => {
    setExpandedRows(prev => ({
      ...prev,
      [rowId]: !prev[rowId]
    }));
  };
  
  const table = useReactTable<T>({
    columns,
    data,
    state: {
      globalFilter,
      sorting,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting, 
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className="relative rounded-md bg-white p-4 shadow overflow-auto">
    {isLoading && (
      <div className="absolute inset-0 bg-white bg-opacity-50 flex justify-center items-center">
        <Loading />
      </div>
    )}

    {filterTable && data.length >= 1 && (
      <input
        type="text"
        value={globalFilter}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Buscar nas colunas..."
        className="mb-4 w-full rounded-md border border-gray-300 p-2"
      />
    )}
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  className="py-3 text-left text-sm font-semibold text-gray-700 bg-gray-100 hover:bg-gray-200 cursor-pointer px-2"
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.isPlaceholder ? null : (
                    <div className="flex items-center">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getCanSort() ? (
                        header.column.getIsSorted() === 'desc' ? <FaChevronDown className="ml-2" /> : <FaChevronUp className="ml-2" />
                      ) : <span className="ml-2" />}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td
                  key={cell.id}
                  className="whitespace-nowrap py-2 text-sm text-gray-500 px-2"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    {data.length === 0 && !isLoading && <NoRegister />}
  </div>
  );
};

export default Table;
