/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const RadioOn = ({ color = '#191919' }: IconProps) => (
  <path
    d="M12 4C10.4178 4 8.87103 4.46919 7.55544 5.34824C6.23985 6.22729 5.21447 7.47672 4.60897 8.93853C4.00347 10.4003 3.84504 12.0089 4.15372 13.5607C4.4624 15.1126 5.22433 16.538 6.34315 17.6569C7.46197 18.7757 8.88743 19.5376 10.4393 19.8463C11.9911 20.155 13.5997 19.9965 15.0615 19.391C16.5233 18.7855 17.7727 17.7602 18.6518 16.4446C19.5308 15.129 20 13.5822 20 12C19.9959 9.87952 19.1518 7.84705 17.6524 6.34764C16.153 4.84823 14.1205 4.00407 12 4ZM12 18.7692C10.6612 18.7692 9.35241 18.3722 8.23922 17.6284C7.12603 16.8846 6.2584 15.8274 5.74605 14.5905C5.2337 13.3536 5.09965 11.9925 5.36084 10.6794C5.62203 9.36629 6.26674 8.16012 7.21343 7.21343C8.16013 6.26674 9.36629 5.62203 10.6794 5.36084C11.9925 5.09965 13.3536 5.2337 14.5905 5.74605C15.8274 6.25839 16.8846 7.12602 17.6284 8.23922C18.3722 9.35241 18.7692 10.6612 18.7692 12C18.7672 13.7947 18.0534 15.5153 16.7843 16.7843C15.5153 18.0534 13.7947 18.7672 12 18.7692ZM16.9231 12C16.9231 12.9737 16.6343 13.9255 16.0934 14.7351C15.5524 15.5447 14.7836 16.1757 13.884 16.5483C12.9844 16.9209 11.9945 17.0184 11.0396 16.8285C10.0846 16.6385 9.20737 16.1696 8.51886 15.4811C7.83036 14.7926 7.36148 13.9154 7.17152 12.9604C6.98156 12.0055 7.07906 11.0156 7.45167 10.116C7.82429 9.21644 8.45529 8.44756 9.26489 7.90661C10.0745 7.36566 11.0263 7.07692 12 7.07692C13.3051 7.07896 14.5561 7.59829 15.4789 8.5211C16.4017 9.44392 16.921 10.6949 16.9231 12Z"
    fill={color}
  />
);
