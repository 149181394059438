/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ValidateCourse = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3184 3.2063L19.4465 7.41527C19.5899 7.95011 19.1104 8.45248 18.5593 8.39805C18.2411 8.36664 17.959 8.15072 17.8762 7.84183L17.7902 7.52075L14.6461 8.36099L14.6443 8.36146L14.7102 8.6099C14.8283 9.05584 14.5626 9.51313 14.1166 9.63122C13.6707 9.74937 13.2135 9.48362 13.0953 9.03768C13.0003 8.67895 12.9057 8.32017 12.8111 7.96144L12.8031 7.93105C12.45 6.59113 12.0969 5.25179 11.722 3.91709H8.48131V16.1827H9.26232C9.70456 16.1827 10.0631 16.5413 10.0631 16.9835C10.0631 17.4257 9.70456 17.7843 9.26232 17.7843H8.48131V20.1203H9.25403C9.54729 20.1203 9.80941 20.3024 9.93384 20.568C10.1779 21.0891 9.82308 21.7406 9.24764 21.7406H8.48131C8.18615 21.7406 7.90946 21.6617 7.67116 21.5238C7.4328 21.6617 7.15612 21.7406 6.86098 21.7406H3.62033C2.72545 21.7406 2 21.0152 2 20.1203V3.91709C2 3.02221 2.72545 2.29674 3.62033 2.29674H6.86098C7.15612 2.29674 7.4328 2.37566 7.67116 2.5135C7.90946 2.37566 8.18615 2.29674 8.48131 2.29674H11.722C12.2544 2.29674 12.7269 2.55357 13.0222 2.9501L16.3253 2.0559C17.1934 1.82319 18.0857 2.33829 18.3184 3.2063ZM17.3706 5.95567L14.2294 6.79512L14.2221 6.76735L14.2191 6.76814L13.6032 4.47014L16.7466 3.62758L17.3706 5.95567ZM6.86098 7.9679V20.1203H3.62033V7.9679H6.86098ZM6.86098 6.34754V3.91709H3.62033V6.34754H6.86098Z"
      fill={color}
    />
    <path
      d="M19.0804 15.1002C19.2918 14.8986 19.6266 14.9065 19.8282 15.1178C20.0299 15.3292 20.022 15.664 19.8106 15.8657L16.8815 18.6601C16.677 18.8552 16.3552 18.8551 16.1509 18.6598L14.6888 17.2626C14.4776 17.0608 14.47 16.7259 14.6718 16.5147C14.8737 16.3035 15.2085 16.2959 15.4197 16.4978L16.5166 17.5461L19.0804 15.1002Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9304 16.6806C11.9304 13.7427 14.312 11.3611 17.2499 11.3611C20.1877 11.3611 22.5693 13.7427 22.5693 16.6806C22.5693 19.6184 20.1877 22 17.2499 22C14.312 22 11.9304 19.6184 11.9304 16.6806ZM17.2499 12.419C14.8963 12.419 12.9883 14.327 12.9883 16.6806C12.9883 19.0341 14.8963 20.9421 17.2499 20.9421C19.6035 20.9421 21.5114 19.0341 21.5114 16.6806C21.5114 14.327 19.6035 12.419 17.2499 12.419Z"
      fill={color}
    />
  </>
);
