/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Release = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M12.9999 15.3334C12.9999 15.9471 12.5025 16.4445 11.8888 16.4445C11.2752 16.4445 10.7777 15.9471 10.7777 15.3334C10.7777 14.7198 11.2752 14.2223 11.8888 14.2223C12.5025 14.2223 12.9999 14.7198 12.9999 15.3334Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.889 3.48148C11.2014 3.48148 10.542 3.75463 10.0557 4.24083C9.56954 4.72704 9.29639 5.38648 9.29639 6.07407V8.66672H19.2963C20.1145 8.66672 20.7778 9.33 20.7778 10.1482V20.5186C20.7778 21.3368 20.1145 22.0001 19.2963 22.0001H4.48148C3.66328 22.0001 3 21.3368 3 20.5186V10.1482C3 9.33 3.66328 8.66672 4.48148 8.66672H7.81491V6.07407C7.81491 4.99356 8.24414 3.95731 9.00818 3.19327C9.77222 2.42923 10.8085 2 11.889 2C12.9695 2 14.0058 2.42923 14.7698 3.19327C15.5338 3.95731 15.9631 4.99356 15.9631 6.07407C15.9631 6.48317 15.6314 6.81481 15.2223 6.81481C14.8132 6.81481 14.4816 6.48317 14.4816 6.07407C14.4816 5.38648 14.2084 4.72704 13.7222 4.24083C13.236 3.75463 12.5766 3.48148 11.889 3.48148ZM19.2963 10.1482H4.48148V20.5186H19.2963V10.1482Z"
      fill={color}
    />
  </>
);
