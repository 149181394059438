/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const RightAnswer = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M21.0017 15.1112L18.9095 17.1172L18.0144 16.2652C17.8419 16.1034 17.5615 16.1034 17.3997 16.276C17.2379 16.4485 17.2379 16.7289 17.4105 16.8907L18.6076 18.0338C18.7801 18.1956 19.039 18.1956 19.2115 18.0338L21.6057 15.7475C21.7782 15.5858 21.789 15.3054 21.6165 15.1328C21.4547 14.9603 21.1743 14.9495 21.0017 15.122V15.1112Z"
      fill={color}
    />
    <path
      d="M13.8733 8.66188C13.8733 5.54515 11.3282 3 8.21144 3C5.09471 3 2.53877 5.54515 2.53877 8.66188C2.53877 10.5492 3.4986 12.3286 5.06235 13.3747C4.53391 13.5688 4.01625 13.8061 3.53095 14.0865C2.10739 14.9061 0.931877 16.0924 0.101468 17.516C0.00440731 17.6885 -0.0279463 17.8934 0.0259764 18.0876C0.0798991 18.2817 0.187744 18.4758 0.371082 18.5405C0.9858 18.767 1.29855 18.4435 1.39561 18.2709C2.08582 17.0738 3.078 16.0816 4.27508 15.3914C5.4506 14.712 6.77709 14.3561 8.13595 14.3345H8.27615C9.45166 14.3345 10.638 14.6257 11.6948 15.1542C11.9321 15.2728 12.2125 15.262 12.4498 15.1326C12.6655 15.014 12.8057 14.7983 12.838 14.561C12.8704 14.2698 12.7194 13.9894 12.4605 13.86C12.1047 13.6767 11.738 13.5149 11.3605 13.3855C12.9243 12.3394 13.8841 10.56 13.8841 8.67267L13.8733 8.66188ZM8.27615 12.8247H8.14673C5.88198 12.7924 4.04861 10.9266 4.04861 8.66188C4.04861 6.39713 5.91433 4.49905 8.21144 4.49905C10.5085 4.49905 12.3743 6.36477 12.3743 8.66188C12.3743 10.959 10.5301 12.7924 8.27615 12.8247Z"
      fill={color}
    />
    <path
      d="M19.5137 11.919C17.0332 11.919 15.0273 13.9357 15.0273 16.4054C15.0273 18.8751 17.044 20.8918 19.5137 20.8918C21.9833 20.8918 24 18.8751 24 16.4054C24 13.9357 21.9833 11.919 19.5137 11.919ZM22.8677 16.4054C22.8677 18.2496 21.3686 19.7594 19.5137 19.7594C17.6587 19.7594 16.1597 18.2603 16.1597 16.4054C16.1597 14.5505 17.6587 13.0514 19.5137 13.0514C21.3686 13.0514 22.8677 14.5505 22.8677 16.4054Z"
      fill={color}
    />
  </>
);
