/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const SatisfactionSurvey = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8814 5.61654C10.702 5.61654 10.5242 5.67777 10.3845 5.78093C10.2702 5.86533 10.1192 6.07141 10.07 6.20607L9.14105 8.59039L6.6957 8.75036C6.28891 8.7714 6.01479 9.05228 5.91929 9.38384C5.82468 9.71231 5.90089 10.1046 6.19739 10.3607L8.07201 12.0261L7.51628 14.2918C7.30602 15.1058 8.17026 15.8639 8.91984 15.3746L10.8654 14.0978L12.9726 15.475C13.3183 15.6996 13.6945 15.6372 13.9561 15.4256C14.2141 15.2167 14.3682 14.8551 14.2791 14.4765L13.6749 11.9944L15.5646 10.3614C16.151 9.85607 15.8859 8.81227 15.0691 8.75049L12.6065 8.5744L11.693 6.18987C11.631 6.02017 11.5113 5.87911 11.3783 5.78093C11.2386 5.67777 11.0608 5.61654 10.8814 5.61654ZM9.08471 12.1488L9.06201 12.2456L8.99674 12.5253C8.94286 12.7569 8.87137 13.066 8.80091 13.376C8.73311 13.6744 8.6659 13.9728 8.60287 14.2723C8.85581 14.1153 9.10827 13.9575 9.36066 13.7996C9.62364 13.635 9.88614 13.4704 10.0829 13.347L10.4143 13.139C10.5405 13.0645 10.6977 13.0004 10.8653 13.0004C11.0199 13.0004 11.1816 13.0402 11.3256 13.145L11.6645 13.3637C11.8703 13.4965 12.1449 13.6736 12.42 13.8507C12.6775 14.0164 12.935 14.182 13.193 14.3469L12.6601 12.1577C12.6215 11.9978 12.6214 11.8198 12.6601 11.6599L12.6645 11.6414C12.7176 11.4952 12.7965 11.3462 12.9423 11.236L14.588 9.77398L12.4402 9.63347C12.2806 9.63214 12.103 9.5725 11.9762 9.46557C11.8374 9.36144 11.7402 9.21855 11.6825 9.0606L10.8701 6.97518L10.0641 9.04393C10.0058 9.20368 9.9071 9.34803 9.76568 9.45244C9.63296 9.55042 9.47407 9.61529 9.307 9.6168L7.14503 9.7714L8.80763 11.2222C8.92638 11.3247 9.02335 11.4658 9.08115 11.6251C9.14201 11.7929 9.14319 11.9803 9.08471 12.1488Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.08398 10.8461C2.08398 5.96055 6.04453 2 10.9301 2C15.8157 2 19.7763 5.96055 19.7763 10.8461C19.7763 13.012 18.9979 14.996 17.7057 16.5338L21.8587 20.6868C22.1591 20.9872 22.1591 21.4743 21.8587 21.7747C21.5583 22.0751 21.0712 22.0751 20.7708 21.7747L16.6178 17.6217C15.08 18.9139 13.096 19.6923 10.9301 19.6923C6.04453 19.6923 2.08398 15.7317 2.08398 10.8461ZM10.9301 3.53846C6.8942 3.53846 3.62244 6.81022 3.62244 10.8461C3.62244 14.8821 6.8942 18.1538 10.9301 18.1538C14.966 18.1538 18.2378 14.8821 18.2378 10.8461C18.2378 6.81022 14.966 3.53846 10.9301 3.53846Z"
      fill={color}
    />
  </>
);
