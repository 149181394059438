/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Filter = ({ color = '#191919' }: IconProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.56996 3H20.43C20.7346 3.0001 21.0328 3.08877 21.2879 3.25522C21.543 3.42167 21.7441 3.65871 21.8669 3.93746C21.9896 4.21621 22.0286 4.52464 21.9792 4.82518C21.9298 5.12513 21.7944 5.4043 21.5895 5.62879L21.5883 5.63009L15.1404 12.7257V18.2771C15.1421 18.5382 15.0782 18.7956 14.9546 19.0257C14.8312 19.2555 14.6521 19.4505 14.4339 19.5932L11.2966 21.6815L11.2934 21.6836C11.0578 21.8388 10.7848 21.9276 10.503 21.9408C10.2212 21.9539 9.94103 21.8909 9.69199 21.7584C9.44296 21.6258 9.23423 21.4286 9.08776 21.1875C8.94129 20.9464 8.86249 20.6703 8.85963 20.3882L8.85955 20.3802L8.85959 12.7258L8.85751 12.7236L2.41168 5.63009L2.41063 5.62894C2.20563 5.40442 2.07021 5.1252 2.02084 4.82518C1.97138 4.52465 2.0104 4.21622 2.13313 3.93746C2.25587 3.65871 2.45702 3.42167 2.71209 3.25522C2.96716 3.08877 3.26538 3.0001 3.56996 3ZM3.57021 4.57021L3.57199 4.57215L10.0166 11.6642C10.2847 11.9535 10.4325 12.3341 10.4298 12.7285V20.3722L13.5702 18.2819L13.5702 18.2801L13.5702 12.7285C13.5675 12.3341 13.7153 11.9535 13.9834 11.6642L20.428 4.57215L20.4298 4.57021H3.57021Z"
    fill={color}
  />
);
