import {
  HTMLAttributes,
  ReactNode,
} from 'react';
import { cva } from 'class-variance-authority';
import { cn } from '../utils';
import { SIZE_ENUM, SizeOptions } from '../types';

type ButtonVariants = 'primary' | 'neutral';
export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  disabled?: boolean;
  size?: SizeOptions;
  variant?: ButtonVariants;
}

const buttonVariants = cva<any>(
  [
    'flex',
    'gap-3',
    'flex-row',
    'items-center',
    'rounded-md',
    'transition-all',
    'cursor-pointer',
    'focus-visible:ring',
    'font-medium',
    'disabled:text-gray-300',
    'disabled:bg-gray-100',
    'disabled:select-none',
    'disabled:pointer-events-none',
  ],
  {
    variants: {
      variant: {        
        primary: [
          'text-white',
          'bg-green-todos',
          'hover:bg-green-dark-todos',
          'active:bg-button-primary_pressed',
          'focus-visible:ring-button-primary_pressed',
        ],        
        neutral: [
          'text-button-custom_text',          
          'bg-button-custom',   
          'hover:bg-green-todos', 
          'active:bg-button-custom_pressed',  
          'focus-visible:ring-button-custom_focus', 
          'disabled:bg-button-custom_disabled',
        ]         
      },
      size: {
        'sm': [
          'text-xs',
          'py-2',
          'px-4',
        ],
        'md': [
          'text-sm',
          'py-3',
          'px-5',
          'leading-4'
        ],
        'lg': [
          'text-base',
          'py-4',
          'px-6',
        ],
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: SIZE_ENUM.MEDIUM,
    },
  },
);

export const Button = ({
  children,
  className,
  variant,
  size = SIZE_ENUM.MEDIUM,
  ...props
}: ButtonProps) => {

  return (
    <button
      className={cn(buttonVariants({ variant, size, className }))}
      {...props}
    >
      {children}
    </button>
  );
};
