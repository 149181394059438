/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Share = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M16.1554 4.2253C16.4558 3.9249 16.9429 3.9249 17.2433 4.2253L21.8587 8.84069C21.9995 8.9815 22.0743 9.16333 22.0831 9.34772C22.0837 9.36001 22.084 9.37231 22.084 9.38462C22.084 9.38938 22.0839 9.39413 22.0839 9.39887C22.0821 9.49715 22.0618 9.59093 22.0265 9.6769C21.989 9.76844 21.933 9.85421 21.8587 9.92854L17.2433 14.5439C16.9429 14.8443 16.4558 14.8443 16.1554 14.5439C15.855 14.2435 15.855 13.7565 16.1554 13.4561L19.4577 10.1538H15.9304C14.054 10.1545 12.2308 10.778 10.7471 11.9267C9.26339 13.0753 8.20305 14.6842 7.73248 16.5006C7.62594 16.9119 7.20618 17.1589 6.79492 17.0523C6.38366 16.9458 6.13664 16.526 6.24318 16.1148C6.79921 13.9684 8.05213 12.0675 9.80531 10.7102C11.5585 9.35286 13.7129 8.61606 15.9301 8.61539H19.4577L16.1554 5.31316C15.855 5.01276 15.855 4.52571 16.1554 4.2253Z"
      fill={color}
    />
    <path
      d="M2.85322 7.07692C3.27805 7.07692 3.62245 7.42132 3.62245 7.84615V19.3846H18.2378C18.6627 19.3846 19.0071 19.729 19.0071 20.1538C19.0071 20.5787 18.6627 20.9231 18.2378 20.9231H3.62245C3.21442 20.9231 2.82311 20.761 2.53459 20.4725C2.24607 20.184 2.08398 19.7926 2.08398 19.3846V7.84615C2.08398 7.42132 2.42838 7.07692 2.85322 7.07692Z"
      fill={color}
    />
  </>
);
