/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const WrongAnswer = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M21.0665 14.7011C20.8939 14.5286 20.6135 14.5286 20.441 14.7011L19.4164 15.7257L18.3919 14.7011C18.2194 14.5286 17.939 14.5286 17.7664 14.7011C17.5938 14.8737 17.5938 15.1541 17.7664 15.3266L18.7909 16.3512L17.7664 17.3757C17.5938 17.5483 17.5938 17.8287 17.7664 18.0012C17.939 18.1738 18.2194 18.1738 18.3919 18.0012L19.4164 16.9767L20.441 18.0012C20.6135 18.1738 20.8939 18.1738 21.0665 18.0012C21.239 17.8287 21.239 17.5483 21.0665 17.3757L20.0419 16.3512L21.0665 15.3266C21.239 15.1541 21.239 14.8737 21.0665 14.7011Z"
      fill={color}
    />
    <path
      d="M13.8086 8.64031C13.8086 5.53437 11.2742 3 8.1683 3C5.06235 3 2.52799 5.53437 2.52799 8.64031C2.52799 10.5168 3.47703 12.2855 5.03 13.3316C4.50156 13.5257 3.99469 13.7522 3.50938 14.0326C2.09661 14.8522 0.921093 16.0277 0.101468 17.4405C0.00440705 17.613 -0.0279462 17.818 0.0259765 18.0121C0.0798991 18.2062 0.198529 18.368 0.371081 18.465C0.489711 18.5297 0.619124 18.5621 0.748538 18.5621C0.813246 18.5621 0.877953 18.5621 0.94266 18.5405C1.13678 18.4866 1.29855 18.368 1.39561 18.1954C2.07503 17.0091 3.06721 16.0169 4.25351 15.3375C5.41824 14.6689 6.74474 14.3022 8.09281 14.2914H8.23301C9.39774 14.2914 10.584 14.5826 11.6301 15.1003C11.8674 15.2189 12.1478 15.2081 12.3743 15.0787C12.59 14.9601 12.7302 14.7551 12.7625 14.5071C12.7949 14.2159 12.6439 13.9355 12.3851 13.8061C12.0292 13.6228 11.6625 13.4718 11.285 13.3316C12.838 12.2855 13.787 10.5168 13.787 8.64031H13.8086ZM12.3203 8.64031C12.3203 10.8943 10.487 12.7492 8.24379 12.7924H8.11438C5.86041 12.76 4.02704 10.8943 4.02704 8.64031C4.02704 6.38634 5.89276 4.48827 8.17908 4.48827C10.4654 4.48827 12.3311 6.35399 12.3311 8.64031H12.3203Z"
      fill={color}
    />
    <path
      d="M19.4167 11.7678C16.8931 11.7678 14.8333 13.8168 14.8333 16.3512C14.8333 18.8855 16.8823 20.9346 19.4167 20.9346C21.9511 20.9346 24.0001 18.8855 24.0001 16.3512C24.0001 13.8168 21.9511 11.7678 19.4167 11.7678ZM22.8462 16.3512C22.8462 18.2385 21.304 19.7807 19.4167 19.7807C17.5294 19.7807 15.9872 18.2493 15.9872 16.3512C15.9872 14.4531 17.5294 12.9217 19.4167 12.9217C21.304 12.9217 22.8462 14.4639 22.8462 16.3512Z"
      fill={color}
    />
  </>
);
