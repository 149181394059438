export const toSnakeCase = (str: string): string => {
  return str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
};

export const toUrlEncoded = (obj: any): string => {
  return Object.keys(obj)
    .map((k) => encodeURIComponent(toSnakeCase(k)) + '=' + encodeURIComponent(obj[k]))
    .join('&');
};

export const goToHref = (href: string) => {
  if (window.location.href === href || href === window.location.pathname + window.location.search) {
    return;
  }
  window.location.replace(href)
}