/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const RadioOff = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7.07692C9.28106 7.07692 7.07692 9.28106 7.07692 12C7.07692 14.7189 9.28106 16.9231 12 16.9231C14.7189 16.9231 16.9231 14.7189 16.9231 12C16.9231 9.28106 14.7189 7.07692 12 7.07692ZM8.30769 12C8.30769 9.96079 9.96079 8.30769 12 8.30769C14.0392 8.30769 15.6923 9.96079 15.6923 12C15.6923 14.0392 14.0392 15.6923 12 15.6923C9.96079 15.6923 8.30769 14.0392 8.30769 12Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM5.23077 12C5.23077 8.26146 8.26146 5.23077 12 5.23077C15.7385 5.23077 18.7692 8.26146 18.7692 12C18.7692 15.7385 15.7385 18.7692 12 18.7692C8.26146 18.7692 5.23077 15.7385 5.23077 12Z"
      fill={color}
    />
  </>
);
