/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const RelatedContent = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.084 17.6552C9.59623 19.3655 6.16627 19.1148 3.95469 16.9033C1.46042 14.409 1.46042 10.365 3.95469 7.8707C6.16627 5.65912 9.59623 5.40847 12.084 7.11877C14.5717 5.40847 18.0017 5.65912 20.2133 7.8707C22.7076 10.365 22.7076 14.409 20.2133 16.9033C18.0017 19.1148 14.5717 19.3655 12.084 17.6552ZM4.85794 16C2.86253 14.0046 2.86253 10.7694 4.85794 8.77396C6.54197 7.08993 9.10903 6.82714 11.0687 7.98557C10.2822 8.81177 9.75956 9.79686 9.50064 10.8343C9.49057 10.8652 9.4828 10.8972 9.47753 10.93C9.2542 11.8875 9.2542 12.8865 9.47753 13.8439C9.4828 13.8768 9.49057 13.9087 9.50064 13.9397C9.75956 14.9771 10.2822 15.9622 11.0687 16.7884C9.10903 17.9468 6.54197 17.684 4.85794 16ZM10.9909 14.3806C11.24 14.9691 11.6043 15.5203 12.084 16C12.5636 15.5203 12.928 14.9691 13.1771 14.3806H10.9909ZM14.6673 13.9397C14.6774 13.9087 14.6852 13.8768 14.6904 13.844C14.9138 12.8865 14.9138 11.8875 14.6904 10.93C14.6852 10.8972 14.6774 10.8652 14.6673 10.8343C14.4084 9.79688 13.8858 8.81178 13.0993 7.98557C15.0589 6.82713 17.626 7.08993 19.31 8.77396C21.3054 10.7694 21.3054 14.0046 19.31 16C17.626 17.684 15.0589 17.9468 13.0993 16.7884C13.8857 15.9622 14.4084 14.9771 14.6673 13.9397ZM13.5304 13.1032L10.6376 13.1032C10.5707 12.6282 10.5707 12.1457 10.6376 11.6708L13.5304 11.6708C13.5973 12.1457 13.5973 12.6282 13.5304 13.1032ZM10.9909 10.3934L13.1771 10.3934C12.928 9.80491 12.5636 9.25361 12.084 8.77396C11.6043 9.25361 11.24 9.80491 10.9909 10.3934Z"
      fill={color}
    />
  </>
);
