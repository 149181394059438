/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Responsible = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.91435 3.2536C6.44025 3.2536 3.62678 6.09654 3.62678 9.59995C3.62678 11.7829 4.71886 13.7088 6.38185 14.8508C5.81257 15.0591 5.26034 15.3182 4.73195 15.6263C3.15646 16.5447 1.84843 17.8656 0.939167 19.456C0.704345 19.8667 0.843105 20.3926 1.25166 20.6307C1.6612 20.8694 2.18407 20.727 2.41944 20.3153C3.17915 18.9865 4.27182 17.8833 5.58745 17.1163C6.88288 16.3611 8.34961 15.9581 9.84442 15.9459C9.8677 15.9462 9.89102 15.9463 9.91435 15.9463C9.93768 15.9463 9.96097 15.9462 9.98424 15.9459C11.479 15.9581 12.9457 16.3611 14.2412 17.1163C15.5568 17.8833 16.6495 18.9865 17.4092 20.3153C17.6445 20.727 18.1674 20.8694 18.5769 20.6307C18.9855 20.3926 19.1243 19.8667 18.8894 19.456C17.9802 17.8656 16.6721 16.5447 15.0967 15.6263C14.5683 15.3183 14.0161 15.0591 13.4468 14.8508C15.1098 13.7088 16.2019 11.7829 16.2019 9.59995C16.2019 6.09654 13.3885 3.2536 9.91435 3.2536ZM5.33513 9.59995C5.33513 7.04409 7.38689 4.97501 9.91435 4.97501C12.4418 4.97501 14.4936 7.04409 14.4936 9.59995C14.4936 12.1339 12.4769 14.1894 9.97937 14.2244L9.9143 14.2242L9.84925 14.2244C7.3518 14.1893 5.33513 12.1338 5.33513 9.59995Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4627 7.64869C19.6306 7.35139 18.8468 7.28315 17.9853 7.45927C17.6824 7.5212 17.2822 7.75302 17.2822 8.21533C17.2822 8.6933 17.6953 9.05935 18.1675 9.01251C18.9453 8.93534 19.5961 8.92318 20.3534 9.25867C20.7569 9.47321 21.1062 9.77075 21.3769 10.1298C21.6487 10.4905 21.8346 10.9044 21.9217 11.3419C22.0087 11.7794 21.9948 12.23 21.881 12.6618C21.7672 13.0936 21.5561 13.4961 21.2624 13.8406C20.9687 14.185 20.5996 14.4629 20.1813 14.6539C19.9893 14.7417 19.5897 14.8163 19.1543 14.8681C18.7273 14.9189 18.2965 14.9446 18.061 14.9448C17.8567 14.9448 17.6596 15.0233 17.5134 15.1648C17.367 15.3064 17.2836 15.4998 17.2836 15.7027C17.2836 15.9056 17.367 16.099 17.5134 16.2406C17.6596 16.3821 17.8569 16.4606 18.0612 16.4606C18.5513 16.46 19.2344 16.5189 19.9042 16.6356C20.5775 16.7529 21.2188 16.9254 21.6352 17.144C22.492 17.5935 23.2183 18.2435 23.7481 19.0335C23.8617 19.2019 24.0386 19.3182 24.2388 19.359C24.4389 19.3998 24.6479 19.3621 24.8202 19.2534C24.9927 19.1445 25.115 18.9727 25.1581 18.7745C25.2012 18.5762 25.161 18.3699 25.0475 18.2013C24.2394 16.9976 23.0782 16.0599 21.7178 15.5055C22.5385 14.8879 23.128 14.0207 23.3866 13.0374C23.6734 11.9469 23.5325 10.7924 22.9918 9.79701C22.4512 8.80182 21.5498 8.03707 20.4627 7.64869Z"
      fill={color}
    />
  </>
);
