/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ClassRoom = ({ color = '#191919' }: IconProps) => (
  <path
    d="M1.6 16.4V5.20001C1.6 4.77566 1.76857 4.36869 2.06863 4.06864C2.36869 3.76858 2.77565 3.60001 3.2 3.60001H20.8C21.2243 3.60001 21.6313 3.76858 21.9314 4.06864C22.2314 4.36869 22.4 4.77566 22.4 5.20001V16.4C22.4 16.6122 22.3157 16.8157 22.1657 16.9657C22.0157 17.1157 21.8122 17.2 21.6 17.2C21.3878 17.2 21.1843 17.1157 21.0343 16.9657C20.8843 16.8157 20.8 16.6122 20.8 16.4V5.20001H3.2V16.4C3.2 16.6122 3.11571 16.8157 2.96569 16.9657C2.81566 17.1157 2.61217 17.2 2.4 17.2C2.18783 17.2 1.98434 17.1157 1.83431 16.9657C1.68429 16.8157 1.6 16.6122 1.6 16.4ZM24 19.6C24 19.8122 23.9157 20.0157 23.7657 20.1657C23.6157 20.3157 23.4122 20.4 23.2 20.4H0.8C0.587827 20.4 0.384344 20.3157 0.234315 20.1657C0.0842854 20.0157 0 19.8122 0 19.6C0 19.3878 0.0842854 19.1843 0.234315 19.0343C0.384344 18.8843 0.587827 18.8 0.8 18.8H10.4V16.4C10.4 16.1878 10.4843 15.9843 10.6343 15.8343C10.7843 15.6843 10.9878 15.6 11.2 15.6H17.6C17.8122 15.6 18.0157 15.6843 18.1657 15.8343C18.3157 15.9843 18.4 16.1878 18.4 16.4V18.8H23.2C23.4122 18.8 23.6157 18.8843 23.7657 19.0343C23.9157 19.1843 24 19.3878 24 19.6ZM12 18.8H16.8V17.2H12V18.8Z"
    fill={color}
  />
);
