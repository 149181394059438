/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Search = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8461 2C5.96055 2 2 5.96055 2 10.8461C2 15.7317 5.96055 19.6923 10.8461 19.6923C13.012 19.6923 14.996 18.9139 16.5338 17.6217L20.6868 21.7747C20.9872 22.0751 21.4743 22.0751 21.7747 21.7747C22.0751 21.4743 22.0751 20.9872 21.7747 20.6868L17.6217 16.5338C18.9139 14.996 19.6923 13.012 19.6923 10.8461C19.6923 5.96055 15.7317 2 10.8461 2ZM3.53846 10.8461C3.53846 6.81022 6.81022 3.53846 10.8461 3.53846C14.8821 3.53846 18.1538 6.81022 18.1538 10.8461C18.1538 14.8821 14.8821 18.1538 10.8461 18.1538C6.81022 18.1538 3.53846 14.8821 3.53846 10.8461Z"
      fill={color}
    />
  </>
);
