/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Bold = ({ color = '#191919' }: IconProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0 0.952381C0 0.426395 0.426395 0 0.952381 0H10C11.3892 0 12.7216 0.551869 13.7039 1.5342C14.6862 2.51654 15.2381 3.84887 15.2381 5.2381C15.2381 6.62733 14.6862 7.95965 13.7039 8.94199C13.6799 8.96594 13.6558 8.98964 13.6314 9.01309C14.3119 9.29741 14.9375 9.71347 15.4692 10.2451C16.5408 11.3167 17.1429 12.7702 17.1429 14.2857C17.1429 15.8012 16.5408 17.2547 15.4692 18.3263C14.3975 19.398 12.9441 20 11.4286 20H0.952381C0.426395 20 0 19.5736 0 19.0476V0.952381ZM1.90476 10.4762V18.0952H11.4286C12.4389 18.0952 13.4079 17.6939 14.1223 16.9795C14.8367 16.265 15.2381 15.2961 15.2381 14.2857C15.2381 13.2754 14.8367 12.3064 14.1223 11.592C13.4079 10.8776 12.4389 10.4762 11.4286 10.4762H1.90476ZM10 8.57143H1.90476V1.90476H10C10.8841 1.90476 11.7319 2.25595 12.357 2.88107C12.9821 3.50619 13.3333 4.35404 13.3333 5.2381C13.3333 6.12215 12.9821 6.97 12.357 7.59512C11.7319 8.22024 10.8841 8.57143 10 8.57143Z"
    fill={color}
  />
);
