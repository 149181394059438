/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Classes = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M5.99296 4.91144C5.56595 4.80196 5.1166 4.81626 4.69741 4.95267C4.27822 5.08908 3.90652 5.34197 3.62572 5.68179C3.34492 6.02161 3.16663 6.43433 3.11167 6.87171C3.05672 7.3091 3.12738 7.75309 3.31539 8.15181C3.5034 8.55053 3.80099 8.88751 4.17341 9.12338C4.54582 9.35925 4.97767 9.48427 5.41849 9.48382C5.43885 9.4838 5.45902 9.48456 5.47898 9.48609C5.49178 9.48706 5.50449 9.48835 5.5171 9.48994C5.89909 9.53804 6.19424 9.8644 6.19358 10.2593C6.19287 10.6869 5.84567 11.0329 5.4181 11.0322C4.63704 11.0309 3.86648 11.2121 3.16788 11.5614C2.46927 11.9107 1.86196 12.4184 1.39437 13.0441C1.1384 13.3866 0.653249 13.4567 0.31076 13.2007C-0.0317281 12.9448 -0.101863 12.4596 0.154109 12.1171C0.766129 11.2982 1.56103 10.6337 2.47542 10.1765C2.58498 10.1217 2.69589 10.0701 2.80802 10.0217C2.43572 9.68222 2.13162 9.27181 1.91489 8.81219C1.60154 8.14766 1.48378 7.40767 1.57537 6.67869C1.66696 5.94972 1.96411 5.26186 2.43211 4.6955C2.9001 4.12913 3.51962 3.70764 4.21826 3.48029C4.9169 3.25293 5.66582 3.2291 6.37751 3.41157C7.0892 3.59403 7.73426 3.97527 8.23732 4.51073C8.74039 5.04619 9.08068 5.71376 9.21844 6.43544C9.29861 6.85543 9.02313 7.26089 8.60314 7.34106C8.18315 7.42123 7.77769 7.14575 7.69752 6.72576C7.61486 6.29275 7.41069 5.89221 7.10885 5.57094C6.80701 5.24966 6.41997 5.02092 5.99296 4.91144Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7.93544C9.43453 7.93544 7.35483 10.0151 7.35483 12.5806C7.35483 14.0866 8.07152 15.4252 9.18245 16.274C8.88002 16.4075 8.58636 16.5628 8.30388 16.7392C7.19537 17.4315 6.30359 18.4213 5.73013 19.5957C5.54252 19.9799 5.7019 20.4435 6.08612 20.6311C6.47034 20.8187 6.9339 20.6593 7.12151 20.2751C7.5677 19.3613 8.26158 18.5912 9.1241 18.0525C9.98661 17.5138 10.9831 17.2282 12 17.2282C13.0169 17.2282 14.0134 17.5138 14.8759 18.0525C15.7384 18.5912 16.4323 19.3613 16.8785 20.2751C17.0661 20.6593 17.5297 20.8187 17.9139 20.6311C18.2981 20.4435 18.4575 19.9799 18.2699 19.5957C17.6964 18.4213 16.8046 17.4315 15.6961 16.7392C15.4136 16.5628 15.12 16.4075 14.8175 16.274C15.9284 15.4252 16.6451 14.0866 16.6451 12.5806C16.6451 10.0151 14.5654 7.93544 12 7.93544ZM8.90321 12.5806C8.90321 10.8703 10.2897 9.48382 12 9.48382C13.7103 9.48382 15.0967 10.8703 15.0967 12.5806C15.0967 14.2909 13.7103 15.6774 12 15.6774C10.2897 15.6774 8.90321 14.2909 8.90321 12.5806Z"
      fill={color}
    />
    <path
      d="M18.5819 11.0322C19.363 11.0309 20.1335 11.2121 20.8321 11.5614C21.5307 11.9107 22.138 12.4184 22.6056 13.0441C22.8616 13.3866 23.3468 13.4567 23.6892 13.2007C24.0317 12.9448 24.1019 12.4596 23.8459 12.1171C23.2339 11.2982 22.439 10.6337 21.5246 10.1765C21.415 10.1217 21.304 10.0701 21.1918 10.0216C21.5641 9.68217 21.8682 9.27179 22.0849 8.81219C22.3983 8.14766 22.516 7.40767 22.4244 6.67869C22.3328 5.94972 22.0357 5.26186 21.5677 4.6955C21.0997 4.12913 20.4802 3.70764 19.7815 3.48029C19.0829 3.25293 18.334 3.2291 17.6223 3.41157C16.9106 3.59403 16.2655 3.97527 15.7625 4.51073C15.2594 5.04619 14.9191 5.71376 14.7814 6.43544C14.7012 6.85543 14.9767 7.26089 15.3967 7.34106C15.8167 7.42123 16.2221 7.14575 16.3023 6.72576C16.3849 6.29275 16.5891 5.89221 16.891 5.57094C17.1928 5.24966 17.5798 5.02092 18.0068 4.91144C18.4339 4.80196 18.8832 4.81626 19.3024 4.95267C19.7216 5.08908 20.0933 5.34198 20.3741 5.68179C20.6549 6.02161 20.8332 6.43433 20.8881 6.87171C20.9431 7.3091 20.8724 7.75309 20.6844 8.15181C20.4964 8.55053 20.1988 8.88751 19.8264 9.12338C19.454 9.35925 19.0221 9.48427 18.5813 9.48382C18.1537 9.48339 17.8068 9.82965 17.8063 10.2572C17.806 10.6321 18.0721 10.945 18.4259 11.0167C18.4756 11.0268 18.5271 11.0321 18.5797 11.0322L18.5819 11.0322Z"
      fill={color}
    />
  </>
);
