/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ExternalEvaluations = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.20878 1.13753L2.20892 6.13739C2.14261 6.20377 2.09003 6.28257 2.05419 6.36927C2.01834 6.45598 1.99993 6.5489 2 6.64273V18.071C2 18.4499 2.15051 18.8132 2.41841 19.0811C2.68631 19.349 3.04966 19.4995 3.42853 19.4995H5.02556C5.215 19.4995 5.39667 19.4243 5.53062 19.2903C5.98059 18.8403 5.6619 18.071 5.02556 18.071H3.42853V7.357H7.71413C7.90356 7.357 8.08524 7.28174 8.21919 7.14779C8.35314 7.01384 8.42839 6.83216 8.42839 6.64273V2.35714H16.2853V7.27559C16.2853 7.91193 17.0547 8.23061 17.5046 7.78065C17.6386 7.6467 17.7138 7.46502 17.7138 7.27558V2.35714C17.7138 1.97827 17.5633 1.61491 17.2954 1.34701C17.0275 1.07911 16.6642 0.928604 16.2853 0.928604H7.71413C7.6203 0.928532 7.52738 0.946945 7.44067 0.982792C7.35396 1.01864 7.27516 1.07122 7.20878 1.13753ZM4.43832 5.92847L6.99986 3.36693V5.92847H4.43832Z"
      fill={color}
    />
    <path
      d="M10.5044 14.3269C11.0067 13.8948 10.7014 13.0711 10.0389 13.0711C8.604 13.0711 6.99986 13.0711 6.99986 13.0711C6.81043 13.0711 6.62875 13.1464 6.4948 13.2803C6.36085 13.4143 6.28559 13.596 6.28559 13.7854C6.28559 13.9748 6.36085 14.1565 6.4948 14.2904C6.62875 14.4244 6.81043 14.4997 6.99986 14.4997H10.0386C10.2095 14.4997 10.3748 14.4384 10.5044 14.3269Z"
      fill={color}
    />
    <path
      d="M6.28559 10.9283C6.28559 11.1178 6.36085 11.2994 6.4948 11.4334C6.62875 11.5673 6.81043 11.6426 6.99986 11.6426H12.714C12.9034 11.6426 13.0851 11.5673 13.219 11.4334C13.353 11.2994 13.4283 11.1178 13.4283 10.9283C13.4283 10.7389 13.353 10.5572 13.219 10.4233C13.0851 10.2893 12.9034 10.2141 12.714 10.2141H6.99986C6.81043 10.2141 6.62875 10.2893 6.4948 10.4233C6.36085 10.5572 6.28559 10.7389 6.28559 10.9283Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9335 10.4235L21.7905 13.2806C21.8569 13.3469 21.9096 13.4257 21.9456 13.5124C21.9815 13.5991 22 13.6921 22 13.7859C22 13.8798 21.9815 13.9728 21.9456 14.0595C21.9096 14.1462 21.8569 14.225 21.7905 14.2913L13.2193 22.8625C13.1529 22.9288 13.0742 22.9814 12.9874 23.0172C12.9007 23.0531 12.8078 23.0715 12.714 23.0714H9.85692C9.66749 23.0714 9.48581 22.9962 9.35186 22.8622C9.21791 22.7282 9.14266 22.5466 9.14266 22.3571V19.5001C9.14258 19.4062 9.161 19.3133 9.19684 19.2266C9.23269 19.1399 9.28527 19.0611 9.35158 18.9947L17.9228 10.4235C17.9891 10.3571 18.0679 10.3044 18.1546 10.2685C18.2413 10.2326 18.3342 10.2141 18.4281 10.2141C18.522 10.2141 18.6149 10.2326 18.7016 10.2685C18.7883 10.3044 18.8671 10.3571 18.9335 10.4235ZM10.5712 21.6429H12.4185L18.1326 15.9287L16.2853 14.0815L10.5712 19.7956V21.6429ZM17.2951 13.0717L19.1424 14.919L20.2754 13.7859L18.4281 11.9387L17.2951 13.0717Z"
      fill={color}
    />
  </>
);
