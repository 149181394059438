/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const DigitalTest = ({ color = '#191919' }: IconProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12.8532 18.3846H19.7763C21.0508 18.3846 22.084 17.3514 22.084 16.0769V5.30769C22.084 4.03319 21.0508 3 19.7763 3H4.39168C3.11717 3 2.08398 4.03319 2.08398 5.30769V16.0769C2.08398 17.3514 3.11717 18.3846 4.39168 18.3846H11.3148V19.9231H9.00706C8.58223 19.9231 8.23783 20.2675 8.23783 20.6923C8.23783 21.1171 8.58223 21.4615 9.00706 21.4615H15.1609C15.5857 21.4615 15.9301 21.1171 15.9301 20.6923C15.9301 20.2675 15.5857 19.9231 15.1609 19.9231H12.8532V18.3846ZM20.5455 16.0769C20.5455 16.5018 20.2011 16.8462 19.7763 16.8462H4.39168C3.96684 16.8462 3.62245 16.5018 3.62245 16.0769V14.5385H20.5455V16.0769ZM3.62245 5.30769V13H20.5455V5.30769C20.5455 4.88286 20.2011 4.53846 19.7763 4.53846H4.39168C3.96684 4.53846 3.62245 4.88286 3.62245 5.30769Z"
    fill={color}
  />
);
