/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Cut = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.43472 8.55733C8.5844 8.1784 8.66663 7.76546 8.66663 7.33332C8.66663 5.49238 7.17426 4 5.33332 4C3.49238 4 2 5.49238 2 7.33332C2 9.17426 3.49238 10.6666 5.33332 10.6666C6.20748 10.6666 7.00305 10.3301 7.59756 9.77959L11.0586 12.1482L7.59754 14.5166C7.00303 13.9661 6.20747 13.6296 5.33332 13.6296C3.49238 13.6296 2 15.122 2 16.9629C2 18.8038 3.49238 20.2962 5.33332 20.2962C7.17426 20.2962 8.66663 18.8038 8.66663 16.9629C8.66663 16.5307 8.58439 16.1178 8.43471 15.7389L12.3702 13.0458L20.8409 18.8427C21.1785 19.0737 21.6394 18.9873 21.8705 18.6497C22.1015 18.3121 22.0151 17.8511 21.6775 17.6201L12.8076 11.5499C12.7953 11.5409 12.7827 11.5323 12.7699 11.5241L8.43472 8.55733ZM5.33332 5.48147C4.31057 5.48147 3.48147 6.31057 3.48147 7.33332C3.48147 8.35606 4.31057 9.18516 5.33332 9.18516C5.95436 9.18516 6.50399 8.87945 6.83995 8.41031C6.84663 8.39938 6.85362 8.38858 6.86093 8.37789C6.86835 8.36705 6.876 8.35648 6.88388 8.34616C7.07439 8.0551 7.18516 7.70715 7.18516 7.33332C7.18516 6.31057 6.35606 5.48147 5.33332 5.48147ZM5.33332 15.1111C4.31057 15.1111 3.48147 15.9402 3.48147 16.9629C3.48147 17.9856 4.31057 18.8147 5.33332 18.8147C6.35606 18.8147 7.18516 17.9856 7.18516 16.9629C7.18516 16.5889 7.07428 16.2408 6.8836 15.9496C6.87582 15.9394 6.86825 15.929 6.86092 15.9182C6.8537 15.9077 6.84679 15.897 6.84019 15.8862C6.50426 15.4169 5.95451 15.1111 5.33332 15.1111Z"
      fill={color}
    />
    <path
      d="M21.8705 5.64661C22.1015 5.98429 22.015 6.44525 21.6773 6.67619L15.4088 10.9632C15.0711 11.1941 14.6102 11.1076 14.3792 10.7699C14.1483 10.4323 14.2348 9.9713 14.5725 9.74036L20.841 5.45334C21.1786 5.2224 21.6396 5.30893 21.8705 5.64661Z"
      fill={color}
    />
  </>
);
