/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const NotesHighlights = ({ color = '#191919' }: IconProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16.1926 21.0813C19.6212 19.4958 22 16.0257 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 16.0238 4.37661 19.4926 7.80269 21.0791C7.82262 21.0895 7.84314 21.099 7.86419 21.1074C9.12463 21.6807 10.525 22 12 22C13.4765 22 14.8782 21.68 16.1396 21.1056C16.1577 21.0983 16.1754 21.0902 16.1926 21.0813ZM12 3.33343C7.21359 3.33343 3.33343 7.21359 3.33343 12C3.33343 15.1151 4.97695 17.8463 7.44417 19.3739V14.3336C7.44417 13.9505 7.59635 13.5831 7.86725 13.3122C8.13814 13.0413 8.50555 12.8891 8.88866 12.8891H8.99979V8.88899C8.99979 8.63646 9.14246 8.4056 9.36834 8.29266L14.035 5.95934C14.2417 5.856 14.4871 5.86705 14.6837 5.98853C14.8802 6.11001 14.9999 6.3246 14.9999 6.55567V12.8891H15.1108C15.4939 12.8891 15.8614 13.0413 16.1323 13.3122C16.4031 13.5831 16.5553 13.9505 16.5553 14.3336V19.3742C19.0228 17.8467 20.6666 15.1153 20.6666 12C20.6666 7.21359 16.7864 3.33343 12 3.33343ZM15.2219 20.0479V14.3336C15.2219 14.3042 15.2102 14.2759 15.1894 14.2551C15.1685 14.2343 15.1403 14.2226 15.1108 14.2226H8.88866C8.8592 14.2226 8.83095 14.2343 8.81013 14.2551C8.7893 14.2759 8.7776 14.3042 8.7776 14.3336V20.0477C9.77373 20.4469 10.8612 20.6666 12 20.6666C13.1386 20.6666 14.2259 20.447 15.2219 20.0479ZM13.6664 12.8891V7.63444L10.3332 9.30104V12.8891H13.6664Z"
    fill={color}
  />
);
