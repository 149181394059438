/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ImageFullScreen = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M2.00003 5.11111C2.00003 5.72476 2.49749 6.22222 3.11113 6.22222H20.8889C21.5025 6.22222 22 5.72476 22 5.11111C22 4.49746 21.5025 4 20.8889 4H3.11114C2.49749 4 2.00003 4.49746 2.00003 5.11111ZM2.00003 18.4444C2.00003 17.8308 2.49749 17.3333 3.11113 17.3333H20.8889C21.5025 17.3333 22 17.8308 22 18.4444C22 19.0581 21.5025 19.5555 20.8889 19.5555H3.11114C2.49749 19.5555 2.00003 19.0581 2.00003 18.4444Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3333 15.111C21.2538 15.111 22 14.3648 22 13.4444V10.111C22 9.19063 21.2538 8.44437 20.3333 8.44437H3.66666C2.74618 8.44437 2 9.19063 2 10.111V13.4444C2 14.3648 2.74618 15.111 3.66666 15.111H20.3333ZM20.3333 10.111H3.66666V13.4385L20.3333 13.4444V10.111Z"
      fill={color}
    />
  </>
);
