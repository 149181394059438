/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Reload = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M8.53184 4.20558C9.9922 3.60103 11.5991 3.44318 13.1491 3.752C14.6992 4.06082 16.1229 4.82244 17.24 5.94051L19.268 7.96253H16.407C15.9657 7.96253 15.608 8.32026 15.608 8.76153C15.608 9.20281 15.9657 9.56053 16.407 9.56053H21.201C21.3199 9.56053 21.4328 9.53456 21.5342 9.48797C21.6026 9.45659 21.6675 9.41502 21.7267 9.36328C21.7555 9.33804 21.7826 9.31075 21.8076 9.28164C21.8573 9.2237 21.8975 9.16033 21.9279 9.09362C21.9742 8.99249 22 8.88002 22 8.76153V3.96755C22 3.52627 21.6423 3.16855 21.201 3.16855C20.7597 3.16855 20.402 3.52627 20.402 3.96755V6.8366L18.37 4.81059C17.0295 3.46914 15.3213 2.55536 13.4614 2.18481C11.6013 1.81422 9.67305 2.00364 7.92062 2.7291C6.16819 3.45456 4.67029 4.68347 3.61641 6.26037C2.56252 7.83727 2 9.69132 2 11.588C2 13.4846 2.56252 15.3387 3.61641 16.9156C4.67029 18.4925 6.16819 19.7214 7.92062 20.4468C9.67305 21.1723 11.6013 21.3617 13.4614 20.9911C15.3215 20.6206 17.0299 19.7066 18.3705 18.3649C18.6824 18.0528 18.6821 17.5469 18.37 17.235C18.0578 16.9231 17.5519 16.9233 17.24 17.2354C16.1229 18.3535 14.6992 19.1151 13.1491 19.4239C11.5991 19.7328 9.9922 19.5749 8.53184 18.9704C7.07149 18.3658 5.82324 17.3417 4.945 16.0276C4.06676 14.7136 3.59799 13.1685 3.59799 11.588C3.59799 10.0074 4.06676 8.46239 4.945 7.1483C5.82324 5.83422 7.07149 4.81013 8.53184 4.20558Z"
      fill={color}
    />
  </>
);
