/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Questions = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M10.6223 6.27412C11.2801 6.00164 12.004 5.93035 12.7023 6.06926C13.4006 6.20817 14.0421 6.55103 14.5455 7.0545C15.049 7.55797 15.3919 8.19942 15.5308 8.89775C15.6697 9.59608 15.5984 10.3199 15.3259 10.9777C15.0534 11.6355 14.592 12.1978 14 12.5934C13.633 12.8386 13.226 13.0129 12.8 13.11V13.2001C12.8 13.6419 12.4418 14.0001 12 14.0001C11.5581 14.0001 11.2 13.6419 11.2 13.2001V12.4001C11.2 12.1879 11.2843 11.9844 11.4343 11.8344C11.5843 11.6844 11.7878 11.6001 12 11.6001C12.3955 11.6001 12.7822 11.4828 13.1111 11.263C13.44 11.0432 13.6963 10.7309 13.8477 10.3654C13.9991 9.99999 14.0387 9.59785 13.9615 9.20989C13.8844 8.82193 13.6939 8.46557 13.4142 8.18587C13.1345 7.90616 12.7781 7.71568 12.3901 7.63851C12.0022 7.56134 11.6001 7.60095 11.2346 7.75232C10.8692 7.9037 10.5568 8.16004 10.337 8.48894C10.1173 8.81783 9.99997 9.20451 9.99997 9.60007C9.99997 10.0419 9.6418 10.4001 9.19998 10.4001C8.75815 10.4001 8.39998 10.0419 8.39998 9.60007C8.39998 8.88806 8.61111 8.19204 9.00669 7.60003C9.40226 7.00801 9.9645 6.54659 10.6223 6.27412Z"
      fill={color}
    />
    <path
      d="M12 18C12.6627 18 13.2 17.4628 13.2 16.8C13.2 16.1373 12.6627 15.6001 12 15.6001C11.3372 15.6001 10.8 16.1373 10.8 16.8C10.8 17.4628 11.3372 18 12 18Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.92899 4.92892C6.80435 3.05356 9.34788 2 12 2C13.3132 2 14.6136 2.25866 15.8269 2.7612C17.0401 3.26375 18.1425 4.00034 19.0711 4.92892C19.9997 5.8575 20.7363 6.95989 21.2388 8.17314C21.7413 9.3864 22 10.6868 22 12C22 13.3132 21.7413 14.6135 21.2388 15.8268C20.7363 17.04 19.9997 18.1424 19.0711 19.071C18.1425 19.9996 17.0401 20.7362 15.8269 21.2387C14.6136 21.7413 13.3132 21.9999 12 21.9999H3.57442C3.36821 22.0018 3.16364 21.9628 2.97257 21.8852C2.77957 21.8067 2.60423 21.6904 2.45691 21.5431C2.3096 21.3958 2.19328 21.2204 2.11483 21.0274C2.03716 20.8364 1.99816 20.6318 2.00007 20.4256V12C2.00007 9.34781 3.05363 6.80428 4.92899 4.92892ZM6.06036 6.06029C7.63566 4.48499 9.77222 3.59999 12 3.59999C13.1031 3.59999 14.1954 3.81727 15.2146 4.2394C16.2337 4.66154 17.1597 5.28028 17.9397 6.06029C18.7197 6.8403 19.3385 7.7663 19.7606 8.78544C20.1827 9.80457 20.4 10.8969 20.4 12C20.4 13.1031 20.1827 14.1954 19.7606 15.2145C19.3385 16.2336 18.7197 17.1596 17.9397 17.9396C17.1597 18.7197 16.2337 19.3384 15.2146 19.7605C14.1954 20.1827 13.1031 20.3999 12 20.3999H3.60006V12C3.60006 9.77216 4.48506 7.63559 6.06036 6.06029Z"
      fill={color}
    />
  </>
);
