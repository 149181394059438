import { JWTIDToken } from '../auth/AuthService'

export interface IUser {
  nome: string;
  email: string
}

export class User implements IUser {
  nome: string

  email: string

  isNull = false

  constructor(
    name: string,
    email: string
  ) {
    this.nome = name
    this.email = email
  }

  static createFromIDToken(idToken: JWTIDToken) {
    return new User(
      idToken.name,
      idToken.email
    )
  }

  static createNullUser() {
    const user = new User(
      '',
      '',
    )

    user.isNull = true
    return user
  }
}
