/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Image = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M15.8462 9.76923C15.8462 10.4065 15.3296 10.9231 14.6923 10.9231C14.0551 10.9231 13.5385 10.4065 13.5385 9.76923C13.5385 9.13198 14.0551 8.61538 14.6923 8.61538C15.3296 8.61538 15.8462 9.13198 15.8462 9.76923Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5.53846C2 4.68879 2.68879 4 3.53846 4H20.4615C21.3112 4 22 4.68879 22 5.53846V19.3846C22 20.2343 21.3112 20.9231 20.4615 20.9231H3.53846C2.68879 20.9231 2 20.2343 2 19.3846V5.53846ZM20.4615 5.53846V15.989L17.709 13.2365C17.5664 13.0923 17.3967 12.9778 17.2096 12.8994C17.0214 12.8206 16.8194 12.78 16.6154 12.78C16.4114 12.78 16.2094 12.8206 16.0212 12.8994C15.8341 12.9778 15.6644 13.0923 15.5217 13.2365L13.5426 15.2156L13.5385 15.2198L9.24756 10.9289C9.10491 10.7847 8.93515 10.6701 8.74803 10.5917C8.55984 10.5129 8.35786 10.4724 8.15385 10.4724C7.94983 10.4724 7.74785 10.5129 7.55967 10.5917C7.37253 10.6701 7.20275 10.7847 7.0601 10.9289L3.53846 14.4505V5.53846H20.4615ZM3.53846 19.3846V16.6262L8.14971 12.015L8.15383 12.0108L12.4447 16.3017C12.5874 16.4459 12.7572 16.5605 12.9443 16.6389C13.1325 16.7177 13.3344 16.7582 13.5385 16.7582C13.7425 16.7582 13.9445 16.7177 14.1326 16.6389C14.3198 16.5605 14.4895 16.4459 14.6322 16.3017L14.6346 16.2993L16.6113 14.3227L16.6154 14.3185L20.4615 18.1647V19.3846H3.53846Z"
      fill={color}
    />
  </>
);
