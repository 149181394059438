/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const SingleInvite = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M18.6388 10.7591C19.0605 10.7591 19.4023 10.4172 19.4023 9.99557V5.38896C19.4023 4.57899 19.0806 3.80221 18.5079 3.22948C17.9351 2.65675 17.1583 2.33499 16.3484 2.33499H5.6595C4.84954 2.33499 4.07275 2.65675 3.50002 3.22948C2.92729 3.80221 2.60553 4.57899 2.60553 5.38896L2.60553 10.1535C1.76221 10.1535 1.07855 10.8372 1.07855 11.6805V19.989C1.07855 20.394 1.23943 20.7824 1.5258 21.0687C1.81216 21.3551 2.20055 21.516 2.60553 21.516H9.82898C10.2506 21.516 10.5925 21.1741 10.5925 20.7525C10.5925 20.3308 10.2506 19.989 9.82898 19.989H2.60553V11.5457L8.89479 16.0312C9.02428 16.1237 9.17944 16.1734 9.33857 16.1734H9.82803C10.2502 16.1734 10.5925 15.8312 10.5925 15.409C10.5925 14.9868 10.2502 14.6445 9.82803 14.6445H9.58289L4.13252 10.7577L4.13252 5.38896C4.13252 4.98398 4.29339 4.59558 4.57976 4.30922C4.86612 4.02285 5.25452 3.86197 5.6595 3.86197L16.3484 3.86197C16.7534 3.86197 17.1417 4.02285 17.4281 4.30922C17.7145 4.59558 17.8754 4.98397 17.8754 5.38896L17.8754 9.99557C17.8754 10.4172 18.2172 10.7591 18.6388 10.7591Z"
      fill={color}
    />
    <path
      d="M14.0579 8.39026L7.94997 8.39026C7.74748 8.39026 7.55328 8.30982 7.4101 8.16664C7.26692 8.02346 7.18648 7.82926 7.18648 7.62677C7.18648 7.42428 7.26692 7.23008 7.4101 7.0869C7.55328 6.94372 7.74748 6.86328 7.94997 6.86328L14.0579 6.86328C14.2604 6.86328 14.4546 6.94372 14.5978 7.0869C14.741 7.23008 14.8214 7.42428 14.8214 7.62677C14.8214 7.82926 14.741 8.02346 14.5978 8.16664C14.4546 8.30982 14.2604 8.39026 14.0579 8.39026Z"
      fill={color}
    />
    <path
      d="M7.4101 10.1409C7.26692 10.284 7.18648 10.4782 7.18648 10.6807C7.18648 10.8832 7.26692 11.0774 7.4101 11.2206C7.55328 11.3638 7.74748 11.4442 7.94997 11.4442L14.0579 11.4442C14.2604 11.4442 14.4546 11.3638 14.5978 11.2206C14.741 11.0774 14.8214 10.8832 14.8214 10.6807C14.8214 10.4782 14.741 10.284 14.5978 10.1409C14.4546 9.99768 14.2604 9.91724 14.0579 9.91724L7.94997 9.91724C7.74748 9.91724 7.55328 9.99768 7.4101 10.1409Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6039 13.4269C16.943 13.4269 15.5965 14.7733 15.5965 16.4343C15.5965 17.4228 16.0736 18.2998 16.8094 18.8478C16.5818 18.9371 16.3604 19.0435 16.1472 19.1667C15.4002 19.5979 14.78 20.2182 14.3489 20.9652C14.2201 21.1883 14.2965 21.4736 14.5197 21.6024C14.7428 21.7312 15.0281 21.6548 15.1569 21.4316C15.5062 20.8265 16.0086 20.324 16.6137 19.9746C17.2094 19.6306 17.884 19.447 18.5716 19.4414L18.6043 19.4416L18.6358 19.4414C19.3233 19.447 19.9983 19.6306 20.594 19.9746C21.1991 20.324 21.7016 20.8265 22.0509 21.4316C22.1797 21.6548 22.4649 21.7312 22.6881 21.6024C22.9112 21.4736 22.9877 21.1883 22.8589 20.9652C22.4277 20.2182 21.8075 19.5979 21.0606 19.1667C20.8474 19.0435 20.626 18.9371 20.3984 18.8478C21.1342 18.2998 21.6112 17.4228 21.6112 16.4343C21.6112 14.7733 20.2648 13.4269 18.6039 13.4269ZM16.5295 16.4343C16.5295 15.2886 17.4582 14.3599 18.6039 14.3599C19.7495 14.3599 20.6783 15.2886 20.6783 16.4343C20.6783 17.5699 19.7657 18.4924 18.634 18.5084L18.6036 18.5083L18.5738 18.5084C17.4421 18.4924 16.5295 17.5699 16.5295 16.4343Z"
      fill={color}
    />
  </>
);
