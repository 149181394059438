import React from 'react';
import Select, { StylesConfig, SingleValue } from 'react-select';

export interface OptionType {
  value: number;
  label: string;
}

const customStyles: StylesConfig<OptionType> = {
  control: (provided) => ({
    ...provided,
    width: '100%',
    height: '40px',
    borderRadius: '0.375rem', 
    borderColor: '#d1d5db', 
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)', 
    padding: '0 0.5rem', 
    fontSize: '1rem', 
    backgroundColor: 'white', 
    '&:hover': {
      borderColor: '#9CA3AF', 
    },
    '&:focus-within': {
      borderColor: '#3B82F6', 
      boxShadow: '0 0 0 1px #3B82F6', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#00a988' : state.isFocused ? '#6ee7b7' : undefined,
    color: state.isSelected ? 'white' : '#374151',
    padding: '0.5rem 1rem', 
    fontSize: '1rem', 
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#374151',
    fontSize: '1rem', 
  }),
};

export interface CustomSelectProps {
  options: any[];
  isPagination?: boolean;
  value: any;
  onChange: (value: number) => void;
  label?: string;
  placeholder?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ options, value, isPagination, onChange, label, placeholder }) => {
  const formattedOptions: OptionType[] = options.map(option => ({
    value: isPagination ? option : option.id,
    label: isPagination ? `${option} itens por página` : `${option.nome}`
  }));

  return (
    <div className="input-container">
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}

      <Select<OptionType>
        options={formattedOptions}
        value={formattedOptions.find(option => option.value === value)}
        onChange={(option: SingleValue<OptionType>) => onChange(option ? option.value : value)}
        styles={customStyles}
        menuPlacement={isPagination ? 'top' : 'auto'}
        placeholder={placeholder}
      />
    </div>
  );
}

export default CustomSelect;
