import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../services/api';
import { useEffect, useState } from "react";
import { Pagination } from "../components/Pagination";
import Table, { TableColumn } from '../components/Table';
import ImageViewer from '../components/ImageViewer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEye, faClose } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../components/Button';
import { Grid } from '../components/Grid';
import Modal from 'react-modal';
import SearchSmartShareForm from '../components/SearchSmartShareForm';
import { TYPE_FILE_ENUM } from '../types';
interface Data {
  Nome: string;
  DsDocumento: string;
  Matricula: string;
  DtEfetivacao: string;
  CdDocumento: string;
  TipoArquivo: string
}

interface ApiResponse {
  body: string;
  headers: HeadersResponse
}
interface HeadersResponse {
  "Content-Type": string;
  "Content-Disposition": string;
}
interface QueryParams {
  documento?: string;
  matricula?: string;
  nome?: string;
}

const fetchDataFromServer = async (params: QueryParams) => {
  const query = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== '') {
      query.append(key, value.toString());
    }
  });

  const response = await api.get(`smart-share?${query.toString()}`);
  const data = response.data.Data?.Items?.map((item: Data) => ({
    Nome: item.Nome,
    DsDocumento: item.DsDocumento,
    Matricula: item.Matricula,
    DtEfetivacao: item.DtEfetivacao,
    CdDocumento: item.CdDocumento,
    TipoArquivo: item.TipoArquivo,
  })) ?? [];
  const total = response.data.Data?.TotalItems;
  return { data, total };
};
interface SmartShareListProps {
  initialDocumento?: string;
}

const SmartShareList: React.FC<SmartShareListProps> = ({ initialDocumento = '' }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [totalItems, setTotalItems] = useState(0);
  const [items, setItems] = useState<Data[]>([]);

  const [documento, setDocumento] = useState<string>(initialDocumento);
  const [matricula, setMatricula] = useState<string>('');
  const [nome, setNome] = useState<string>('');

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTriggered, setSearchTriggered] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const [imageBase64, setImageBase64] = useState('');

  useEffect(() => {
    if (searchTriggered || initialDocumento) {
      fetchData();
      setSearchTriggered(false);
    }
  }, [searchTriggered, initialDocumento]);

  useEffect(() => {
    if (!initialLoad) {
      fetchData();
    } else {
      setInitialLoad(false);
    }
  }, [currentPage, itemsPerPage])

  const onSearch = (doc: string, mat: string, nom: string) => {
    setDocumento(doc);
    setMatricula(mat);
    setNome(nom);
    setSearchTriggered(true);
  };

  const onPageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    setItemsPerPage(perPage);
  };

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);

    const params: QueryParams = {
      documento: documento,
      matricula: matricula,
      nome: nome
    };

    if (!params.documento && !params.matricula && !params.nome) {
      toast.error('É necessário informar um filtro!');
      setIsLoading(false);
      return;
    }

    try {
      const { data, total } = await fetchDataFromServer(params);
      setItems(data);
      setTotalItems(total);
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar os documentos.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getFile = async (documento: string) => {
    try {
      setIsLoading(true);
      const response = await api.get<ApiResponse>(
        'file-management/download',
        {
          params: {
            document: documento,
          },
        }
      );
      return response;
    } catch (error) {
      toast.error('Error downloading the file: ');
      return null;
    } finally {
      setIsLoading(false);
    }
  }

  function extractFilename(contentDisposition: string): string {
    const filenameRegex = /filename="([^"]+)"/;
    const match = filenameRegex.exec(contentDisposition);
    if (match) {
      const filename = match[1];
      return filename.replace(/\.(pdf|mp3)$/i, '');
    }
    return "default-filename";
  }

  const handleDownload = async (documento: string, matricula: string) => {
    try {
      setIsLoading(true);
      const response = await getFile(documento);
      if (!response || !response.data || !response.data.body) {
        toast.error('Erro ao carregar o arquivo!');
        return;
      }

      const base64 = response.data.body;
      const contentType = response.data.headers['Content-Type'] || 'application/octet-stream';
      const nameFile = extractFilename(response.data.headers['Content-Disposition']) || { matricula };

      const binaryStr = window.atob(base64);
      const len = binaryStr.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryStr.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: contentType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${nameFile}.${getExtension(contentType)}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (error) {
      toast.error('Error processing the download: ');
    } finally {
      setIsLoading(false);
    }
  };

  function getExtension(mimeType: string) {
    switch (mimeType) {
      case 'application/pdf': return 'pdf';
      case 'audio/mp3': return 'gz';
      default: return '';
    }
  }

  const handleDisplayImage = async (documento: string) => {
    try {
      setIsLoading(true);
      const response = await getFile(documento);
      if (!response || !response.data || !response.data.body) {
        toast.error('Failed to get the image data.');
        return;
      }

      if (!initialDocumento)
        openModal();

      setImageBase64(response.data.body);
    } catch (error) {
      toast.error('Error processing the image: ');
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const columns: TableColumn<Data>[] = [
    { header: 'Matrícula', accessorKey: 'Matricula' },
    { header: 'Nome', accessorKey: 'Nome' },
    { header: 'Documento', accessorKey: 'DsDocumento' },
    {
      header: 'Dt. Efetivação', accessorKey: 'DtEfetivacao', cell: (info) => {
        const date = new Date(info.getValue() as string);
        return date.toLocaleDateString();
      },
    },
    {
      header: 'Opções',
      cell: ({ row }) => (
        <Grid.Container
          gridSpacing='4px'
          variant='flex'>
          <Grid.Item >
            <Button
              onClick={() => handleDownload(row.original.CdDocumento, row.original.Matricula)}
              title="Baixar"
              size='sm'
            >
              <FontAwesomeIcon icon={faDownload} size="xs" />
            </Button>
          </Grid.Item>
          {
            row.original.TipoArquivo === TYPE_FILE_ENUM.PDF &&
            (
              <Grid.Item >
                <Button
                  size='sm'
                  onClick={() => handleDisplayImage(row.original.CdDocumento)}
                  title="Visualizar"
                >
                  <FontAwesomeIcon icon={faEye} size="xs" />
                </Button>
              </Grid.Item>
            )
          }
        </Grid.Container>
      ),
    }
  ];

  return (
    <div className={!initialDocumento ? 'p-2 ml-3 mr-3 gap-6' : ''}>
      <ToastContainer />

      {!initialDocumento && (
        <div className="mb-4 grid grid-cols-1 shadow rounded-md">
          <div className="bg-white p-4">
            <SearchSmartShareForm
              initialDocumento={documento}
              initialMatricula={matricula}
              initialNome={nome}
              onSearch={onSearch}
            />
            {error && <p className="mt-2 text-red-500">{error}</p>}
          </div>
        </div>
      )}

      <Table
        columns={columns}
        data={items}
        isLoading={isLoading}
        filterTable={!initialDocumento}
      />

      {items.length > 0 && !initialDocumento &&
        (
          <Pagination
            totalItems={totalItems}
            onPageChange={onPageChange}
            itemsPerPageOptions={[10, 20, 30, 50]}
          />
        )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-10"
        overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-75"
      >
        <div className="bg-white p-6 rounded-md shadow-md w-2/4">
          <Grid.Container variant='flex' justifyContent='space-between' direction='row'>
            <h2 className="text-xl font-bold mb-4">Detalhes do Documento</h2>


            <Button variant='neutral' onClick={closeModal}>
              <FontAwesomeIcon onClick={closeModal} icon={faClose} size="1x" />
            </Button>
          </Grid.Container>

          {imageBase64 && <ImageViewer base64={imageBase64} />}
        </div>
      </Modal>

      {imageBase64 && initialDocumento && <ImageViewer base64={imageBase64} />}

    </div>
  );
};

export default SmartShareList;