/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Hide = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3298 9.08836L5.90757 4.21923C5.66076 3.94578 5.24057 3.92575 4.96709 4.17258C4.69362 4.41936 4.67361 4.83958 4.9204 5.11303L6.52787 6.88059C3.55552 8.70063 2.24758 11.4975 2.14358 11.7199L2.139 11.7296C2.06564 11.9031 2.06564 12.0965 2.139 12.2699C2.2524 12.5301 5.06046 18.6665 12.084 18.6665H12.164C13.6247 18.6665 15.0788 18.3396 16.4128 17.746L18.2604 19.7803C18.3938 19.9271 18.5739 20.0005 18.754 20.0005C18.914 20.0005 19.0741 19.9404 19.2009 19.827C19.4743 19.5802 19.4943 19.16 19.2475 18.8866L10.3364 9.08168L10.3298 9.08836ZM9.48267 11.3895C9.55603 11.0827 9.68278 10.8026 9.84953 10.5424L13.3179 14.3576C12.9444 14.5577 12.5242 14.6711 12.0906 14.6711H12.0773C11.537 14.6711 11.0168 14.5111 10.5699 14.2042C10.123 13.8974 9.78283 13.4705 9.58939 12.9636C9.39596 12.4567 9.35594 11.9164 9.48267 11.3895ZM12.1507 17.3391H12.0773C6.6546 17.3391 4.07997 13.1437 3.48636 12.0031C3.87988 11.2694 5.14718 9.22173 7.44833 7.8944L8.93573 9.53524C8.58222 9.98879 8.3221 10.5157 8.18869 11.0827C8.00193 11.8697 8.0553 12.6901 8.34879 13.4438C8.64225 14.1976 9.14917 14.8446 9.81617 15.3048C10.4898 15.765 11.2636 16.0184 12.0906 16.0051C12.8577 16.0051 13.5981 15.7783 14.2384 15.3715L15.459 16.7188C14.4051 17.1257 13.2846 17.3458 12.1507 17.3458V17.3391Z"
      fill={color}
    />
    <path
      d="M22.029 11.7297C21.9156 11.4696 19.1075 5.33319 12.084 5.33319C11.5037 5.33319 10.8501 5.37989 10.2498 5.48659C9.88957 5.54664 9.64279 5.89349 9.70281 6.25365C9.76284 6.61382 10.1097 6.8606 10.4699 6.8006C11.0035 6.71388 11.5437 6.68722 12.084 6.66719C17.5067 6.66719 20.0813 10.8626 20.675 12.0032C20.3881 12.5368 19.6478 13.7841 18.3538 14.938C18.0803 15.1848 18.0536 15.605 18.3004 15.8784C18.4338 16.0252 18.6139 16.0986 18.8007 16.0986C18.9608 16.0986 19.1208 16.0452 19.2476 15.9318C21.1485 14.231 21.9956 12.3567 22.029 12.2766C22.1023 12.1032 22.1023 11.9098 22.029 11.7364V11.7297Z"
      fill={color}
    />
    <path
      d="M14.7319 11.743C14.6786 11.176 14.4384 10.6291 14.0516 10.2022H14.0583C13.6714 9.78198 13.1445 9.4885 12.5842 9.3818C12.2173 9.31507 11.9839 8.96159 12.0506 8.60138C12.1173 8.23454 12.4641 7.99443 12.831 8.06779C13.6714 8.22786 14.4585 8.66811 15.0387 9.30176C15.619 9.94206 15.9792 10.7625 16.0593 11.6229C16.0993 11.9897 15.8258 12.3166 15.4589 12.3499H15.3989C15.0521 12.3499 14.7653 12.0898 14.7319 11.743Z"
      fill={color}
    />
  </>
);
