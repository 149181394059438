/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Syllabus = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M10.4173 9.5C9.95708 9.5 9.58398 9.8731 9.58398 10.3333C9.58398 10.7936 9.95708 11.1667 10.4173 11.1667H17.084C17.5442 11.1667 17.9173 10.7936 17.9173 10.3333C17.9173 9.8731 17.5442 9.5 17.084 9.5H10.4173Z"
      fill={color}
    />
    <path
      d="M10.4173 12.8333C9.95708 12.8333 9.58398 13.2064 9.58398 13.6667C9.58398 14.1269 9.95708 14.5 10.4173 14.5H17.084C17.5442 14.5 17.9173 14.1269 17.9173 13.6667C17.9173 13.2064 17.5442 12.8333 17.084 12.8333H10.4173Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.08398 3.66667C2.08398 2.74619 2.83018 2 3.75065 2H20.4173C21.3378 2 22.084 2.74619 22.084 3.66667V20.3333C22.084 21.2538 21.3378 22 20.4173 22H3.75065C2.83018 22 2.08398 21.2538 2.08398 20.3333V3.66667ZM7.91732 20.3333H20.4173V3.66667H7.91732V20.3333ZM6.25065 3.66667V20.3333H3.75065V3.66667H6.25065Z"
      fill={color}
    />
  </>
);
