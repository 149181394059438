/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Midia = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9722 6.97535C10.7362 6.81798 10.4327 6.80331 10.1826 6.93717C9.93243 7.07103 9.77629 7.3317 9.77629 7.61538V13.7692C9.77629 14.0529 9.93243 14.3136 10.1826 14.4474C10.4327 14.5813 10.7362 14.5666 10.9722 14.4093L15.5876 11.3323C15.8016 11.1897 15.9301 10.9495 15.9301 10.6923C15.9301 10.4351 15.8016 10.1949 15.5876 10.0523L10.9722 6.97535ZM13.7742 10.6923L11.3148 12.3319V9.05271L13.7742 10.6923Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.084 16.0769C22.084 17.3514 21.0508 18.3846 19.7763 18.3846H4.39168C3.11717 18.3846 2.08398 17.3514 2.08398 16.0769V5.30769C2.08398 4.03319 3.11717 3 4.39168 3H19.7763C21.0508 3 22.084 4.03319 22.084 5.30769V16.0769ZM19.7763 16.8462C20.2011 16.8462 20.5455 16.5018 20.5455 16.0769V5.30769C20.5455 4.88286 20.2011 4.53846 19.7763 4.53846H4.39168C3.96684 4.53846 3.62245 4.88286 3.62245 5.30769V16.0769C3.62245 16.5018 3.96684 16.8462 4.39168 16.8462H19.7763Z"
      fill={color}
    />
    <path
      d="M8.23783 20.6923C8.23783 20.2675 8.58223 19.9231 9.00706 19.9231H15.1609C15.5857 19.9231 15.9301 20.2675 15.9301 20.6923C15.9301 21.1171 15.5857 21.4615 15.1609 21.4615H9.00706C8.58223 21.4615 8.23783 21.1171 8.23783 20.6923Z"
      fill={color}
    />
  </>
);
