/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Timer = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M9.85714 2C9.46266 2 9.14286 2.3198 9.14286 2.71429C9.14286 3.10877 9.46266 3.42857 9.85714 3.42857H14.1429C14.5373 3.42857 14.8571 3.10877 14.8571 2.71429C14.8571 2.3198 14.5373 2 14.1429 2H9.85714Z"
      fill={color}
    />
    <path
      d="M16.0408 9.38782C16.3197 9.66676 16.3197 10.119 16.0408 10.398L12.5051 13.9337C12.2261 14.2126 11.7739 14.2126 11.4949 13.9337C11.216 13.6547 11.216 13.2025 11.4949 12.9235L15.0306 9.38782C15.3096 9.10887 15.7618 9.10887 16.0408 9.38782Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.42857 13.4286C3.42857 8.6947 7.26613 4.85714 12 4.85714C16.7339 4.85714 20.5714 8.6947 20.5714 13.4286C20.5714 18.1624 16.7339 22 12 22C7.26613 22 3.42857 18.1624 3.42857 13.4286ZM12 6.28571C8.05511 6.28571 4.85714 9.48368 4.85714 13.4286C4.85714 17.3735 8.05511 20.5714 12 20.5714C15.9449 20.5714 19.1429 17.3735 19.1429 13.4286C19.1429 9.48368 15.9449 6.28571 12 6.28571Z"
      fill={color}
    />
  </>
);
