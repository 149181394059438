import { ReactComponent as Warning } from '../assets/icons/exclamation-circle.svg';

const NoRegister = () => {
  return (
    <div className="flex justify-center flex-col items-center py-4">
    <Warning />
    <p className="text-gray-500">Nenhum registro foi encontrado.</p>
  </div>
  )
}

export default NoRegister;