/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ReferenceMatrix = ({ color = '#191919' }: IconProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.96319 3.44574C4.32771 4.33783 3.08398 5.69666 3.08398 7.38462V16.6154C3.08398 18.3029 4.3278 19.6617 5.9632 20.5539C7.62776 21.462 9.87481 22 12.3148 22C14.7547 22 17.0017 21.462 18.6663 20.5539C20.3017 19.6617 21.5455 18.3029 21.5455 16.6154V7.38462C21.5455 5.69666 20.3018 4.33783 18.6663 3.44574C17.0017 2.53779 14.7547 2 12.3148 2C9.87482 2 7.62777 2.53779 5.96319 3.44574ZM6.69989 4.79635C5.2729 5.57471 4.62245 6.52357 4.62245 7.38462C4.62245 8.24566 5.2729 9.19452 6.69989 9.97288C8.09779 10.7354 10.0815 11.2308 12.3148 11.2308C14.548 11.2308 16.5317 10.7354 17.9296 9.97288C19.3566 9.19452 20.0071 8.24566 20.0071 7.38462C20.0071 6.52357 19.3566 5.57471 17.9296 4.79635C16.5317 4.03386 14.548 3.53846 12.3148 3.53846C10.0815 3.53846 8.09779 4.03386 6.69989 4.79635ZM20.0071 15.0154V16.6154C20.0071 17.4759 19.3566 18.4248 17.9295 19.2033C16.5316 19.9659 14.5479 20.4615 12.3148 20.4615C10.0816 20.4615 8.0979 19.9659 6.69997 19.2033C5.27286 18.4248 4.62245 17.4759 4.62245 16.6154V15.0154C5.01901 15.3624 5.47355 15.6714 5.9632 15.9385C7.62776 16.8466 9.87481 17.3846 12.3148 17.3846C14.7547 17.3846 17.0017 16.8466 18.6663 15.9385C19.156 15.6714 19.6105 15.3624 20.0071 15.0154ZM4.62245 12C4.62245 12.8605 5.27286 13.8094 6.69997 14.5879C8.0979 15.3505 10.0816 15.8462 12.3148 15.8462C14.5479 15.8462 16.5316 15.3505 17.9295 14.5879C19.3566 13.8094 20.0071 12.8605 20.0071 12V10.4004C19.6105 10.7475 19.156 11.0564 18.6663 11.3235C17.0017 12.2314 14.7547 12.7692 12.3148 12.7692C9.87482 12.7692 7.62777 12.2314 5.96319 11.3235C5.47354 11.0564 5.019 10.7475 4.62245 10.4004V12Z"
    fill={color}
  />
);
