/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const PendingRequests = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75472 2.09447C2.03343 1.81576 2.41145 1.65918 2.80561 1.65918H11.7227C11.9198 1.65918 12.1088 1.73747 12.2482 1.87683L17.4498 7.07847C17.5892 7.21782 17.6674 7.40683 17.6674 7.60391V8.34701H11.7227C11.3123 8.34701 10.9796 8.01431 10.9796 7.60391V3.14536L2.80561 3.14536L2.80561 19.4934H8.44744C8.85783 19.4934 9.19053 19.8261 9.19053 20.2365C9.19053 20.6469 8.85783 20.9796 8.44744 20.9796H2.80561C2.41145 20.9796 2.03343 20.823 1.75472 20.5443C1.47601 20.2656 1.31943 19.8875 1.31943 19.4934V3.14536C1.31943 2.7512 1.47601 2.37319 1.75472 2.09447ZM12.4658 4.19625L15.1304 6.86082H12.4658V4.19625Z"
      fill={color}
    />
    <path
      d="M7.35553 14.2917C7.76592 14.2917 8.09862 14.6244 8.09862 15.0348C8.09862 15.4452 7.76592 15.7779 7.35552 15.7779H6.52107C6.11067 15.7779 5.77798 15.4452 5.77798 15.0348C5.77798 14.6244 6.11067 14.2917 6.52107 14.2917H7.35553Z"
      fill={color}
    />
    <path
      d="M8.91506 11.3194C9.32546 11.3194 9.65815 11.6521 9.65815 12.0625C9.65815 12.4729 9.32546 12.8056 8.91506 12.8056H6.52107C6.11067 12.8056 5.77798 12.4729 5.77798 12.0625C5.77798 11.6521 6.11067 11.3194 6.52107 11.3194H8.91506Z"
      fill={color}
    />
    <path
      d="M17.0767 12.9958C16.6828 12.9175 16.2746 12.9577 15.9036 13.1114C15.5326 13.2651 15.2155 13.5253 14.9924 13.8592C14.7693 14.193 14.6503 14.5856 14.6503 14.9871C14.6503 15.2363 14.8523 15.4383 15.1014 15.4383C15.3506 15.4383 15.5526 15.2363 15.5526 14.9871C15.5526 14.764 15.6188 14.546 15.7427 14.3605C15.8667 14.175 16.0428 14.0304 16.2489 13.945C16.455 13.8597 16.6818 13.8373 16.9006 13.8809C17.1194 13.9244 17.3204 14.0318 17.4781 14.1896C17.6359 14.3473 17.7433 14.5483 17.7868 14.7671C17.8304 14.9859 17.808 15.2127 17.7226 15.4188C17.6373 15.6249 17.4927 15.801 17.3072 15.925C17.1217 16.0489 16.9036 16.1151 16.6806 16.1151C16.5609 16.1151 16.4461 16.1626 16.3615 16.2472C16.2769 16.3318 16.2294 16.4466 16.2294 16.5662V17.0174C16.2294 17.2666 16.4314 17.4686 16.6806 17.4686C16.9297 17.4686 17.1317 17.2666 17.1317 17.0174V16.9667C17.372 16.9119 17.6015 16.8136 17.8085 16.6753C18.1424 16.4522 18.4026 16.1351 18.5563 15.7641C18.71 15.3931 18.7502 14.9849 18.6718 14.591C18.5935 14.1972 18.4001 13.8354 18.1162 13.5515C17.8323 13.2676 17.4705 13.0742 17.0767 12.9958Z"
      fill={color}
    />
    <path
      d="M17.3573 19.0477C17.3573 19.4215 17.0543 19.7245 16.6806 19.7245C16.3068 19.7245 16.0038 19.4215 16.0038 19.0477C16.0038 18.6739 16.3068 18.3709 16.6806 18.3709C17.0543 18.3709 17.3573 18.6739 17.3573 19.0477Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6806 16.3407C10.6806 13.027 13.3668 10.3407 16.6806 10.3407C19.9943 10.3407 22.6806 13.027 22.6806 16.3407C22.6806 19.6544 19.9943 22.3407 16.6806 22.3407C13.3668 22.3407 10.6806 19.6544 10.6806 16.3407ZM16.6806 11.5339C14.0259 11.5339 11.8738 13.686 11.8738 16.3407C11.8738 18.9954 14.0259 21.1475 16.6806 21.1475C19.3353 21.1475 21.4873 18.9954 21.4873 16.3407C21.4873 13.686 19.3353 11.5339 16.6806 11.5339Z"
      fill={color}
    />
  </>
);
