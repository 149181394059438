/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Simulations = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M13.5385 9.15385C13.1136 9.15385 12.7692 9.49824 12.7692 9.92308C12.7692 10.3479 13.1136 10.6923 13.5385 10.6923H18.1538C18.5787 10.6923 18.9231 10.3479 18.9231 9.92308C18.9231 9.49824 18.5787 9.15385 18.1538 9.15385H13.5385Z"
      fill={color}
    />
    <path
      d="M12.7692 13C12.7692 12.5752 13.1136 12.2308 13.5385 12.2308H18.1538C18.5787 12.2308 18.9231 12.5752 18.9231 13C18.9231 13.4248 18.5787 13.7692 18.1538 13.7692H13.5385C13.1136 13.7692 12.7692 13.4248 12.7692 13Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.84615 7.61538C5.42132 7.61538 5.07692 7.95978 5.07692 8.38461V14.5385C5.07692 14.9633 5.42132 15.3077 5.84615 15.3077H10.4615C10.8864 15.3077 11.2308 14.9633 11.2308 14.5385V8.38461C11.2308 7.95978 10.8864 7.61538 10.4615 7.61538H5.84615ZM6.61538 13.7692V9.15385H9.69231V13.7692H6.61538Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.53846 3C3.13044 3 2.73912 3.16209 2.45061 3.45061C2.16209 3.73912 2 4.13044 2 4.53846V19.9231C2 20.1897 2.13804 20.4373 2.36482 20.5774C2.5916 20.7176 2.87479 20.7303 3.11324 20.6111L5.84615 19.2446L8.57907 20.6111C8.79563 20.7194 9.05053 20.7194 9.26709 20.6111L12 19.2446L14.7329 20.6111C14.9495 20.7194 15.2044 20.7194 15.4209 20.6111L18.1538 19.2446L20.8868 20.6111C21.1252 20.7303 21.4084 20.7176 21.6352 20.5774C21.862 20.4373 22 20.1897 22 19.9231V4.53846C22 4.13044 21.8379 3.73912 21.5494 3.45061C21.2609 3.16209 20.8696 3 20.4615 3H3.53846ZM3.53846 4.53846L20.4615 4.53846V18.6784L18.4979 17.6966C18.2813 17.5883 18.0264 17.5883 17.8098 17.6966L15.0769 19.0631L12.344 17.6966C12.1275 17.5883 11.8725 17.5883 11.656 17.6966L8.92308 19.0631L6.19016 17.6966C5.9736 17.5883 5.7187 17.5883 5.50214 17.6966L3.53846 18.6784L3.53846 4.53846Z"
      fill={color}
    />
  </>
);
