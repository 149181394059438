/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Archive = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M9.85723 12.1429C9.46274 12.1429 9.14294 12.4627 9.14294 12.8572C9.14294 13.2517 9.46274 13.5715 9.85723 13.5715H14.1429C14.5374 13.5715 14.8572 13.2517 14.8572 12.8572C14.8572 12.4627 14.5374 12.1429 14.1429 12.1429H9.85723Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      d="M2 6.42857C2 5.63959 2.63959 5 3.42857 5H20.5714C21.3604 5 22 5.63959 22 6.42857V8.57143C22 9.36037 21.3605 9.99994 20.5715 10V17.857C20.5715 18.2359 20.421 18.5993 20.1531 18.8672C19.8852 19.1351 19.5218 19.2856 19.143 19.2856H4.85725C4.47837 19.2856 4.11501 19.1351 3.8471 18.8672C3.57919 18.5993 3.42868 18.2359 3.42868 17.857V10C2.6397 10 2 9.36041 2 8.57143V6.42857ZM19.8573 8.57132L19.8699 8.57143H20.5714V6.42857H3.42857V8.57143H4.13035L4.14297 8.57132L4.15558 8.57143H19.8446L19.8573 8.57132ZM19.143 10H4.85725V17.857H19.143V10Z"
      fill={color}
    />
  </>
);
