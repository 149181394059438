/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const MicrophoneOpen = ({ color = '#191919' }: IconProps) => (
  <path
    d="M12.084 16.2857C13.2203 16.2845 14.3097 15.8326 15.1132 15.0292C15.9166 14.2257 16.3686 13.1363 16.3697 12V6.28571C16.3697 5.14907 15.9182 4.05898 15.1145 3.25526C14.3108 2.45153 13.2207 2 12.084 2C10.9474 2 9.85729 2.45153 9.05356 3.25526C8.24983 4.05898 7.79831 5.14907 7.79831 6.28571V12C7.79949 13.1363 8.2514 14.2257 9.05487 15.0292C9.85834 15.8326 10.9477 16.2845 12.084 16.2857ZM9.22688 6.28571C9.22688 5.52795 9.5279 4.80123 10.0637 4.26541C10.5995 3.72959 11.3263 3.42857 12.084 3.42857C12.8418 3.42857 13.5685 3.72959 14.1043 4.26541C14.6401 4.80123 14.9412 5.52795 14.9412 6.28571V12C14.9412 12.7578 14.6401 13.4845 14.1043 14.0203C13.5685 14.5561 12.8418 14.8571 12.084 14.8571C11.3263 14.8571 10.5995 14.5561 10.0637 14.0203C9.5279 13.4845 9.22688 12.7578 9.22688 12V6.28571ZM12.7983 19.1071V21.2857C12.7983 21.4752 12.7231 21.6568 12.5891 21.7908C12.4551 21.9247 12.2735 22 12.084 22C11.8946 22 11.7129 21.9247 11.5789 21.7908C11.445 21.6568 11.3697 21.4752 11.3697 21.2857V19.1071C9.6087 18.9279 7.97667 18.1021 6.78924 16.7893C5.60181 15.4766 4.94334 13.7701 4.94116 12C4.94116 11.8106 5.01642 11.6289 5.15037 11.4949C5.28433 11.361 5.46601 11.2857 5.65545 11.2857C5.84489 11.2857 6.02657 11.361 6.16052 11.4949C6.29448 11.6289 6.36973 11.8106 6.36973 12C6.36973 13.5155 6.97177 14.969 8.04341 16.0406C9.11505 17.1122 10.5685 17.7143 12.084 17.7143C13.5995 17.7143 15.053 17.1122 16.1246 16.0406C17.1963 14.969 17.7983 13.5155 17.7983 12C17.7983 11.8106 17.8736 11.6289 18.0075 11.4949C18.1415 11.361 18.3232 11.2857 18.5126 11.2857C18.702 11.2857 18.8837 11.361 19.0177 11.4949C19.1516 11.6289 19.2269 11.8106 19.2269 12C19.2247 13.7701 18.5662 15.4766 17.3788 16.7893C16.1914 18.1021 14.5593 18.9279 12.7983 19.1071Z"
    fill={color}
  />
);
