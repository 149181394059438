/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ListCheck = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1667 12.1667C11.1667 11.7064 11.5398 11.3333 12 11.3333H21.1667C21.6269 11.3333 22 11.7064 22 12.1667C22 12.6269 21.6269 13 21.1667 13H12C11.5398 13 11.1667 12.6269 11.1667 12.1667Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1667 5.5C11.1667 5.03976 11.5398 4.66667 12 4.66667H21.1667C21.6269 4.66667 22 5.03976 22 5.5C22 5.96024 21.6269 6.33333 21.1667 6.33333H12C11.5398 6.33333 11.1667 5.96024 11.1667 5.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1667 18.8333C11.1667 18.3731 11.5398 18 12 18H21.1667C21.6269 18 22 18.3731 22 18.8333C22 19.2936 21.6269 19.6667 21.1667 19.6667H12C11.5398 19.6667 11.1667 19.2936 11.1667 18.8333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.86261 3.2684C9.17462 3.60673 9.15328 4.13394 8.81494 4.44594L5.20036 7.77928C4.88089 8.07389 4.38865 8.07354 4.0696 7.77846L2.26752 6.1118C1.92963 5.7993 1.90905 5.27207 2.22154 4.93418C2.53404 4.5963 3.06127 4.57572 3.39916 4.88821L4.63624 6.03233L7.68506 3.22073C8.0234 2.90872 8.5506 2.93006 8.86261 3.2684Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.86261 9.93506C9.17462 10.2734 9.15328 10.8006 8.81494 11.1126L5.20036 14.4459C4.88089 14.7406 4.38865 14.7402 4.0696 14.4451L2.26752 12.7785C1.92963 12.466 1.90905 11.9387 2.22154 11.6009C2.53404 11.263 3.06127 11.2424 3.39916 11.5549L4.63624 12.699L7.68506 9.8874C8.0234 9.57539 8.5506 9.59673 8.86261 9.93506Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.86261 16.6017C9.17462 16.9401 9.15328 17.4673 8.81494 17.7793L5.20036 21.1126C4.88089 21.4072 4.38865 21.4069 4.0696 21.1118L2.26752 19.4451C1.92963 19.1326 1.90905 18.6054 2.22154 18.2675C2.53404 17.9296 3.06127 17.909 3.39916 18.2215L4.63624 19.3657L7.68506 16.5541C8.0234 16.2421 8.5506 16.2634 8.86261 16.6017Z"
      fill={color}
    />
  </>
);
