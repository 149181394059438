/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Google = ({ color = '#191919' }: IconProps) => (
  <path
    d="M22 11.9995C22.0001 14.3541 21.1693 16.6332 19.654 18.4355C18.1387 20.2377 16.0361 21.4474 13.7164 21.8516C11.3967 22.2557 9.00885 21.8284 6.97333 20.6448C4.93782 19.4612 3.38524 17.5973 2.58904 15.3814C1.79285 13.1655 1.80413 10.7397 2.62088 8.5313C3.43763 6.32288 5.00747 4.4735 7.05389 3.30889C9.10032 2.14427 11.4921 1.73913 13.8079 2.16483C16.1237 2.59052 18.215 3.81974 19.7135 5.63597C19.7879 5.71968 19.8447 5.81752 19.8804 5.92365C19.9161 6.02979 19.9301 6.14203 19.9215 6.25369C19.9129 6.36534 19.8818 6.47411 19.8302 6.5735C19.7786 6.6729 19.7075 6.76087 19.6212 6.83217C19.5348 6.90347 19.435 6.95663 19.3276 6.98848C19.2203 7.02032 19.1076 7.03021 18.9963 7.01753C18.885 7.00486 18.7775 6.96989 18.68 6.91471C18.5826 6.85953 18.4972 6.78528 18.4291 6.69639C17.2035 5.21027 15.5008 4.19466 13.6108 3.82235C11.7208 3.45003 9.76016 3.74401 8.06245 4.65426C6.36474 5.56451 5.03481 7.03481 4.29891 8.81505C3.56301 10.5953 3.4666 12.5755 4.02607 14.4188C4.58553 16.2621 5.76633 17.8546 7.36758 18.9255C8.96883 19.9964 10.8916 20.4795 12.8089 20.2925C14.7261 20.1056 16.5194 19.2602 17.8836 17.9002C19.2479 16.5401 20.0988 14.7495 20.2916 12.8328H12C11.779 12.8328 11.567 12.745 11.4107 12.5888C11.2544 12.4325 11.1666 12.2205 11.1666 11.9995C11.1666 11.7785 11.2544 11.5665 11.4107 11.4102C11.567 11.254 11.779 11.1662 12 11.1662H21.1666C21.3876 11.1662 21.5996 11.254 21.7559 11.4102C21.9122 11.5665 22 11.7785 22 11.9995Z"
    fill={color}
  />
);
