import React from 'react';

interface InputWithLabelProps {
  value: string;
  setValue: (value: string) => void;
  label: string;
  placeholder?: string;
  id: string;
}

const InputWithLabel: React.FC<InputWithLabelProps> = ({
  value,
  setValue,
  label,
  placeholder,
  id
}) => {
  return (
    <div className="input-container">
      <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <input
        type="text"
        id={id}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        className="mt-1 block w-full h-10 rounded-md border px-4 py-2 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base"
      />
    </div>
  );
};

export default InputWithLabel;
