import { useState, useEffect } from 'react';
import { Icon } from '../assets/icons';
import CustomSelect from '../components/Select'; 
import { Button } from './Button';
export interface PaginationProps {
  totalItems: number;
  nameItems?: string;
  defaultItemsPerPage?: number;
  itemsPerPageOptions?: number[];
  onPageChange: (page: number, itemsPerPage: number) => void;
}

export const Pagination = ({
  totalItems,
  nameItems = 'items',
  defaultItemsPerPage = 10,
  itemsPerPageOptions = [10, 20, 30, 50],
  onPageChange,
}: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    onPageChange(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage, onPageChange]);

  const handlePreviousPage = () => {
    setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage < totalPages ? currentPage + 1 : currentPage);
  };

  const handleItemsPerPageChange = (newValue: number) => {
    setItemsPerPage(newValue);
    setCurrentPage(1);
    setSelectedValue(newValue);
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(startPage + 4, totalPages);
    if (endPage - startPage < 4) {
      startPage = Math.max(1, endPage - 4);
    }
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const [selectedValue, setSelectedValue] = useState<number>(itemsPerPageOptions[0]);

  return (
    <div className="flex gap-5 flex-col md:flex-row justify-between items-center md:space-x-4 mt-4">
      <div className="flex gap-4 flex-col md:flex-row items-center md:space-x-4">
        <div>
          <CustomSelect
            isPagination
            options={itemsPerPageOptions}
            value={selectedValue}
            onChange={handleItemsPerPageChange}
          />
        </div>
        <span className='text-gray-500'>
          Mostrando {Math.min((currentPage - 1) * itemsPerPage + 1, totalItems)} a {Math.min(currentPage * itemsPerPage, totalItems)} de {totalItems} {nameItems || 'Itens'}
        </span>
      </div>

      <div className='flex flex-wrap justify-center items-center gap-2'>
        <Button
          size='sm'
          variant='neutral'
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          <Icon name='ChevronLeft' />
        </Button>
        {generatePageNumbers().map(number => (
          <Button
            size='sm'
            key={number}
            variant={number === currentPage ? 'primary' : 'neutral'}
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </Button>
        ))}
        <Button
          size='sm'
          variant='neutral'
          onClick={handleNextPage}
          disabled={currentPage >= totalPages}
        >
          <Icon name='ChevronRight' />
        </Button>
      </div>
    </div>
  );
};
