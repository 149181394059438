/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Comments = ({ color = '#191919' }: IconProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.64294 2.70502C3.20721 2.70502 2.78933 2.88027 2.48122 3.19223C2.17311 3.50419 2.00002 3.9273 2.00002 4.36848L2 19.6387L2.00005 19.6448C2.00251 19.9593 2.09301 20.2667 2.26104 20.5313C2.42908 20.7959 2.66776 21.0068 2.9494 21.1396C3.23103 21.2724 3.54408 21.3216 3.85223 21.2815C4.16038 21.2414 4.4512 21.1135 4.69055 20.913L4.69371 20.9103L7.72677 18.3433L20.3571 18.3396C20.7928 18.3396 21.2107 18.1644 21.5188 17.8524C21.8269 17.5404 22 17.1173 22 16.6762V4.36848C22 3.9273 21.8269 3.50419 21.5188 3.19223C21.2107 2.88027 20.7928 2.70502 20.3571 2.70502H3.64294ZM3.7664 19.3642V4.49348L20.2336 4.49348V16.5512H7.6788C7.2918 16.5513 6.91704 16.6918 6.62308 16.9464L3.7664 19.3642Z"
    fill={color}
  />
);
