/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ListTraits = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.83333 5.83333C7.83333 5.3731 8.20643 5 8.66667 5H21.1667C21.6269 5 22 5.3731 22 5.83333C22 6.29357 21.6269 6.66667 21.1667 6.66667H8.66667C8.20643 6.66667 7.83333 6.29357 7.83333 5.83333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.83333 12.5C7.83333 12.0398 8.20643 11.6667 8.66667 11.6667H21.1667C21.6269 11.6667 22 12.0398 22 12.5C22 12.9602 21.6269 13.3333 21.1667 13.3333H8.66667C8.20643 13.3333 7.83333 12.9602 7.83333 12.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.83333 19.1667C7.83333 18.7064 8.20643 18.3333 8.66667 18.3333H21.1667C21.6269 18.3333 22 18.7064 22 19.1667C22 19.6269 21.6269 20 21.1667 20H8.66667C8.20643 20 7.83333 19.6269 7.83333 19.1667Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5.83333C2 5.3731 2.3731 5 2.83333 5H4.5C4.96024 5 5.33333 5.3731 5.33333 5.83333C5.33333 6.29357 4.96024 6.66667 4.5 6.66667H2.83333C2.3731 6.66667 2 6.29357 2 5.83333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12.5C2 12.0398 2.3731 11.6667 2.83333 11.6667H4.5C4.96024 11.6667 5.33333 12.0398 5.33333 12.5C5.33333 12.9602 4.96024 13.3333 4.5 13.3333H2.83333C2.3731 13.3333 2 12.9602 2 12.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 19.1667C2 18.7064 2.3731 18.3333 2.83333 18.3333H4.5C4.96024 18.3333 5.33333 18.7064 5.33333 19.1667C5.33333 19.6269 4.96024 20 4.5 20H2.83333C2.3731 20 2 19.6269 2 19.1667Z"
      fill={color}
    />
  </>
);
