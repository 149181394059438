/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const AddUser = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      d="M8.26532 4C11.4142 4 13.9702 6.55573 13.9702 9.70485L13.9595 9.6942C13.9595 11.5921 12.9997 13.3824 11.4251 14.4392C11.6952 14.5319 11.9652 14.6411 12.2278 14.763C12.332 14.8115 12.4348 14.8616 12.536 14.9138C12.7946 15.0539 12.9456 15.3234 12.9134 15.6254C12.8918 15.863 12.7514 16.0787 12.525 16.197C12.2877 16.3264 12.0076 16.3376 11.7703 16.2188C10.7025 15.6904 9.51629 15.399 8.33004 15.399H8.18968C6.83085 15.4097 5.48294 15.7767 4.30735 16.4559C3.09953 17.1568 2.09652 18.1599 1.40645 19.3573C1.37316 19.4201 1.33214 19.4755 1.28473 19.5229C1.19418 19.614 1.08099 19.6774 0.953416 19.7131C0.888697 19.7344 0.823978 19.7344 0.759259 19.7344C0.629822 19.7344 0.500384 19.7019 0.381866 19.6375C0.198362 19.5405 0.0798441 19.3786 0.0260448 19.1847C-0.0280207 18.9903 0.00447187 18.7857 0.101417 18.6132C0.931843 17.1787 2.11809 15.9924 3.55256 15.162C3.82688 15.0033 4.11159 14.8584 4.40482 14.7274C4.63014 14.6267 4.86025 14.5345 5.09463 14.4504C3.53099 13.4042 2.5602 11.6033 2.5602 9.70485C2.5602 6.55573 5.1162 4 8.26532 4ZM8.33004 13.9001C10.6056 13.8676 12.4603 11.9804 12.4603 9.70485H12.4819C12.4819 7.38616 10.5947 5.49892 8.27597 5.49892C5.95728 5.49892 4.07004 7.4293 4.07004 9.70485C4.07004 11.9804 5.92505 13.8676 8.2006 13.9001H8.33004Z"
      fill={color}
    />
    <path
      d="M20.2468 15.8412H23.2018C23.6439 15.8412 24 16.1975 24 16.6396C24 17.0817 23.6439 17.4376 23.2018 17.4376H20.2468V20.1659C20.2468 20.608 19.891 20.9638 19.4489 20.9638C19.0068 20.9638 18.6507 20.608 18.6507 20.1659V17.4376H15.6957C15.2536 17.4376 14.8978 17.0817 14.8978 16.6396C14.8978 16.1975 15.2536 15.8412 15.6957 15.8412H18.6507V13.1128C18.6507 12.8742 18.7546 12.6606 18.92 12.5147C19.0606 12.39 19.2457 12.3149 19.4489 12.3149C19.891 12.3149 20.2468 12.6707 20.2468 13.1128V15.8412Z"
      fill={color}
    />
  </>
);
