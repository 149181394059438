/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Courses = ({ color = '#191919' }: IconProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13.1028 9.55982V19.6036C13.1028 20.473 12.3981 21.1777 11.5287 21.1777H8.38046C8.09374 21.1777 7.82493 21.1011 7.5934 20.9671C7.36187 21.1011 7.09306 21.1777 6.80634 21.1777H3.6581C2.78874 21.1777 2.08398 20.473 2.08398 19.6036V3.86241C2.08398 2.99305 2.78874 2.28829 3.6581 2.28829H6.80634C7.09306 2.28829 7.36187 2.36494 7.5934 2.49888C7.82493 2.36494 8.09374 2.28829 8.38046 2.28829H11.5287C12.046 2.28829 12.5049 2.53778 12.7919 2.92299C12.8422 2.90384 12.8939 2.88707 12.947 2.87283L16.0008 2.05429C16.8441 1.82826 17.7109 2.32864 17.937 3.17192L22.0297 18.4409C22.2557 19.2841 21.7553 20.151 20.9121 20.377L17.8583 21.1956C17.015 21.4216 16.1481 20.9212 15.9221 20.078C14.9843 16.5792 14.048 13.0862 13.1028 9.55982ZM17.449 19.6687L16.8157 17.3059L19.8684 16.4835L20.5028 18.8501L17.449 19.6687ZM17.4239 7.36333L19.4609 14.9631L16.4081 15.7855L14.3695 8.17959L17.4239 7.36333ZM17.0163 5.84288L16.4101 3.58119L13.3563 4.39973L13.9619 6.65914L17.0163 5.84288ZM11.5287 3.86241V15.6683H8.38046V3.86241H11.5287ZM11.5287 17.2424H8.38046V19.6036H11.5287V17.2424ZM6.80634 7.7977V19.6036H3.6581V7.7977H6.80634ZM6.80634 6.22359V3.86241H3.6581V6.22359H6.80634Z"
    fill={color}
  />
);
