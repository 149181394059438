/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const AccessHistory = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.65744 6.5809L8.01455 1.22392C8.08564 1.15277 8.17007 1.09653 8.26299 1.05807C8.35592 1.01962 8.45545 0.99987 8.55597 1H17.7396C18.1455 1 18.5348 1.16127 18.8218 1.44836C19.1089 1.73531 19.2702 2.12468 19.2702 2.53066V7.8004C19.2702 7.90673 19.248 8.01084 19.2063 8.10658C19.1684 8.19343 19.1143 8.27334 19.046 8.34162C18.5639 8.82372 17.7396 8.48222 17.7396 7.8004V2.53066H9.3213V7.12237C9.3213 7.32536 9.24066 7.51998 9.09712 7.66359C8.95364 7.80707 8.75896 7.88764 8.55597 7.88764H3.96419V19.3671H6.74679C7.42861 19.3671 7.77004 20.1915 7.28794 20.6736C7.14439 20.8171 6.94971 20.8978 6.74679 20.8978H3.96419C3.55827 20.8978 3.16891 20.7365 2.88188 20.4494C2.59486 20.1625 2.43359 19.7731 2.43359 19.3671V7.12237C2.43353 7.0218 2.45321 6.92226 2.49167 6.8294C2.53005 6.73654 2.58642 6.65205 2.65744 6.5809ZM7.7907 3.61257L5.04616 6.35711H7.7907V3.61257Z"
      fill={color}
    />
    <path
      d="M18.3849 13.0945C19.3251 13.477 20.1232 14.1332 20.6917 14.9859L20.7027 15.0077C21.2712 15.8496 21.5664 16.8445 21.5664 17.8613C21.5664 18.8781 21.2712 19.8622 20.7027 20.7148C20.1341 21.5567 19.336 22.2126 18.3958 22.6063C17.7616 22.8688 17.0947 23 16.4277 23C16.3808 23 16.3341 22.9993 16.2875 22.998L16.2359 22.9963C16.2042 22.9952 16.1725 22.9937 16.1409 22.9919C15.9014 22.9785 15.6645 22.9485 15.422 22.9015C14.4161 22.6938 13.5087 22.2126 12.7871 21.4912C12.5575 21.2615 12.5575 20.8788 12.7871 20.6383C13.0275 20.4087 13.4102 20.4087 13.6399 20.6383C14.1865 21.185 14.8862 21.5567 15.6516 21.7097C16.4169 21.8629 17.2041 21.7864 17.9256 21.4912C18.6582 21.196 19.2595 20.6931 19.6968 20.0479C20.1341 19.403 20.3637 18.6486 20.3637 17.8613C20.3637 17.0741 20.1341 16.3198 19.6968 15.6746C19.2595 15.0187 18.6472 14.5158 17.9256 14.2206C17.2041 13.9254 16.4169 13.8489 15.6516 14.002C14.8862 14.1549 14.1865 14.5378 13.6399 15.0843L12.9183 15.7949H13.5196C13.8476 15.7949 14.1209 16.0683 14.1209 16.3963C14.1209 16.7243 13.8476 16.9977 13.5196 16.9977H11.4532C11.2564 16.9977 11.0597 16.9101 10.9722 16.7899C10.9175 16.7134 10.8519 16.5165 10.8519 16.298V14.3299C10.8519 14.002 11.1252 13.7287 11.4532 13.7287C11.7812 13.7287 12.0545 14.002 12.0545 14.3299V14.9313L12.7762 14.2096C13.4977 13.488 14.4052 12.9961 15.411 12.7993C16.4169 12.6026 17.4446 12.7118 18.3849 13.0945Z"
      fill={color}
    />
    <path
      d="M15.8155 15.3687C15.8155 15.0425 16.0859 14.7703 16.4113 14.7673C16.737 14.7703 17.0073 15.0425 17.0073 15.3687V17.4896L18.855 18.5501C18.997 18.6267 19.0954 18.7579 19.1392 18.9109C19.1829 19.0641 19.161 19.228 19.0845 19.3701C18.9753 19.567 18.7675 19.6762 18.5597 19.6762C18.4504 19.6762 16.067 18.3315 16.067 18.3315C15.9904 18.2767 15.9358 18.2222 15.8921 18.1455C15.8672 18.1021 15.8459 18.0515 15.8321 17.9998C15.8216 17.9607 15.8155 17.921 15.8155 17.8832V15.3687Z"
      fill={color}
    />
  </>
);
