/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const List = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fill={color}
      d="M9.185 11.63a.74.74 0 0 0 0 1.481h5.926a.74.74 0 1 0 0-1.481H9.185ZM9.185 14.593a.74.74 0 1 0 0 1.481h5.926a.74.74 0 0 0 0-1.481H9.185Z"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M8.444 2.74a.74.74 0 1 0-1.481 0v.741H5.481A1.481 1.481 0 0 0 4 4.963v14.074A2.963 2.963 0 0 0 6.963 22h10.37a2.963 2.963 0 0 0 2.963-2.963V4.963a1.482 1.482 0 0 0-1.481-1.482h-1.482v-.74a.74.74 0 1 0-1.481 0v.74h-2.963v-.74a.74.74 0 0 0-1.482 0v.74H8.444v-.74Zm7.408 2.223h-2.963v.74a.74.74 0 0 1-1.482 0v-.74H8.444v.74a.74.74 0 1 1-1.481 0v-.74H5.481v14.074a1.481 1.481 0 0 0 1.482 1.482h10.37a1.482 1.482 0 0 0 1.482-1.482V4.963h-1.482v.74a.74.74 0 1 1-1.481 0v-.74Z"
      clipRule="evenodd"
    />
  </>
);
