/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const RotateLeft = ({ color = '#191919' }: IconProps) => (
  <path
    d="M22 16.4445C22 16.641 21.922 16.8294 21.783 16.9683C21.6441 17.1072 21.4557 17.1853 21.2593 17.1853C21.0628 17.1853 20.8744 17.1072 20.7355 16.9683C20.5966 16.8294 20.5185 16.641 20.5185 16.4445C20.5183 14.8331 20.0403 13.258 19.145 11.9182C18.2496 10.5784 16.9772 9.53421 15.4884 8.91757C13.9996 8.30094 12.3615 8.13958 10.781 8.4539C9.20057 8.76822 7.74879 9.54409 6.60926 10.6834L4.53704 12.7408H8.66667C8.86312 12.7408 9.05153 12.8189 9.19045 12.9578C9.32936 13.0967 9.40741 13.2851 9.40741 13.4816C9.40741 13.678 9.32936 13.8664 9.19045 14.0054C9.05153 14.1443 8.86312 14.2223 8.66667 14.2223H2.74074C2.54428 14.2223 2.35587 14.1443 2.21696 14.0054C2.07804 13.8664 2 13.678 2 13.4816V7.55565C2 7.35919 2.07804 7.17078 2.21696 7.03187C2.35587 6.89295 2.54428 6.81491 2.74074 6.81491C2.9372 6.81491 3.12561 6.89295 3.26452 7.03187C3.40344 7.17078 3.48148 7.35919 3.48148 7.55565V11.701L5.56296 9.63343C6.9099 8.28719 8.62576 7.37055 10.4936 6.9994C12.3614 6.62825 14.2974 6.81925 16.0567 7.54827C17.816 8.27728 19.3196 9.51156 20.3775 11.0951C21.4354 12.6786 22 14.5402 22 16.4445Z"
    fill={color}
  />
);
