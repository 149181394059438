/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Chapter = ({ color = '#191919' }: IconProps) => (
  <>
    <g clipPath="url(#clip0_3568_296)">
      <path
        d="M13.315 5.255C13.315 5.605 13.575 5.885 13.895 5.885H17.515C17.835 5.885 18.095 5.605 18.095 5.255C18.095 4.905 17.835 4.625 17.515 4.625H13.895C13.575 4.625 13.315 4.905 13.315 5.255Z"
        fill={color}
      />
      <path
        d="M17.525 7.785H13.905C13.585 7.785 13.325 8.065 13.325 8.415C13.325 8.765 13.585 9.045 13.905 9.045H17.525C17.845 9.045 18.105 8.765 18.105 8.415C18.105 8.065 17.845 7.785 17.525 7.785Z"
        fill={color}
      />
      <path
        d="M15.345 10.945H13.895C13.575 10.945 13.315 11.225 13.315 11.575C13.315 11.925 13.575 12.205 13.895 12.205H15.345C15.665 12.205 15.925 11.925 15.925 11.575C15.925 11.225 15.665 10.945 15.345 10.945Z"
        fill={color}
      />
      <path
        d="M8.105 4.625H4.485C4.165 4.625 3.905 4.905 3.905 5.255C3.905 5.605 4.165 5.885 4.485 5.885H8.105C8.425 5.885 8.685 5.605 8.685 5.255C8.685 4.905 8.425 4.625 8.105 4.625Z"
        fill={color}
      />
      <path
        d="M8.105 7.785H4.485C4.165 7.785 3.905 8.065 3.905 8.415C3.905 8.765 4.165 9.045 4.485 9.045H8.105C8.425 9.045 8.685 8.765 8.685 8.415C8.685 8.065 8.425 7.785 8.105 7.785Z"
        fill={color}
      />
      <path
        d="M5.92499 10.945H4.47499C4.15499 10.945 3.89499 11.225 3.89499 11.575C3.89499 11.925 4.15499 12.205 4.47499 12.205H5.92499C6.24499 12.205 6.50499 11.925 6.50499 11.575C6.50499 11.225 6.24499 10.945 5.92499 10.945Z"
        fill={color}
      />
      <path
        d="M20.675 1.035C20.415 0.755001 20.065 0.595001 19.695 0.595001H13.895C12.935 0.595001 12.045 0.995001 11.375 1.725C11.255 1.855 11.155 1.995 11.045 2.145L10.995 2.225L10.945 2.145C10.865 2.015 10.755 1.875 10.615 1.725C9.93499 0.995001 9.03499 0.595001 8.09499 0.595001H2.30499C1.91499 0.595001 1.56499 0.755001 1.32499 1.035C1.07499 1.325 0.924988 1.715 0.924988 2.095V14.745C0.924988 15.155 1.06499 15.525 1.32499 15.805C1.59499 16.085 1.95499 16.235 2.30499 16.235H8.10499C8.70499 16.235 9.26499 16.485 9.68499 16.955C10.105 17.415 10.345 18.045 10.345 18.695C10.345 19.085 10.635 19.405 10.995 19.405C11.355 19.405 11.655 19.085 11.655 18.695C11.655 18.055 11.895 17.415 12.315 16.955C12.735 16.495 13.315 16.235 13.895 16.235H19.695C20.075 16.235 20.425 16.075 20.675 15.795C20.935 15.515 21.075 15.135 21.075 14.735V2.095C21.075 1.685 20.935 1.315 20.675 1.035ZM10.345 15.695L10.235 15.605C9.62499 15.105 8.87499 14.825 8.10499 14.825H2.23499V2.015H8.10499C8.70499 2.015 9.26499 2.265 9.68499 2.735C10.115 3.205 10.345 3.815 10.345 4.475V15.705V15.695ZM19.765 14.825H13.895C13.125 14.825 12.365 15.105 11.765 15.605L11.655 15.695V4.465C11.655 3.815 11.885 3.195 12.315 2.725C12.735 2.265 13.315 2.005 13.895 2.005H19.765V14.815V14.825Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_3568_296">
        <rect
          width="20.15"
          height="18.81"
          fill={color}
          transform="translate(0.924988 0.595001)"
        />
      </clipPath>
    </defs>
  </>
);
