/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const RoundCheckBoxOff = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.9949 9.34939 20.9397 6.80881 19.0655 4.93455C17.1912 3.06028 14.6506 2.00508 12 2ZM16.7596 10.25L11.125 15.6346C10.9793 15.7716 10.7865 15.8474 10.5865 15.8462C10.4888 15.8476 10.3917 15.8296 10.301 15.7933C10.2102 15.757 10.1275 15.703 10.0577 15.6346L7.24039 12.9423C7.16225 12.8741 7.0987 12.7909 7.05356 12.6975C7.00842 12.6041 6.98262 12.5026 6.97772 12.399C6.97282 12.2954 6.98892 12.1919 7.02504 12.0947C7.06116 11.9975 7.11656 11.9086 7.18792 11.8334C7.25928 11.7581 7.34511 11.6981 7.44027 11.6568C7.53542 11.6156 7.63793 11.5941 7.74163 11.5935C7.84533 11.5928 7.94808 11.6132 8.0437 11.6533C8.13933 11.6935 8.22585 11.7525 8.29808 11.8269L10.5865 14.0096L15.7019 9.13461C15.8515 9.00412 16.0457 8.9365 16.244 8.94588C16.4423 8.95526 16.6292 9.04092 16.7658 9.18495C16.9024 9.32898 16.978 9.52023 16.9768 9.71872C16.9757 9.9172 16.8979 10.1076 16.7596 10.25Z"
      fill={color}
    />
  </>
);
