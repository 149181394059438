import { ClipLoader } from 'react-spinners';

const Loading = () => {
  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}>
      <div className="flex justify-center" style={{ height: '100%', alignItems: 'center' }}>
        <ClipLoader color="#00a988" size={50} />
      </div>
    </div>
  )
}

export default Loading;