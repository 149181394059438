/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Rewrite = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4343 2.23431C17.7467 1.9219 18.2533 1.9219 18.5657 2.23431L21.7657 5.43431C22.0781 5.74673 22.0781 6.25327 21.7657 6.56569L12.1657 16.1657C12.0157 16.3157 11.8122 16.4 11.6 16.4H8.4C7.95817 16.4 7.6 16.0418 7.6 15.6V12.4C7.6 12.1878 7.68429 11.9843 7.83431 11.8343L17.4343 2.23431ZM20.0686 6L18.8 7.26863L16.7314 5.2L18 3.93137L20.0686 6ZM17.6686 8.4L15.6 6.33137L9.2 12.7314V14.8H11.2686L17.6686 8.4Z"
      fill={color}
    />
    <path
      d="M3.6 2.8C3.17565 2.8 2.76869 2.96857 2.46863 3.26863C2.16857 3.56869 2 3.97565 2 4.4V20.4C2 20.8243 2.16857 21.2313 2.46863 21.5314C2.76869 21.8314 3.17566 22 3.6 22H19.6C20.0243 22 20.4313 21.8314 20.7314 21.5314C21.0314 21.2313 21.2 20.8243 21.2 20.4V11.6C21.2 11.1582 20.8418 10.8 20.4 10.8C19.9582 10.8 19.6 11.1582 19.6 11.6V20.4H3.6L3.6 4.4L12.4 4.4C12.8418 4.4 13.2 4.04183 13.2 3.6C13.2 3.15817 12.8418 2.8 12.4 2.8H3.6Z"
      fill={color}
    />
  </>
);
