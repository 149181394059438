/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Redo = ({ color = '#191919' }: IconProps) => (
  <path
    d="M16.1554 4.85161C15.855 4.55121 15.855 4.06416 16.1554 3.76375C16.4558 3.46335 16.9429 3.46335 17.2433 3.76375L21.8587 8.37914C22.1591 8.67955 22.1591 9.1666 21.8587 9.467L17.2433 14.0824C16.9429 14.3828 16.4558 14.3828 16.1554 14.0824C15.855 13.782 15.855 13.2949 16.1554 12.9945L19.4577 9.6923H8.23784C7.01376 9.6923 5.83982 10.1786 4.97426 11.0441C4.10871 11.9097 3.62245 13.0836 3.62245 14.3077C3.62245 15.5318 4.10871 16.7057 4.97426 17.5713C5.83982 18.4368 7.01376 18.9231 8.23784 18.9231H16.6994C17.1242 18.9231 17.4686 19.2675 17.4686 19.6923C17.4686 20.1171 17.1242 20.4615 16.6994 20.4615H8.23784C6.60573 20.4615 5.04048 19.8132 3.88641 18.6591C2.73234 17.5051 2.08398 15.9398 2.08398 14.3077C2.08398 12.6756 2.73234 11.1103 3.88641 9.95626C5.04048 8.80219 6.60573 8.15384 8.23784 8.15384H19.4577L16.1554 4.85161Z"
    fill={color}
  />
);
