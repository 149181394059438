/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ZoomIn = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M11.6325 6.9231C11.6325 6.52729 11.3116 6.20643 10.9158 6.20643C10.52 6.20643 10.1992 6.52729 10.1992 6.9231V10.2064H6.86766C6.47185 10.2064 6.15099 10.5273 6.15099 10.9231C6.15099 11.3189 6.47185 11.6398 6.86766 11.6398H10.1992L10.1992 14.9231C10.1992 15.3189 10.52 15.6397 10.9158 15.6397C11.3116 15.6397 11.6325 15.3189 11.6325 14.9231V11.6398H14.8677C15.2635 11.6398 15.5843 11.3189 15.5843 10.9231C15.5843 10.5273 15.2635 10.2064 14.8677 10.2064H11.6325V6.9231Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9301 2C6.04453 2 2.08398 5.96055 2.08398 10.8461C2.08398 15.7317 6.04453 19.6923 10.9301 19.6923C13.096 19.6923 15.08 18.9139 16.6178 17.6217L20.7708 21.7747C21.0712 22.0751 21.5583 22.0751 21.8587 21.7747C22.1591 21.4743 22.1591 20.9872 21.8587 20.6868L17.7057 16.5338C18.9979 14.996 19.7763 13.012 19.7763 10.8461C19.7763 5.96055 15.8157 2 10.9301 2ZM3.62244 10.8461C3.62244 6.81022 6.8942 3.53846 10.9301 3.53846C14.966 3.53846 18.2378 6.81022 18.2378 10.8461C18.2378 14.8821 14.966 18.1538 10.9301 18.1538C6.8942 18.1538 3.62244 14.8821 3.62244 10.8461Z"
      fill={color}
    />
  </>
);
