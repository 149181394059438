/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Zip = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.46863 2.46863C3.76869 2.16857 4.17565 2 4.6 2H14.2C14.4122 2 14.6157 2.08429 14.7657 2.23431L20.3657 7.83431C20.5157 7.98434 20.6 8.18783 20.6 8.4V12.4C20.6 12.8418 20.2418 13.2 19.8 13.2C19.3582 13.2 19 12.8418 19 12.4V9.23301H14.4026C13.8504 9.23301 13.4026 8.78529 13.4026 8.23301V3.6L4.6 3.6L4.6 12.4C4.6 12.8418 4.24183 13.2 3.8 13.2C3.35817 13.2 3 12.8418 3 12.4V3.6C3 3.17565 3.16857 2.76869 3.46863 2.46863ZM15.0026 4.73401L17.9016 7.63301H15.0026V4.73401Z"
      fill={color}
    />
    <path
      d="M4.6 15.6C4.15817 15.6 3.8 15.9582 3.8 16.4C3.8 16.8418 4.15817 17.2 4.6 17.2H6.30519L3.93436 20.7562C3.7707 21.0017 3.75545 21.3174 3.89466 21.5775C4.03388 21.8376 4.30497 22 4.6 22H7.8C8.24183 22 8.6 21.6418 8.6 21.2C8.6 20.7582 8.24183 20.4 7.8 20.4H6.09482L8.46564 16.8438C8.6293 16.5983 8.64456 16.2826 8.50534 16.0225C8.36613 15.7624 8.09504 15.6 7.8 15.6H4.6Z"
      fill={color}
    />
    <path
      d="M11.8 15.6C12.2418 15.6 12.6 15.9582 12.6 16.4V21.2C12.6 21.6418 12.2418 22 11.8 22C11.3582 22 11 21.6418 11 21.2V16.4C11 15.9582 11.3582 15.6 11.8 15.6Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8 15.6C15.3582 15.6 15 15.9582 15 16.4V21.2C15 21.6418 15.3582 22 15.8 22C16.2418 22 16.6 21.6418 16.6 21.2V20.4H17.4C18.0365 20.4 18.647 20.1471 19.0971 19.6971C19.5471 19.247 19.8 18.6365 19.8 18C19.8 17.3635 19.5471 16.753 19.0971 16.3029C18.647 15.8529 18.0365 15.6 17.4 15.6H15.8ZM17.4 18.8H16.6V17.2H17.4C17.6122 17.2 17.8157 17.2843 17.9657 17.4343C18.1157 17.5843 18.2 17.7878 18.2 18C18.2 18.2122 18.1157 18.4157 17.9657 18.5657C17.8157 18.7157 17.6122 18.8 17.4 18.8Z"
      fill={color}
    />
  </>
);
