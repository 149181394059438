/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Reports = ({ color = '#191919' }: IconProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2.76923 4C2.3444 4 2 4.3444 2 4.76923V17.8462C2 18.2542 2.16209 18.6455 2.45061 18.934C2.73912 19.2225 3.13044 19.3846 3.53846 19.3846H20.4615C20.8696 19.3846 21.2609 19.2225 21.5494 18.934C21.8379 18.6455 22 18.2542 22 17.8462V4.76923C22 4.3444 21.6556 4 21.2308 4H2.76923ZM8.92308 17.8462H20.4615V14.7692H8.92308V17.8462ZM7.38462 14.7692V17.8462H3.53846V14.7692H7.38462ZM8.92308 13.2308H20.4615V10.1538H8.92308V13.2308ZM7.38462 10.1538V13.2308H3.53846V10.1538H7.38462ZM20.4615 8.61538V5.53846H3.53846V8.61538H20.4615Z"
    fill={color}
  />
);
