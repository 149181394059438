/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const TeacherBook = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.69658 8.28028C2.69658 10.4262 3.84547 12.3037 5.56171 13.3314C4.90834 13.5428 4.27632 13.823 3.67671 14.1693C2.18602 15.03 0.948191 16.268 0.0876566 17.7588C-0.0928253 18.0715 0.0143345 18.4713 0.327005 18.6517C0.639675 18.8322 1.03945 18.7251 1.21994 18.4124C1.62252 17.7149 2.12039 17.0813 2.69658 16.5285V18.0856C2.69658 18.4466 2.98924 18.7393 3.35027 18.7393C3.71129 18.7393 4.00395 18.4466 4.00395 18.0856V15.4999C4.11114 15.4314 4.21999 15.3652 4.33045 15.3014C4.5663 15.1653 4.80794 15.0408 5.05447 14.9285L7.51591 18.3745C8.03732 19.1045 9.12222 19.1045 9.64362 18.3745L12.1051 14.9285C12.1646 14.9556 12.2953 15.0053 12.4534 15.0643C13.0691 15.2403 13.4587 15.105 13.5678 14.7023C13.7795 13.92 12.6087 13.6066 11.9125 13.4203C11.7893 13.3873 11.681 13.3583 11.5978 13.3314C13.314 12.3037 14.4629 10.4262 14.4629 8.28028C14.4629 5.03109 11.829 2.39709 8.57976 2.39709C5.33057 2.39709 2.69658 5.03109 2.69658 8.28028ZM8.57976 3.70447C6.05261 3.70447 4.00395 5.75313 4.00395 8.28028C4.00395 10.7849 6.01628 12.8196 8.51234 12.8556L8.57975 12.8554L8.64717 12.8556C11.1432 12.8196 13.1556 10.7849 13.1556 8.28028C13.1556 5.75313 11.1069 3.70447 8.57976 3.70447ZM10.8289 14.4658C10.1219 14.2717 9.38989 14.1692 8.65086 14.163C8.62719 14.1633 8.60349 14.1635 8.57976 14.1635C8.55603 14.1635 8.53233 14.1633 8.50866 14.163C7.76963 14.1692 7.03764 14.2717 6.33062 14.4658L8.57977 17.6146L10.8289 14.4658Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5938 13.3567C15.5938 12.4566 16.3242 11.7262 17.2243 11.7262H23.4731C23.7641 11.7262 24 11.9621 24 12.2532V19.6055C24 19.8965 23.7641 20.1325 23.4731 20.1325H17.2236L17.2216 20.1324C17.1462 20.1321 17.0712 20.1468 17.0014 20.1755C16.9317 20.2042 16.8683 20.2464 16.815 20.2998C16.7616 20.3531 16.7194 20.4165 16.6906 20.4862C16.6822 20.5068 16.6749 20.5278 16.669 20.5491H22.7378C23.0288 20.5491 23.2648 20.785 23.2648 21.076C23.2648 21.367 23.0288 21.6029 22.7378 21.6029H16.1207C15.8297 21.6029 15.5938 21.367 15.5938 21.076V20.7084L15.5938 20.7054L15.5938 13.3567ZM17.2425 12.7919C16.8745 12.7919 16.5762 13.0879 16.5762 13.453V19.3077L16.7323 19.1925C16.7658 19.1711 16.8395 19.1307 16.8913 19.1224H22.9672V12.7919H17.2425Z"
      fill={color}
    />
  </>
);
