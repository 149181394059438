/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Message = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.85322 4C2.42838 4 2.08398 4.35817 2.08398 4.8V18.4C2.08398 18.8243 2.24607 19.2313 2.53459 19.5314C2.82311 19.8314 3.21442 20 3.62245 20H20.5455C20.9535 20 21.3449 19.8314 21.6334 19.5314C21.9219 19.2313 22.084 18.8243 22.084 18.4V4.8C22.084 4.35817 21.7396 4 21.3148 4H2.85322ZM3.62245 6.6657V18.4H20.5455V6.6471L12.6037 14.1896C12.3096 14.47 11.8582 14.47 11.5641 14.1896L3.62245 6.6657ZM19.3663 5.6H4.78241L12.0839 12.5146L19.3663 5.6Z"
      fill={color}
    />
  </>
);
