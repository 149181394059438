/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Help = ({ color = '#191919' }: IconProps) => (
  <path
    fill={color}
    fillRule="evenodd"
    d="M5.891 19.117A9.968 9.968 0 0 0 12.916 22a9.968 9.968 0 0 0 7.116-2.975A9.968 9.968 0 0 0 22.916 12c0-2.738-1.1-5.22-2.884-7.026a.796.796 0 0 0-.09-.09A9.968 9.968 0 0 0 12.916 2c-2.738 0-5.22 1.1-7.025 2.883a.78.78 0 0 0-.092.092A9.968 9.968 0 0 0 2.916 12c0 2.738 1.1 5.22 2.883 7.025a.762.762 0 0 0 .092.092ZM4.454 12c0-2.06.736-3.947 1.96-5.415l2.74 2.74A4.594 4.594 0 0 0 8.3 12c0 .997.316 1.92.853 2.674l-2.74 2.74A8.427 8.427 0 0 1 4.454 12Zm3.047 6.503 2.74-2.74a4.594 4.594 0 0 0 2.675.852c.997 0 1.92-.316 2.674-.853l2.74 2.74a8.427 8.427 0 0 1-5.414 1.96 8.427 8.427 0 0 1-5.415-1.96Zm11.918-1.088-2.74-2.74A4.594 4.594 0 0 0 17.53 12c0-.997-.316-1.92-.853-2.674l2.74-2.74A8.427 8.427 0 0 1 21.379 12c0 2.06-.736 3.947-1.96 5.415ZM18.33 5.497l-2.74 2.74a4.594 4.594 0 0 0-2.675-.852c-.997 0-1.92.316-2.674.853l-2.74-2.74a8.427 8.427 0 0 1 5.414-1.96c2.06 0 3.947.736 5.415 1.96ZM9.839 12a3.077 3.077 0 1 1 6.154 0 3.077 3.077 0 0 1-6.154 0Z"
    clipRule="evenodd"
  />
);
