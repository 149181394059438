import { Navigate, Outlet } from 'react-router-dom';

type RoutesPropsData = {
  routeRoles?: Array<string>;
}

const PrivateRoutes: React.FC<RoutesPropsData> = ({ routeRoles }) => {
  const userRoles = ['user'];
  const findRole = routeRoles?.find(role => userRoles.indexOf(role) !== -1);

  console.log('userRoles', userRoles)
  console.log('findRole', findRole)
  return findRole ? <Outlet/> : <Navigate to="/" />;
};

export default PrivateRoutes;