/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ClassDiary = ({ color = '#191919' }: IconProps) => (
  <path
    d="M19.7763 2H6.69937C5.88332 2 5.10069 2.32417 4.52365 2.90121C3.94662 3.47824 3.62244 4.26087 3.62244 5.07692V21.2308C3.62244 21.4348 3.70349 21.6304 3.84775 21.7747C3.99201 21.919 4.18766 22 4.39167 22H18.2378C18.4418 22 18.6375 21.919 18.7818 21.7747C18.926 21.6304 19.0071 21.4348 19.0071 21.2308C19.0071 21.0268 18.926 20.8311 18.7818 20.6868C18.6375 20.5426 18.4418 20.4615 18.2378 20.4615H5.16091C5.16091 20.0535 5.32299 19.6622 5.61151 19.3737C5.90003 19.0852 6.29134 18.9231 6.69937 18.9231H19.7763C19.9803 18.9231 20.176 18.842 20.3202 18.6978C20.4645 18.5535 20.5455 18.3579 20.5455 18.1538V2.76923C20.5455 2.56522 20.4645 2.36956 20.3202 2.2253C20.176 2.08104 19.9803 2 19.7763 2ZM11.3148 3.53846H15.9301V10.4615L14.083 9.07692C13.9499 8.97706 13.7879 8.92308 13.6215 8.92308C13.455 8.92308 13.2931 8.97706 13.1599 9.07692L11.3148 10.4615V3.53846ZM19.0071 17.3846H6.69937C6.15912 17.3839 5.62831 17.5262 5.16091 17.7971V5.07692C5.16091 4.6689 5.32299 4.27758 5.61151 3.98907C5.90003 3.70055 6.29134 3.53846 6.69937 3.53846H9.77629V12C9.77629 12.1429 9.81607 12.2829 9.89117 12.4044C9.96628 12.5259 10.0737 12.6241 10.2015 12.688C10.3293 12.7519 10.4723 12.779 10.6146 12.7661C10.7569 12.7533 10.8928 12.7011 11.0071 12.6154L13.6224 10.6538L16.2388 12.6154C16.3717 12.7151 16.5333 12.769 16.6994 12.7692C16.9034 12.7692 17.099 12.6882 17.2433 12.5439C17.3876 12.3997 17.4686 12.204 17.4686 12V3.53846H19.0071V17.3846Z"
    fill={color}
  />
);
