/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const Journey = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M11.3918 11.1344C11.8818 10.7759 12.5203 10.8868 12.8754 11.3721C13.2336 11.8616 13.1235 12.5102 12.6377 12.8656L12.6354 12.8673C12.149 13.2113 11.5112 13.116 11.1541 12.6279C10.796 12.1384 10.906 11.4899 11.3918 11.1344Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.72288 6.05312C7.94774 5.90696 8.20025 5.82501 8.45772 5.82501H8.56587L8.57766 5.84858C8.66662 5.85486 8.7609 5.87086 8.85244 5.90747L15.5303 8.60256C15.7724 8.67762 15.9573 8.84433 16.0895 9.01976C16.2433 9.21038 16.325 9.45595 16.3263 9.69112L16.7859 16.9354C16.7976 17.3088 16.6341 17.6959 16.2834 17.9423C16.0605 18.1059 15.804 18.175 15.5618 18.175C15.4211 18.175 15.291 18.1513 15.1623 18.1045L15.1568 18.1026L8.46941 15.4175C8.22727 15.3425 8.04226 15.1757 7.91007 15.0003C7.75628 14.8096 7.67457 14.5641 7.67324 14.3289L7.21326 7.07911C7.21326 6.70886 7.35947 6.29537 7.72118 6.05424L7.72288 6.05312ZM8.59615 7.26351L9.03826 14.193L15.42 16.7552L14.9911 9.83686L8.59615 7.26351Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12Z"
      fill={color}
    />
  </>
);
