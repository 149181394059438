/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const EditCourse = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7563 6.03503L16.446 1.27945C16.2117 0.405771 15.3136 -0.112685 14.44 0.121542L11.1153 1.02157C10.818 0.622463 10.3424 0.363952 9.80653 0.363952H6.54473C6.24764 0.363952 5.96915 0.443391 5.72929 0.582132C5.48938 0.443391 5.21089 0.363952 4.91382 0.363952H1.65202C0.751298 0.363952 0.0211182 1.09416 0.0211182 1.99488V18.3038C0.0211182 19.2046 0.751298 19.9347 1.65202 19.9347H4.91382C5.21089 19.9347 5.48938 19.8553 5.72929 19.7165C5.96915 19.8553 6.24764 19.9347 6.54473 19.9347H7.31606C7.89525 19.9347 8.25238 19.279 8.00673 18.7544C7.88149 18.4871 7.61766 18.3038 7.32249 18.3038H6.54473V15.9525H7.33083C7.77596 15.9525 8.13681 15.5917 8.13681 15.1466C8.13681 14.7014 7.77596 14.3406 7.33083 14.3406H6.54473V1.99488H9.80653C10.184 3.33829 10.5393 4.68637 10.8947 6.03503L10.9107 6.09568C11.0033 6.44673 11.934 9.84818 12.0271 10.1992C12.146 10.6481 12.6062 10.9156 13.055 10.7967C13.5039 10.6778 13.7714 10.2175 13.6525 9.76867L12.748 6.46826L12.7497 6.46778L15.9143 5.62205L16.1758 6.46438C16.2591 6.77528 16.543 6.99261 16.8633 7.02423C17.418 7.07901 17.9006 6.57336 17.7563 6.03503ZM12.3304 4.89169L15.4921 4.04676L14.864 1.70348L11.7001 2.55154L12.32 4.86453L12.323 4.86374L12.3304 4.89169ZM4.91382 18.3038V6.07212H1.65202V18.3038H4.91382ZM4.91382 1.99488V4.44119H1.65202V1.99488H4.91382Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2849 8.12999C19.0246 7.86964 18.6025 7.86964 18.3421 8.12999L10.3421 16.13C10.2171 16.255 10.1469 16.4246 10.1469 16.6014V19.2681C10.1469 19.6362 10.4453 19.9347 10.8135 19.9347H13.4802C13.657 19.9347 13.8266 19.8645 13.9516 19.7395L21.9516 11.7395C22.2119 11.4791 22.2119 11.057 21.9516 10.7967L19.2849 8.12999ZM19.4802 12.3252L20.5374 11.2681L18.8135 9.5442L17.7563 10.6014L19.4802 12.3252ZM16.8135 11.5442L18.5374 13.2681L13.204 18.6014H11.4802V16.8775L16.8135 11.5442Z"
      fill={color}
    />
  </>
);
