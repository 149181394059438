/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ListNumber = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66679 11.7499C8.66679 11.2897 9.03988 10.9166 9.50011 10.9166H21.1667C21.6269 10.9166 22 11.2897 22 11.7499C22 12.2102 21.6269 12.5832 21.1667 12.5832H9.50011C9.03988 12.5832 8.66679 12.2102 8.66679 11.7499Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66679 5.08331C8.66679 4.62308 9.03988 4.24999 9.50011 4.24999H21.1667C21.6269 4.24999 22 4.62308 22 5.08331C22 5.54355 21.6269 5.91664 21.1667 5.91664H9.50011C9.03988 5.91664 8.66679 5.54355 8.66679 5.08331Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66679 18.4165C8.66679 17.9563 9.03988 17.5832 9.50011 17.5832H21.1667C21.6269 17.5832 22 17.9563 22 18.4165C22 18.8768 21.6269 19.2499 21.1667 19.2499H9.50011C9.03988 19.2499 8.66679 18.8768 8.66679 18.4165Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.93827 3.12446C5.18394 3.27629 5.33349 3.54451 5.33349 3.83333V9.66661C5.33349 10.1268 4.96039 10.4999 4.50016 10.4999C4.03993 10.4999 3.66683 10.1268 3.66683 9.66661V5.18168L3.20618 5.412C2.79454 5.61782 2.29398 5.45097 2.08816 5.03933C1.88234 4.62768 2.04919 4.12713 2.46083 3.9213L4.12749 3.08798C4.38581 2.95882 4.69259 2.97262 4.93827 3.12446Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.34645 14.2352C4.2588 14.2287 4.17078 14.2408 4.08811 14.2706C4.00545 14.3005 3.93001 14.3474 3.86671 14.4084C3.80342 14.4694 3.75369 14.543 3.72078 14.6245C3.54844 15.0512 3.06278 15.2575 2.63604 15.0851C2.20929 14.9128 2.00306 14.4271 2.1754 14.0004C2.29607 13.7016 2.47839 13.4316 2.71047 13.208C2.94255 12.9845 3.21917 12.8124 3.52227 12.703C3.82537 12.5936 4.14813 12.5493 4.46949 12.5731C4.79085 12.5969 5.10358 12.6882 5.38726 12.8411C5.67095 12.9939 5.91921 13.2048 6.11584 13.4601C6.31247 13.7154 6.45305 14.0093 6.52841 14.3226C6.60376 14.6359 6.6122 14.9616 6.55316 15.2784C6.49412 15.5952 6.36894 15.8959 6.18578 16.1611C6.17978 16.1698 6.17361 16.1783 6.16728 16.1868L4.49821 18.4166H5.75015C6.21038 18.4166 6.58348 18.7896 6.58348 19.2499C6.58348 19.7101 6.21038 20.0832 5.75015 20.0832H2.83351C2.51801 20.0832 2.22956 19.905 2.08833 19.6229C1.94711 19.3408 1.97732 19.0031 2.16638 18.7505L4.82155 15.2034C4.86768 15.1336 4.89938 15.0553 4.91472 14.973C4.93082 14.8866 4.92852 14.7978 4.90797 14.7124C4.88742 14.6269 4.84908 14.5468 4.79545 14.4771C4.74182 14.4075 4.67412 14.35 4.59675 14.3083C4.51938 14.2666 4.43409 14.2417 4.34645 14.2352Z"
      fill={color}
    />
  </>
);
