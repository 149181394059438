/* eslint-disable max-len */
import React from 'react';
import { IconProps } from '../types';

export const ImageToCenter = ({ color = '#191919' }: IconProps) => (
  <>
    <path
      d="M3.11112 6.22229C2.49746 6.22229 2 5.72485 2 5.11104C2 4.49744 2.49746 4 3.11112 4H20.8889C21.5025 4 22 4.49744 22 5.11104C22 5.72485 21.5025 6.22229 20.8889 6.22229H3.11112Z"
      fill={color}
    />
    <path
      d="M3.11112 17.3333C2.49746 17.3333 2 17.8308 2 18.4444C2 19.0582 2.49746 19.5556 3.11112 19.5556H20.8889C21.5025 19.5556 22 19.0582 22 18.4444C22 17.8308 21.5025 17.3333 20.8889 17.3333H3.11112Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7222 13.4444C16.7222 14.3648 15.976 15.111 15.0555 15.111H9.5C8.57951 15.111 7.83333 14.3648 7.83333 13.4444V10.111C7.83333 9.19063 8.57951 8.44438 9.5 8.44438H15.0555C15.976 8.44438 16.7222 9.19063 16.7222 10.111V13.4444ZM9.5 10.111H15.0555V13.4444L9.5 13.4385V10.111Z"
      fill={color}
    />
  </>
);
